import { Box, Breadcrumb, BreadcrumbItem, BreadcrumbLink } from "@chakra-ui/react";

interface BreadcrumbNavProps {
  currentPage: string;
  onNavigate: (route: string) => void;
}

export function BreadcrumbNav({ currentPage, onNavigate }: BreadcrumbNavProps) {
  return (
    <Box>
      <Breadcrumb mb={4}>
        <BreadcrumbItem>
          <BreadcrumbLink
            onClick={() => onNavigate("roster")}
            fontSize="md"
            fontWeight={currentPage === "roster" ? "700" : "500"}
            textDecoration={currentPage === "roster" ? "underline" : "none"}
          >
            Roster
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <BreadcrumbLink
            onClick={() => onNavigate("resolve")}
            fontSize="md"
            fontWeight={currentPage === "resolve" ? "700" : "500"}
            textDecoration={currentPage === "resolve" ? "underline" : "none"}
          >
            Resolve
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <BreadcrumbLink
            onClick={() => onNavigate("model_insight")}
            fontSize="md"
            fontWeight={currentPage === "model_insight" ? "700" : "500"}
            textDecoration={currentPage === "model_insight" ? "underline" : "none"}
          >
            Model Insights
          </BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
    </Box>
  );
}
