import { Flex, Button, IconButton, ButtonGroup } from "@chakra-ui/react";
import { ArrowForwardIcon, ArrowBackIcon } from "@chakra-ui/icons";

const shouldDisplay = (n: number, currentPage: number, amountOfPages: number) => {
  // Always show if total pages <= 10
  if (amountOfPages <= 10) {
    return true;
  }

  // Show the first two pages, last two pages
  if ((n < 2 && currentPage < 2) || n >= amountOfPages - 2) {
    return true;
  }

  // Show the current page as well as the two previous pages
  if (n >= currentPage - 2 && n <= currentPage) {
    return true;
  }

  // Don't display in all other cases
  return false;
};

interface PaginationProps {
  currentPage: number;
  moveForward: () => void;
  moveBack: () => void;
  amountOfPages: number;
  onChangePage: (num: number) => void;
}

export function Pagination({
  currentPage,
  moveForward,
  moveBack,
  amountOfPages,
  onChangePage,
}: PaginationProps) {
  return (
    <Flex my="20px" justifyContent="center">
      <ButtonGroup variant="outline" spacing="6">
        <IconButton
          isDisabled={currentPage < 1}
          aria-label="previous providers"
          colorScheme="green"
          icon={<ArrowBackIcon />}
          variant="solid"
          onClick={moveBack}
        />
        {[...Array(amountOfPages).keys()].map((n, i) => {
          return (
            shouldDisplay(n, currentPage, amountOfPages) && (
              <Button
                key={i}
                aria-label={`select page ${n + 1}`}
                onClick={() => onChangePage(n)}
                colorScheme="indigo"
                variant={currentPage === n ? "solid" : "ghost"}
              >
                {n + 1}
              </Button>
            )
          );
        })}
        <IconButton
          isDisabled={amountOfPages - 1 === currentPage}
          aria-label="next providers"
          colorScheme="green"
          icon={<ArrowForwardIcon />}
          variant="solid"
          onClick={moveForward}
        />
      </ButtonGroup>
    </Flex>
  );
}
