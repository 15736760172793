import { Flex, Image, LinkBox, LinkOverlay } from "@chakra-ui/react";

export function Navbar() {
  return (
    <Flex
      h="60px"
      bgColor="white"
      alignItems="center"
      pl="30px"
      boxShadow="0px 3px 4px 0px rgba(0, 0, 0, 0.05)"
    >
      <LinkBox>
        <LinkOverlay href="/broker">
          <Image
            h="24px"
            src="https://uploads-ssl.webflow.com/645029572ba4d37613789f79/645029572ba4d36e42789f80_230426_Indigo_Logo_Primary-p-1080.png"
            alt="Indigo Logo"
          />
        </LinkOverlay>
      </LinkBox>
    </Flex>
  );
}
