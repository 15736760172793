export const CREATE_HEALTHCARE_ENTITY = "Create Healthcare Entity";
export const COPY_HEALTHCARE_ENTITY = "Copy Healthcare Entity";
export const UPDATE_HEALTHCARE_ENTITY = "Update Healthcare Entity";
export const DELETE_HEALTHCARE_ENTITY = "Delete Healthcare Entity";

export const CREATE_INDIVIDUAL_PROVIDER = "Create Individual Provider";
export const COPY_INDIVIDUAL_PROVIDER = "Copy Individual Provider";
export const UPDATE_INDIVIDUAL_PROVIDER = "Update Individual Provider";
export const DELETE_INDIVIDUAL_PROVIDER = "Delete Individual Provider";

export const CREATE_MIDLEVEL = "Create Midlevel";
export const COPY_MIDLEVEL = "Copy Midlevel";
export const UPDATE_MIDLEVEL = "Update Midlevel";
export const DELETE_MIDLEVEL = "Delete Midlevel";

export const NEXT_BUTTON_CLICKED = "Next Button Clicked";
export const BACK_BUTTON_CLICKED = "Back Button Clicked";

export const PAGES = {
  ROSTER: "roster",
  MODEL_INSIGHT: "model_insight",
  RESOLUTION: "resolution",
};

export const ERROR_OVERLAY_ACTIVATED = "Error Overlay Shown";
export const OPEN_RESOLUTION_MODAL = "Open Resolution Modal";
export const CLOSE_RESOLUTION_MODAL = "Close Resolution Modal";
export const SELECT_NPI_FROM_RESOLUTION_MODAL = "Select NPI From Resolution Modal";
export const UPDATE_SCHEDULE_RATING_FACTOR = "Update Schedule Rating Factor";
export const SUBMIT_QUOTE = "Submit Quote";
export const QUOTE_RESPONSE = "Quote Response";
export const QUOTE_ERROR = "Quote Submission Error";

export const AMOUNT_OF_NPI_MATCHES = "Amount of NPI Matches";

export const RAT_EVENTS = {
  SELECT_MULTIPLE_PROVIDERS_FLOW: "Select Multiple Providers Flow",
  SELECT_SINGLE_PROVIDER_FLOW: "Select Individual Provider Flow",
  MATCH_PROFILES: "Match Profiles Clicked",
  ASSESS_RISK: "Assess Risk Clicked",
  ADD_PROVIDER: "Add Another Provider Clicked",
  SUBMIT_INQUIRY: "Submit Application Clicked",
};
