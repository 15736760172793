import { Amplify, API } from "aws-amplify";
import awsconfig from "../aws-exports";

const myAPI = "api2b38d508";
const path = "/submitToSunlight/quote";

Amplify.configure(awsconfig);
API.configure(awsconfig);

export type QuoteRequest = {
  individualProviders?: any[];
  healthcareEnitites?: any[];
  midLevels?: any[];
  slots?: any[];
  resolvedRatings?: any[];
  quoteProperties?: any;
};

async function submitToSunlight(appState: QuoteRequest) {
  // const params = { body: {} };
  try {
    const response = await API.post(myAPI, path, { body: appState });
    console.log("RESULTS");
    console.log(response);
    console.log("WERE");
    return JSON.parse(response.body);
  } catch (error) {
    throw new Error("Error submitting to Sunlight: " + error);
  }
}

export { submitToSunlight };
