import { Amplify, API, Auth } from "aws-amplify";
import awsconfig from "../aws-exports";
import { getBrokerInquiryModelRating } from "../graphql/queries";

Amplify.configure(awsconfig);
API.configure(awsconfig);

type ModelRatingFetcherInput = {
  npi: string;
  countyFIPS: string;
  specialty: string;
};

export type ModelRatingResult = {
  data?: any;
  metadata?: any;
  results?: any[];
};

type ModelRatingInput = {
  npi: string;
  countyFIPS: string;
  specialty: string;
};

class ModelRatingFetcher {
  async fetch(input: ModelRatingFetcherInput) {
    const response = (await API.graphql(
      {
        query: getBrokerInquiryModelRating,
        variables: {
          npi: input.npi,
          countyFIPS: input.countyFIPS,
          specialty: input.specialty,
        },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      },
      {
        Authorization: (await Auth.currentSession()).getIdToken().getJwtToken(),
      },
    )) as any;

    // TODO - there must be a better way to pass that modelRatingResult down from the lambda
    const lambdaPayload = response?.data?.getBrokerInquiryModelRating;
    if (lambdaPayload) {
      const lambdaResult = JSON.parse(lambdaPayload);
      const modelRatingResult = JSON.parse(lambdaResult.body);
      return modelRatingResult as ModelRatingResult;
    } else {
      throw new Error("malformed results, expected payload.");
    }
  }
}

export async function getModelRating(input: ModelRatingInput): Promise<ModelRatingResult> {
  return new Promise(async (resolve, reject) => {
    const fetcher = new ModelRatingFetcher();
    try {
      resolve(
        await fetcher.fetch({
          npi: input.npi,
          countyFIPS: input.countyFIPS,
          specialty: input.specialty,
        }),
      );
    } catch (e: any) {
      if (e.errors && e.errors.length > 0) {
        reject(new Error(e.errors.map((x: any) => x.errorType).join(", ")));
      } else {
        reject(new Error("malformed results, expected errors."));
      }
    }
  });
}
