import { Controller, Control } from "react-hook-form";
import {
  Box,
  Checkbox,
  Divider,
  Flex,
  FormErrorMessage,
  FormLabel,
  FormControl,
  IconButton,
  Input,
  Select,
} from "@chakra-ui/react";
import { CloseIcon } from "@chakra-ui/icons";

import { INDIGO_SPECIALTIES } from "services/indigoSpecialtyService";
import { UpdateBrokerInquiryInput } from "API";
import { AutocompleteInput } from "components";
import { stringifyAddress } from "utils";

interface InfoFormProps {
  index: number;
  control: Control<UpdateBrokerInquiryInput>;
  removeProvider: () => void;
}

export function InfoForm({ index, control, removeProvider }: InfoFormProps) {
  return (
    <>
      {index !== 0 && <Divider my="50px" />}
      {index !== 0 && (
        <Flex justifyContent="flex-end">
          <IconButton
            onClick={removeProvider}
            size="xs"
            variant="ghost"
            colorScheme="indigo"
            aria-label="Remove provider"
            icon={<CloseIcon />}
          />
        </Flex>
      )}
      <Flex gap="12px">
        <Controller
          name={`providers.${index}.firstName`}
          control={control}
          rules={{ required: "First name is required" }}
          render={({ field, fieldState }) => {
            return (
              <FormControl isInvalid={Boolean(fieldState.error)}>
                <FormLabel fontSize="sm" htmlFor="firstName">
                  First name
                </FormLabel>
                <Input
                  fontSize="sm"
                  id="firstName"
                  placeholder="First name"
                  onChange={field.onChange}
                  defaultValue={field.value}
                />
                <FormErrorMessage>{fieldState.error && fieldState.error.message}</FormErrorMessage>
              </FormControl>
            );
          }}
        />
        <Controller
          name={`providers.${index}.lastName`}
          control={control}
          rules={{ required: "Last name is required" }}
          render={({ field, fieldState }) => {
            return (
              <FormControl isInvalid={Boolean(fieldState.error)}>
                <FormLabel fontSize="sm" htmlFor="lastName">
                  Last name
                </FormLabel>
                <Input
                  fontSize="sm"
                  id="lastName"
                  placeholder="Last name"
                  onChange={field.onChange}
                  defaultValue={field.value}
                />
                <FormErrorMessage>{fieldState.error && fieldState.error.message}</FormErrorMessage>
              </FormControl>
            );
          }}
        />
      </Flex>
      <Controller
        name={`providers.${index}.specialty`}
        control={control}
        rules={{ required: "Specialty is required" }}
        render={({ field, fieldState }) => {
          return (
            <FormControl mt="16px" isInvalid={Boolean(fieldState.error)}>
              <FormLabel fontSize="sm" htmlFor="specialty">
                Specialty
              </FormLabel>
              <Select
                defaultValue={field.value}
                id="specialty"
                size="md"
                m="0px"
                p="0px"
                onChange={field.onChange}
              >
                <option></option>
                {INDIGO_SPECIALTIES.map((spec) => (
                  <option key={spec.value} value={spec.value}>
                    {spec.name}
                  </option>
                ))}
              </Select>
              <FormErrorMessage>{fieldState.error && fieldState.error.message}</FormErrorMessage>
            </FormControl>
          );
        }}
      />
      <Controller
        name={`providers.${index}.address`}
        render={({ fieldState, field }) => {
          return (
            <FormControl mt="16px" isInvalid={Boolean(fieldState.error)}>
              <FormLabel fontSize="sm" htmlFor="address">
                Address
              </FormLabel>
              <AutocompleteInput
                error={fieldState.error && !!fieldState.error.message}
                value={stringifyAddress(field?.value)}
                onChange={(addressObj) => {
                  field.onChange(addressObj);
                }}
                data-testid="address"
              />
              <FormErrorMessage>{fieldState.error && fieldState.error.message}</FormErrorMessage>
            </FormControl>
          );
        }}
        control={control}
      />
      <Box mt={6}>
        <Controller
          name={`providers.${index}.address.isSecondary`}
          control={control}
          render={({ field: { onChange, value } }) => (
            <Flex wrap="wrap">
              <Checkbox
                w="50%"
                isChecked={!!value}
                value={`${value}`}
                onChange={(e) => {
                  const bValue = e.target.value === "true" ? true : false;
                  onChange(!bValue);
                }}
                key={index}
              >
                Secondary Practice Address
              </Checkbox>
            </Flex>
          )}
        />
      </Box>
    </>
  );
}
