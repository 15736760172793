import { Box, Button, Heading } from "@chakra-ui/react";
import React from "react";

import { VerticalSection, BreadcrumbNav } from "components";
import HealthcareEntityTable from "./HealthcareEntityTable";
import IndividualProviderTable from "./individualProviderTable";
import MidLevelTable from "./midLevelTable";
import Submissions from "./submissions";
import { useAnalytics } from "providers";
import { NEXT_BUTTON_CLICKED, PAGES } from "analytics";
import { QuotePropertyTable } from "./QuotePropertyTable";

export default function RosterPage({
  onNavigate,
  healthcareEntities,
  onHealthcareEntityRowChange,
  onHealthcareEntityRowDelete,
  onHealthcareEntityRowAdd,
  onHealthcareEntityRowCopy,
  individualProviders,
  onIndividualProviderRowChange,
  onIndividualProviderRowDelete,
  onIndividualProviderRowAdd,
  onIndividualProviderRowCopy,
  midLevels,
  onMidLevelsRowChange,
  onMidLevelsRowDelete,
  onMidLevelsRowAdd,
  onMidLevelsRowCopy,
  quoteProperties,
  onQuotePropertyChange,
  onQuotePropertiesChange,
}) {
  const analytics = useAnalytics();
  const onNavigate1 = (x) => {
    console.log("wtf: " + x);
    onNavigate(x);
  };
  return (
    <Box p={4}>
      <VerticalSection>
        <Heading as="h3" mb={4}>
          Quote Roster
        </Heading>
      </VerticalSection>
      <VerticalSection>
        <BreadcrumbNav currentPage="roster" onNavigate={onNavigate} />
      </VerticalSection>
      <VerticalSection>
        <Submissions />
      </VerticalSection>
      <VerticalSection>
        <QuotePropertyTable
          quoteProperties={quoteProperties}
          onPropertyChange={onQuotePropertyChange}
          onPropertiesChange={onQuotePropertiesChange}
        />
      </VerticalSection>
      <HealthcareEntityTable
        healthcareEntities={healthcareEntities}
        onRowChange={onHealthcareEntityRowChange}
        onRowDelete={onHealthcareEntityRowDelete}
        onRowAdd={onHealthcareEntityRowAdd}
        onRowCopy={onHealthcareEntityRowCopy}
      />
      <IndividualProviderTable
        individualProviders={individualProviders}
        onRowChange={onIndividualProviderRowChange}
        onRowDelete={onIndividualProviderRowDelete}
        onRowAdd={onIndividualProviderRowAdd}
        onRowCopy={onIndividualProviderRowCopy}
      />
      <MidLevelTable
        midLevels={midLevels}
        onRowChange={onMidLevelsRowChange}
        onRowDelete={onMidLevelsRowDelete}
        onRowAdd={onMidLevelsRowAdd}
        onRowCopy={onMidLevelsRowCopy}
      />
      <VerticalSection>
        <Button variant="outline" id="back-button">
          Back
        </Button>
        <Button
          variant="outline"
          id="next-button"
          onClick={() => {
            analytics.track(NEXT_BUTTON_CLICKED, {
              page: PAGES.ROSTER,
            });
            onNavigate1("resolve");
          }}
        >
          Next
        </Button>
      </VerticalSection>
    </Box>
  );
}
