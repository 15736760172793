import React, { createContext, useContext, ReactNode, useMemo } from "react";
import debounce from "lodash.debounce";

const AnalyticsContext = createContext<any>(null);

export declare interface Analytics {
  track: (event: string, properties?: Record<string, any>) => void;
  identify: (userId: string, traits?: Record<string, any>) => void;
}

export function useAnalytics() {
  const analytics = useContext(AnalyticsContext);

  const analyticsEnabled = process.env.REACT_APP_ENABLE_ANALYTICS === "true";

  const localTrack = (event: string, payload: any) =>
    console.log("Analytic event captured: ", event);

  const debouncedTrack = useMemo(() => debounce(analytics.track, 700), [analytics.track]);

  return {
    ...analytics,
    track: analyticsEnabled ? debouncedTrack : localTrack,
  };
}

interface AnalyticsProviderProps {
  children: ReactNode;
  analytics: Analytics;
}

export function AnalyticsProvider({ children, analytics }: AnalyticsProviderProps) {
  return <AnalyticsContext.Provider value={analytics}>{children}</AnalyticsContext.Provider>;
}
