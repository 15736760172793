/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type CreateBrokerInquiryInput = {
  id?: string | null,
  providers?: Array< BrokerInquiryProviderInput > | null,
  hasMultipleProviders?: boolean | null,
  riskColor?: RiskColor | null,
  modelId?: string | null,
  author?: BrokerInquiryAuthorInput | null,
  notes?: string | null,
  policyType?: string | null,
  _version?: number | null,
};

export type BrokerInquiryProviderInput = {
  id: string,
  address?: AddressInput | null,
  firstName: string,
  specialty: string,
  npi?: string | null,
  retroDate?: string | null,
  lastName: string,
  limit?: string | null,
  limitAggregate?: string | null,
  limitOccurrence?: string | null,
  limitType?: string | null,
  cyberOptOut?: boolean | null,
};

export type AddressInput = {
  street?: string | null,
  secondary?: string | null,
  city?: string | null,
  state?: string | null,
  postalCode?: string | null,
  countyName?: string | null,
  countyFips?: string | null,
  isSecondary?: boolean | null,
};

export enum RiskColor {
  RED = "RED",
  GREEN = "GREEN",
  GRAY = "GRAY",
}


export type BrokerInquiryAuthorInput = {
  email?: string | null,
};

export type ModelBrokerInquiryConditionInput = {
  hasMultipleProviders?: ModelBooleanInput | null,
  riskColor?: ModelRiskColorInput | null,
  modelId?: ModelStringInput | null,
  notes?: ModelStringInput | null,
  policyType?: ModelStringInput | null,
  and?: Array< ModelBrokerInquiryConditionInput | null > | null,
  or?: Array< ModelBrokerInquiryConditionInput | null > | null,
  not?: ModelBrokerInquiryConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelRiskColorInput = {
  eq?: RiskColor | null,
  ne?: RiskColor | null,
};

export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type BrokerInquiry = {
  __typename: "BrokerInquiry",
  id: string,
  providers?:  Array<BrokerInquiryProvider > | null,
  hasMultipleProviders?: boolean | null,
  riskColor?: RiskColor | null,
  modelId?: string | null,
  author?: BrokerInquiryAuthor | null,
  notes?: string | null,
  policyType?: string | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type BrokerInquiryProvider = {
  __typename: "BrokerInquiryProvider",
  id: string,
  address?: Address | null,
  firstName: string,
  specialty: string,
  npi?: string | null,
  retroDate?: string | null,
  lastName: string,
  limit?: string | null,
  limitAggregate?: string | null,
  limitOccurrence?: string | null,
  limitType?: string | null,
  cyberOptOut?: boolean | null,
};

export type Address = {
  __typename: "Address",
  street?: string | null,
  secondary?: string | null,
  city?: string | null,
  state?: string | null,
  postalCode?: string | null,
  countyName?: string | null,
  countyFips?: string | null,
  isSecondary?: boolean | null,
};

export type BrokerInquiryAuthor = {
  __typename: "BrokerInquiryAuthor",
  email?: string | null,
};

export type UpdateBrokerInquiryInput = {
  id: string,
  providers?: Array< BrokerInquiryProviderInput > | null,
  hasMultipleProviders?: boolean | null,
  riskColor?: RiskColor | null,
  modelId?: string | null,
  author?: BrokerInquiryAuthorInput | null,
  notes?: string | null,
  policyType?: string | null,
  _version?: number | null,
};

export type DeleteBrokerInquiryInput = {
  id: string,
  _version?: number | null,
};

export type CreateCarrierInput = {
  id?: string | null,
  name: string,
  _version?: number | null,
};

export type ModelCarrierConditionInput = {
  name?: ModelStringInput | null,
  and?: Array< ModelCarrierConditionInput | null > | null,
  or?: Array< ModelCarrierConditionInput | null > | null,
  not?: ModelCarrierConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type Carrier = {
  __typename: "Carrier",
  id: string,
  name: string,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateCarrierInput = {
  id: string,
  name?: string | null,
  _version?: number | null,
};

export type DeleteCarrierInput = {
  id: string,
  _version?: number | null,
};

export type ModelBrokerInquiryFilterInput = {
  id?: ModelIDInput | null,
  hasMultipleProviders?: ModelBooleanInput | null,
  riskColor?: ModelRiskColorInput | null,
  modelId?: ModelStringInput | null,
  notes?: ModelStringInput | null,
  policyType?: ModelStringInput | null,
  and?: Array< ModelBrokerInquiryFilterInput | null > | null,
  or?: Array< ModelBrokerInquiryFilterInput | null > | null,
  not?: ModelBrokerInquiryFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type ModelBrokerInquiryConnection = {
  __typename: "ModelBrokerInquiryConnection",
  items:  Array<BrokerInquiry | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelCarrierFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  and?: Array< ModelCarrierFilterInput | null > | null,
  or?: Array< ModelCarrierFilterInput | null > | null,
  not?: ModelCarrierFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelCarrierConnection = {
  __typename: "ModelCarrierConnection",
  items:  Array<Carrier | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelSubscriptionBrokerInquiryFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  hasMultipleProviders?: ModelSubscriptionBooleanInput | null,
  riskColor?: ModelSubscriptionStringInput | null,
  modelId?: ModelSubscriptionStringInput | null,
  notes?: ModelSubscriptionStringInput | null,
  policyType?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionBrokerInquiryFilterInput | null > | null,
  or?: Array< ModelSubscriptionBrokerInquiryFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
};

export type ModelSubscriptionStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionCarrierFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionCarrierFilterInput | null > | null,
  or?: Array< ModelSubscriptionCarrierFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type CreateBrokerInquiryMutationVariables = {
  input: CreateBrokerInquiryInput,
  condition?: ModelBrokerInquiryConditionInput | null,
};

export type CreateBrokerInquiryMutation = {
  createBrokerInquiry?:  {
    __typename: "BrokerInquiry",
    id: string,
    providers?:  Array< {
      __typename: "BrokerInquiryProvider",
      id: string,
      address?:  {
        __typename: "Address",
        street?: string | null,
        secondary?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        countyName?: string | null,
        countyFips?: string | null,
        isSecondary?: boolean | null,
      } | null,
      firstName: string,
      specialty: string,
      npi?: string | null,
      retroDate?: string | null,
      lastName: string,
      limit?: string | null,
      limitAggregate?: string | null,
      limitOccurrence?: string | null,
      limitType?: string | null,
      cyberOptOut?: boolean | null,
    } > | null,
    hasMultipleProviders?: boolean | null,
    riskColor?: RiskColor | null,
    modelId?: string | null,
    author?:  {
      __typename: "BrokerInquiryAuthor",
      email?: string | null,
    } | null,
    notes?: string | null,
    policyType?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateBrokerInquiryMutationVariables = {
  input: UpdateBrokerInquiryInput,
  condition?: ModelBrokerInquiryConditionInput | null,
};

export type UpdateBrokerInquiryMutation = {
  updateBrokerInquiry?:  {
    __typename: "BrokerInquiry",
    id: string,
    providers?:  Array< {
      __typename: "BrokerInquiryProvider",
      id: string,
      address?:  {
        __typename: "Address",
        street?: string | null,
        secondary?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        countyName?: string | null,
        countyFips?: string | null,
        isSecondary?: boolean | null,
      } | null,
      firstName: string,
      specialty: string,
      npi?: string | null,
      retroDate?: string | null,
      lastName: string,
      limit?: string | null,
      limitAggregate?: string | null,
      limitOccurrence?: string | null,
      limitType?: string | null,
      cyberOptOut?: boolean | null,
    } > | null,
    hasMultipleProviders?: boolean | null,
    riskColor?: RiskColor | null,
    modelId?: string | null,
    author?:  {
      __typename: "BrokerInquiryAuthor",
      email?: string | null,
    } | null,
    notes?: string | null,
    policyType?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteBrokerInquiryMutationVariables = {
  input: DeleteBrokerInquiryInput,
  condition?: ModelBrokerInquiryConditionInput | null,
};

export type DeleteBrokerInquiryMutation = {
  deleteBrokerInquiry?:  {
    __typename: "BrokerInquiry",
    id: string,
    providers?:  Array< {
      __typename: "BrokerInquiryProvider",
      id: string,
      address?:  {
        __typename: "Address",
        street?: string | null,
        secondary?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        countyName?: string | null,
        countyFips?: string | null,
        isSecondary?: boolean | null,
      } | null,
      firstName: string,
      specialty: string,
      npi?: string | null,
      retroDate?: string | null,
      lastName: string,
      limit?: string | null,
      limitAggregate?: string | null,
      limitOccurrence?: string | null,
      limitType?: string | null,
      cyberOptOut?: boolean | null,
    } > | null,
    hasMultipleProviders?: boolean | null,
    riskColor?: RiskColor | null,
    modelId?: string | null,
    author?:  {
      __typename: "BrokerInquiryAuthor",
      email?: string | null,
    } | null,
    notes?: string | null,
    policyType?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateCarrierMutationVariables = {
  input: CreateCarrierInput,
  condition?: ModelCarrierConditionInput | null,
};

export type CreateCarrierMutation = {
  createCarrier?:  {
    __typename: "Carrier",
    id: string,
    name: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateCarrierMutationVariables = {
  input: UpdateCarrierInput,
  condition?: ModelCarrierConditionInput | null,
};

export type UpdateCarrierMutation = {
  updateCarrier?:  {
    __typename: "Carrier",
    id: string,
    name: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteCarrierMutationVariables = {
  input: DeleteCarrierInput,
  condition?: ModelCarrierConditionInput | null,
};

export type DeleteCarrierMutation = {
  deleteCarrier?:  {
    __typename: "Carrier",
    id: string,
    name: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type GetBrokerInquiryQueryVariables = {
  id: string,
};

export type GetBrokerInquiryQuery = {
  getBrokerInquiry?:  {
    __typename: "BrokerInquiry",
    id: string,
    providers?:  Array< {
      __typename: "BrokerInquiryProvider",
      id: string,
      address?:  {
        __typename: "Address",
        street?: string | null,
        secondary?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        countyName?: string | null,
        countyFips?: string | null,
        isSecondary?: boolean | null,
      } | null,
      firstName: string,
      specialty: string,
      npi?: string | null,
      retroDate?: string | null,
      lastName: string,
      limit?: string | null,
      limitAggregate?: string | null,
      limitOccurrence?: string | null,
      limitType?: string | null,
      cyberOptOut?: boolean | null,
    } > | null,
    hasMultipleProviders?: boolean | null,
    riskColor?: RiskColor | null,
    modelId?: string | null,
    author?:  {
      __typename: "BrokerInquiryAuthor",
      email?: string | null,
    } | null,
    notes?: string | null,
    policyType?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListBrokerInquiriesQueryVariables = {
  filter?: ModelBrokerInquiryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListBrokerInquiriesQuery = {
  listBrokerInquiries?:  {
    __typename: "ModelBrokerInquiryConnection",
    items:  Array< {
      __typename: "BrokerInquiry",
      id: string,
      providers?:  Array< {
        __typename: "BrokerInquiryProvider",
        id: string,
        firstName: string,
        specialty: string,
        npi?: string | null,
        retroDate?: string | null,
        lastName: string,
        limit?: string | null,
        limitAggregate?: string | null,
        limitOccurrence?: string | null,
        limitType?: string | null,
        cyberOptOut?: boolean | null,
      } > | null,
      hasMultipleProviders?: boolean | null,
      riskColor?: RiskColor | null,
      modelId?: string | null,
      author?:  {
        __typename: "BrokerInquiryAuthor",
        email?: string | null,
      } | null,
      notes?: string | null,
      policyType?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncBrokerInquiriesQueryVariables = {
  filter?: ModelBrokerInquiryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncBrokerInquiriesQuery = {
  syncBrokerInquiries?:  {
    __typename: "ModelBrokerInquiryConnection",
    items:  Array< {
      __typename: "BrokerInquiry",
      id: string,
      providers?:  Array< {
        __typename: "BrokerInquiryProvider",
        id: string,
        firstName: string,
        specialty: string,
        npi?: string | null,
        retroDate?: string | null,
        lastName: string,
        limit?: string | null,
        limitAggregate?: string | null,
        limitOccurrence?: string | null,
        limitType?: string | null,
        cyberOptOut?: boolean | null,
      } > | null,
      hasMultipleProviders?: boolean | null,
      riskColor?: RiskColor | null,
      modelId?: string | null,
      author?:  {
        __typename: "BrokerInquiryAuthor",
        email?: string | null,
      } | null,
      notes?: string | null,
      policyType?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetCarrierQueryVariables = {
  id: string,
};

export type GetCarrierQuery = {
  getCarrier?:  {
    __typename: "Carrier",
    id: string,
    name: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListCarriersQueryVariables = {
  filter?: ModelCarrierFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCarriersQuery = {
  listCarriers?:  {
    __typename: "ModelCarrierConnection",
    items:  Array< {
      __typename: "Carrier",
      id: string,
      name: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncCarriersQueryVariables = {
  filter?: ModelCarrierFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncCarriersQuery = {
  syncCarriers?:  {
    __typename: "ModelCarrierConnection",
    items:  Array< {
      __typename: "Carrier",
      id: string,
      name: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetBrokerInquiryModelRatingQueryVariables = {
  npi?: string | null,
  countyFIPS?: string | null,
  specialty?: string | null,
};

export type GetBrokerInquiryModelRatingQuery = {
  getBrokerInquiryModelRating?: string | null,
};

export type GetBrokerInquiryRiskColorQueryVariables = {
  paramsJson?: string | null,
};

export type GetBrokerInquiryRiskColorQuery = {
  getBrokerInquiryRiskColor?: string | null,
};

export type SubmitInquiryQueryVariables = {
  payload?: string | null,
};

export type SubmitInquiryQuery = {
  submitInquiry?: string | null,
};

export type OnCreateBrokerInquirySubscriptionVariables = {
  filter?: ModelSubscriptionBrokerInquiryFilterInput | null,
};

export type OnCreateBrokerInquirySubscription = {
  onCreateBrokerInquiry?:  {
    __typename: "BrokerInquiry",
    id: string,
    providers?:  Array< {
      __typename: "BrokerInquiryProvider",
      id: string,
      address?:  {
        __typename: "Address",
        street?: string | null,
        secondary?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        countyName?: string | null,
        countyFips?: string | null,
        isSecondary?: boolean | null,
      } | null,
      firstName: string,
      specialty: string,
      npi?: string | null,
      retroDate?: string | null,
      lastName: string,
      limit?: string | null,
      limitAggregate?: string | null,
      limitOccurrence?: string | null,
      limitType?: string | null,
      cyberOptOut?: boolean | null,
    } > | null,
    hasMultipleProviders?: boolean | null,
    riskColor?: RiskColor | null,
    modelId?: string | null,
    author?:  {
      __typename: "BrokerInquiryAuthor",
      email?: string | null,
    } | null,
    notes?: string | null,
    policyType?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateBrokerInquirySubscriptionVariables = {
  filter?: ModelSubscriptionBrokerInquiryFilterInput | null,
};

export type OnUpdateBrokerInquirySubscription = {
  onUpdateBrokerInquiry?:  {
    __typename: "BrokerInquiry",
    id: string,
    providers?:  Array< {
      __typename: "BrokerInquiryProvider",
      id: string,
      address?:  {
        __typename: "Address",
        street?: string | null,
        secondary?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        countyName?: string | null,
        countyFips?: string | null,
        isSecondary?: boolean | null,
      } | null,
      firstName: string,
      specialty: string,
      npi?: string | null,
      retroDate?: string | null,
      lastName: string,
      limit?: string | null,
      limitAggregate?: string | null,
      limitOccurrence?: string | null,
      limitType?: string | null,
      cyberOptOut?: boolean | null,
    } > | null,
    hasMultipleProviders?: boolean | null,
    riskColor?: RiskColor | null,
    modelId?: string | null,
    author?:  {
      __typename: "BrokerInquiryAuthor",
      email?: string | null,
    } | null,
    notes?: string | null,
    policyType?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteBrokerInquirySubscriptionVariables = {
  filter?: ModelSubscriptionBrokerInquiryFilterInput | null,
};

export type OnDeleteBrokerInquirySubscription = {
  onDeleteBrokerInquiry?:  {
    __typename: "BrokerInquiry",
    id: string,
    providers?:  Array< {
      __typename: "BrokerInquiryProvider",
      id: string,
      address?:  {
        __typename: "Address",
        street?: string | null,
        secondary?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        countyName?: string | null,
        countyFips?: string | null,
        isSecondary?: boolean | null,
      } | null,
      firstName: string,
      specialty: string,
      npi?: string | null,
      retroDate?: string | null,
      lastName: string,
      limit?: string | null,
      limitAggregate?: string | null,
      limitOccurrence?: string | null,
      limitType?: string | null,
      cyberOptOut?: boolean | null,
    } > | null,
    hasMultipleProviders?: boolean | null,
    riskColor?: RiskColor | null,
    modelId?: string | null,
    author?:  {
      __typename: "BrokerInquiryAuthor",
      email?: string | null,
    } | null,
    notes?: string | null,
    policyType?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateCarrierSubscriptionVariables = {
  filter?: ModelSubscriptionCarrierFilterInput | null,
};

export type OnCreateCarrierSubscription = {
  onCreateCarrier?:  {
    __typename: "Carrier",
    id: string,
    name: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateCarrierSubscriptionVariables = {
  filter?: ModelSubscriptionCarrierFilterInput | null,
};

export type OnUpdateCarrierSubscription = {
  onUpdateCarrier?:  {
    __typename: "Carrier",
    id: string,
    name: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteCarrierSubscriptionVariables = {
  filter?: ModelSubscriptionCarrierFilterInput | null,
};

export type OnDeleteCarrierSubscription = {
  onDeleteCarrier?:  {
    __typename: "Carrier",
    id: string,
    name: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};
