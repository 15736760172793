import { useEffect, useState, useCallback } from "react";
import { API, graphqlOperation } from "aws-amplify";

const EXCLUDE_KEYS = new Set([
  "__typename",
  "_deleted",
  "_lastChangedAt",
  "updatedAt",
  "createdAt",
]);

function deepClean(obj: any): any {
  if (Array.isArray(obj)) {
    return obj.map((item: any) => deepClean(item));
  }
  if (typeof obj === "object" && obj !== null) {
    let newObj: any = {};
    for (const key in obj) {
      if (!EXCLUDE_KEYS.has(key)) {
        newObj[key] = deepClean(obj[key]);
      }
    }
    return newObj;
  }
  return obj;
}

function extractDataFromPayload<T>(payload: { data: T }) {
  const key: string = Object.keys(payload.data as object)[0];
  const dirtyData = (payload.data as any)[key];
  return deepClean(dirtyData);
}

export function useGraphqlApi<T>(initialQuery?: string, variables?: any) {
  const [data, setData] = useState<T | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<any>(null);
  const [hasFetched, setHasFetched] = useState(false);

  const fetchData = useCallback(
    async (force?: boolean) => {
      if (!force) {
        if (hasFetched || !initialQuery || loading) return;
      }
      setLoading(true);
      try {
        const resp = (await API.graphql(graphqlOperation(initialQuery, variables))) as { data: T };
        const actualData = extractDataFromPayload<T>(resp);
        if (actualData?.items) {
          setHasFetched(true);
          setData(actualData.items);
        } else {
          if (!actualData) {
            throw new Error("No data found");
          }
          setHasFetched(true);
          setData(actualData);
        }
      } catch (e: any) {
        setHasFetched(true);
        setError(e);
      } finally {
        setHasFetched(true);
        setLoading(false);
      }
    },
    [initialQuery, variables, hasFetched, loading],
  );

  const postData = useCallback(async (mutationQuery: string, mutationVariables?: any) => {
    setLoading(true);
    try {
      const d = (await API.graphql(graphqlOperation(mutationQuery, mutationVariables))) as {
        data: T;
      };
      const actualData = extractDataFromPayload<T>(d);
      if (actualData?.items) {
        setHasFetched(true);
        setData(actualData.items);
        return actualData.items;
      } else {
        if (!actualData) {
          throw new Error("No data found");
        }
        setHasFetched(true);
        setData(actualData);
        return actualData;
      }
    } catch (e: any) {
      setHasFetched(true);
      setError(e);
    } finally {
      setHasFetched(true);
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (initialQuery) {
      fetchData();
    }
  }, [initialQuery, fetchData]);

  return { data, loading, error, postData, fetchData };
}
