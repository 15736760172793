import { Amplify, API } from "aws-amplify";
import awsconfig from "../aws-exports";

const myAPI = "api2b38d508";

Amplify.configure(awsconfig);
API.configure(awsconfig);

// Function to query the NPI Registry with flexible search parameters

class NpiRegistryFetcher {
  async fetch(searchParams) {
    const path = "/npi";
    const params = {
      body: {
        version: 2.1,
        limit: 200, // Maximum amount allowed by the NPI Registry
        ...searchParams,
      },
    };
    try {
      const response = await API.post(myAPI, path, params);
      console.log("search: " + JSON.stringify(searchParams) + " response: ", response);
      return "Errors" in response ? response.Errors : response.results;
    } catch (error) {
      throw new Error("Error querying NPI Registry");
    }
  }
}

class NpiResolutionStorage {
  async store(searchResult) {
    const path = "/npi/resolution";
    const params = {
      body: {
        ...searchResult,
      },
    };
    try {
      const response = await API.post(myAPI, path, params);
      console.log(response);
      return response;
    } catch (error) {
      throw new Error("Error storing NPI resolution");
    }
  }
}

export { NpiRegistryFetcher, NpiResolutionStorage };
