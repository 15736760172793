import { useCallback, useState, useEffect } from "react";
import { useAnalytics } from "providers";
import { LocalStorageService } from "./services/localStorageService";
import {
  CREATE_HEALTHCARE_ENTITY,
  COPY_HEALTHCARE_ENTITY,
  UPDATE_HEALTHCARE_ENTITY,
  DELETE_HEALTHCARE_ENTITY,
  CREATE_INDIVIDUAL_PROVIDER,
  COPY_INDIVIDUAL_PROVIDER,
  UPDATE_INDIVIDUAL_PROVIDER,
  DELETE_INDIVIDUAL_PROVIDER,
  CREATE_MIDLEVEL,
  COPY_MIDLEVEL,
  UPDATE_MIDLEVEL,
  DELETE_MIDLEVEL,
} from "analytics";

const queryParams = new URLSearchParams(window.location.search);
const inquiryId = queryParams.get("inquiry-id");
const shouldUseLocalStorage = queryParams.get("localStorage") === "true";
const localStorageService = new LocalStorageService(inquiryId);

//////////////////////////////////////////////////////
// Example (Dummy) data

const USE_DUMMY_VALUES = false;

export const DUMMY_HEALTHCAREENTITIES_VALUES = [
  {
    name: "Healthcare Entity 1",
    limit: "100000/300000",
    entityCharges: "3101",
    //retroDate: new Date(),
    currentPremium: 10000,
    targetPremium: 8000,
    //effectiveDate: new Date(),
    payPlan: "Annual (2% disc.)",
  },
  {
    name: "Healthcare Entity 2",
    limit: "1000000/3000000",
    entityCharges: "3101",
    //retroDate: new Date(),
    currentPremium: 7500,
    targetPremium: 6000,
    //effectiveDate: new Date(),
    payPlan: "Monthly with down",
  },
  // Add more dummy data as needed
];

const DUMMY_INDIVIDUALPROVIDERS_VALUES = [
  {
    firstName: "John",
    lastName: "Doe",
    specialty: "Family Medicine",
    indigoSpecialty: "Primary Care",
    address: "789 Oak St",
    npi: "1234567890",
    limit: "100000/300000",
    limitType: "3101", //3101 = Separate, 3102 = Shared
    retroDate: new Date(),
    deductible: "",
    expiringPremium: 4000,
    targetPremium: 5000,
    licenseNumber: "ABC123",
    licenseState: "CA",
    retiredVolunteered: "",
    vicariousLiability: false,
    partTimePractice: "Less than 50 hours",
    teachingPhysicians: 0,
    residencyAndFellowshipPrograms: 0,
    newToPractice: "0",
    ama: false,
    burnout: false,
  },
  {
    firstName: "Jane",
    lastName: "Smith",
    specialty: "Pediatrics",
    indigoSpecialty: "Primary Care",
    address: "321 Pine St",
    npi: "0987654321",
    limit: "100000/300000",
    limitType: "3101", //3101 = Separate, 3102 = Shared
    retroDate: new Date(),
    deductible: "",
    expiringPremium: 4000,
    targetPremium: 4000,
    licenseNumber: "XYZ789",
    licenseState: "CA",
    retiredVolunteered: "",
    vicariousLiability: false,
    partTimePractice: "Less than 50 hours",
    teachingPhysicians: 0,
    residencyAndFellowshipPrograms: 0,
    newToPractice: "0",
    ama: false,
    burnout: false,
  },
];

export const DUMMY_MIDLEVELS_VALUES = [
  {
    firstName: "Alex",
    lastName: "Johnson",
    specialty: "Nurse Practitioner",
    indigoSpecialty: "Primary Care",
    address: "321 Pine St",
    retroDate: new Date(),
    limit: "0.1M/0.3M",
    limitType: "3101", // 3101 = Separate, 3102 = Shared
  },
  {
    firstName: "Sam",
    lastName: "Lee",
    specialty: "Physician Assistant",
    indigoSpecialty: "Primary Care",
    address: "321 Pine St",
    retroDate: new Date(),
    limit: "0.1M/0.3M",
    limitType: "3101", // 3101 = Separate, 3102 = Shared
    npi: "",
  },
];

const DUMMY_QUOTE_PROPERTIES_VALUES = {
  brokerId: 0,
  producerId: 0,
  submissionEmail: "test@example.com",
  commissionRate: "CS-12-10-5",
};

const DUMMY_SLOTS_VALUES = [
  {
    firstName: "John",
    lastName: "Doe",
    specialty: "Family Medicine",
  },
  {
    firstName: "Jane",
    lastName: "Smith",
    specialty: "Pediatrics",
  },
];

//////////////////////////////////////////////////////
// Validation

function _checkFields(itemsName, items, fieldsToCheck) {
  const errors = [];
  for (let i = 0; i < items.length; i++) {
    const invalidFields = [];
    for (let j = 0; j < fieldsToCheck.length; j++) {
      const item = items[i];
      const key = fieldsToCheck[j];
      if (!(key in item)) {
        invalidFields.push(key);
      } else if (typeof item[key] === "string" && item[key].length === 0) {
        invalidFields.push(key);
      }
    }
    if (invalidFields.length > 0) {
      errors.push({ itemsName, invalidFields });
    }
  }
  return errors;
}

const shallowCompare = (obj1, obj2) => {
  // console.log('shallow compare of')
  // console.log(JSON.stringify(obj1))
  // console.log(JSON.stringify(obj2))
  const result =
    Object.keys(obj1).length === Object.keys(obj2).length &&
    Object.keys(obj1).every((key) => obj1[key] === obj2[key]);
  // console.log(result)
  return result;
};

//////////////////////////////////////////////////////
// React Hook

export function useAppModel() {
  const analytics = useAnalytics();

  const savedData = localStorageService.loadData() || {
    midLevels: [],
    individualProviders: [],
    healthcareEntities: [],
    quoteProperties: {},
  };

  const midLevelsData = shouldUseLocalStorage ? savedData.midLevels : [];
  const [midLevels, setMidLevels] = useState(
    USE_DUMMY_VALUES ? DUMMY_MIDLEVELS_VALUES : midLevelsData,
  );

  const individualProvidersData = shouldUseLocalStorage ? savedData.individualProviders : [];
  const [individualProviders, setIndividualProviders] = useState(
    USE_DUMMY_VALUES ? DUMMY_INDIVIDUALPROVIDERS_VALUES : individualProvidersData,
  );

  const healthcareEntitiesData = shouldUseLocalStorage ? savedData.healthcareEntities : [];
  const [healthcareEntities, setHealthcareEntities] = useState(
    USE_DUMMY_VALUES ? DUMMY_HEALTHCAREENTITIES_VALUES : healthcareEntitiesData,
  );

  const [slots, setSlots] = useState(USE_DUMMY_VALUES ? DUMMY_SLOTS_VALUES : []);

  const quotePropertiesData = shouldUseLocalStorage ? savedData.quoteProperties : { inquiryId };
  const [quoteProperties, setQuoteProperties] = useState(
    USE_DUMMY_VALUES ? DUMMY_QUOTE_PROPERTIES_VALUES : quotePropertiesData,
  );

  useEffect(() => {
    localStorageService.saveData({
      midLevels,
      individualProviders,
      healthcareEntities,
      slots,
      quoteProperties,
    });
  }, [midLevels, individualProviders, healthcareEntities, slots, quoteProperties]);

  // HEALTHCARE ENTITY

  const DEFAULT_HEALTHCARE_ENTITY = {
    name: "",
    limit: "1000000/3000000",
    limitOccurrence: 1000000,
    limitAggregate: 3000000,
    entityCharges: "3101",
    payPlan: "Annual (2% disc.)",
  };

  function addHealthcareEntity() {
    console.log("addHealthcareEntity");
    analytics.track(CREATE_HEALTHCARE_ENTITY);

    setHealthcareEntities([...healthcareEntities, { ...DEFAULT_HEALTHCARE_ENTITY }]);
  }

  function updateHealthcareEntity(index, name, value) {
    console.log("updateHealthcareEntity: " + index + ", " + name + ", " + value);
    const newHealthcareEntities = [...healthcareEntities];
    newHealthcareEntities[index][name] = value;
    analytics.track(UPDATE_HEALTHCARE_ENTITY, {
      ...newHealthcareEntities[index],
    });
    setHealthcareEntities(newHealthcareEntities);
  }

  function deleteHealthcareEntity(index) {
    console.log("deleteHealthcareEntity: " + index);
    const newHealthcareEntities = [...healthcareEntities];
    newHealthcareEntities.splice(index, 1);
    analytics.track(DELETE_HEALTHCARE_ENTITY, {
      ...healthcareEntities[index],
    });
    setHealthcareEntities(newHealthcareEntities);
  }

  function copyHealthcareEntity(index) {
    console.log("copyHealthcareEntity: " + index);
    analytics.track(COPY_HEALTHCARE_ENTITY, {
      ...healthcareEntities[index],
    });
    setHealthcareEntities([...healthcareEntities, { ...healthcareEntities[index] }]);
  }

  // INDIVIDUAL PROVIDER

  const DEFAULT_INDIVIDUAL_PROVIDER = {
    firstName: "",
    lastName: "",
    limit: "1000000/3000000",
    limitOccurrence: 1000000,
    limitAggregate: 3000000,
    limitType: "3101", // 3101 = Separate, 3102 = Shared
    partTimePractice: "3103",
  };

  function addIndividualProvider() {
    console.log("addIndividualProvider");
    analytics.track(CREATE_INDIVIDUAL_PROVIDER);
    setIndividualProviders([...individualProviders, { ...DEFAULT_INDIVIDUAL_PROVIDER }]);
  }

  function updateIndividualProvider(index, name, value) {
    console.log("updateIndividualProvider: " + index + ", " + name + ", " + value);
    const newIndividualProviders = [...individualProviders];
    newIndividualProviders[index][name] = value;
    analytics.track(UPDATE_INDIVIDUAL_PROVIDER, {
      ...newIndividualProviders[index],
      addressObj: undefined,
    });
    setIndividualProviders(newIndividualProviders);
  }

  function deleteIndividualProvider(index) {
    console.log("deleteIndividualProvider: " + index);
    const newIndividualProviders = [...individualProviders];
    newIndividualProviders.splice(index, 1);
    analytics.track(DELETE_INDIVIDUAL_PROVIDER, {
      ...newIndividualProviders[index],
    });
    setIndividualProviders(newIndividualProviders);
  }

  function copyIndividualProvider(index) {
    console.log("copyIndividualProvider: " + index);
    analytics.track(COPY_INDIVIDUAL_PROVIDER, {
      ...individualProviders[index],
    });
    setIndividualProviders([...individualProviders, { ...individualProviders[index] }]);
  }

  // MID LEVELS

  const DEFAULT_MIDLEVEL = {
    limitType: "3101", // 3101 = Separate, 3102 = Shared
    limit: "1000000/3000000",
    limitOccurrence: 1000000,
    limitAggregate: 3000000,
  };

  function addMidLevel() {
    console.log("addMidLevel");
    analytics.track(CREATE_MIDLEVEL);
    setMidLevels([...midLevels, { ...DEFAULT_MIDLEVEL }]);
  }

  function updateMidLevel(index, name, value) {
    console.log("updateMidLevel: " + index + ", " + name + ", " + value);
    const newMidLevels = [...midLevels];
    newMidLevels[index][name] = value;
    analytics.track(UPDATE_MIDLEVEL, {
      ...newMidLevels[index],
    });
    setMidLevels(newMidLevels);
  }

  function deleteMidLevel(index) {
    console.log("deleteMidLevel: " + index);
    const newMidLevels = [...midLevels];
    analytics.track(DELETE_MIDLEVEL, {
      ...newMidLevels[index],
    });
    newMidLevels.splice(index, 1);
    setMidLevels(newMidLevels);
  }

  function copyMidLevel(index) {
    console.log("copyMidLevel: " + index);
    analytics.track(COPY_MIDLEVEL, {
      ...midLevels[index],
    });
    setMidLevels([...midLevels, { ...midLevels[index] }]);
  }

  // SLOTS (TBD)

  const DEFAULT_SLOT = {};

  function addSlot() {
    setSlots([...slots, DEFAULT_SLOT]);
  }

  function updateSlot(index, name, value) {
    console.log("updateSlot: " + index + ", " + name + ", " + value);
    const newSlots = [...slots];
    newSlots[index][name] = value;
    setSlots(newSlots);
  }

  function deleteSlot(index) {
    console.log("deleteSlot: " + index);
    const newSlots = [...slots];
    newSlots.splice(index, 1);
    setSlots(newSlots);
  }

  function copySlot(index) {
    console.log("copySlot: " + index);
    setHealthcareEntities([...slots, { ...slots[index] }]);
  }

  // QUOTE PROPERTIES

  const updateQuoteProperty = useCallback((name, value) => {
    setQuoteProperties((prevQuoteProperties) => {
      console.log("updateQuoteProperty: " + name + "->" + value);
      const newQuoteProperties = { ...prevQuoteProperties };
      newQuoteProperties[name] = value;
      return newQuoteProperties;
    });
  }, []);

  function updateQuoteProperties(props) {
    console.log("updateQuoteProperties: " + JSON.stringify(props));
    const newQuoteProperties = { ...quoteProperties, ...props };
    setQuoteProperties(newQuoteProperties);
  }

  // UTILITY

  function _nonDefault() {
    return {
      healthcareEntities: [
        ...healthcareEntities.filter((x) => !shallowCompare(x, DEFAULT_HEALTHCARE_ENTITY)),
      ],
      individualProviders: [
        ...individualProviders.filter((x) => !shallowCompare(x, DEFAULT_INDIVIDUAL_PROVIDER)),
      ],
      midLevels: [...midLevels.filter((x) => !shallowCompare(x, DEFAULT_MIDLEVEL))],
      slots: [...slots.filter((x) => !shallowCompare(x, DEFAULT_SLOT))],
      quoteProperties: [quoteProperties],
    };
  }

  function rosterValidationErrors() {
    const { healthcareEntities, individualProviders, midLevels, quoteProperties } = _nonDefault();

    console.log(
      "LENGTH OF IND PRODIVERS: " +
        [...individualProviders.filter((x) => !shallowCompare(x, DEFAULT_INDIVIDUAL_PROVIDER))]
          .length,
    );

    const ipErrors = _checkFields("IndividualProvider", individualProviders, [
      "firstName",
      "lastName",
      "specialty",
      "indigoSpecialtyId",
      "address",
      "retroDate",
      "limit",
    ]);
    return [
      ..._checkFields("HealthcareEntity", healthcareEntities, ["name", "retroDate"]),
      ...ipErrors,
      ..._checkFields("MidLevel", midLevels, [
        "firstName",
        "lastName",
        "specialty",
        "indigoSpecialty",
        "address",
        "retroDate",
      ]),
      ..._checkFields("QuoteProperties", quoteProperties, [
        "effectiveDate",
        "agencyName",
        "brokerName",
      ]),
    ];
  }

  function resolveValidationErrors() {
    const { individualProviders } = _nonDefault();
    return [..._checkFields("IndividualProvider", individualProviders, ["npi"])];
  }

  function cleanupEmpty() {
    const { healthcareEntities, individualProviders, midLevels } = _nonDefault();
    setHealthcareEntities(healthcareEntities);
    setIndividualProviders(individualProviders);
    setMidLevels(midLevels);
  }

  return {
    healthcareEntities,
    addHealthcareEntity,
    updateHealthcareEntity,
    deleteHealthcareEntity,
    copyHealthcareEntity,

    individualProviders,
    updateIndividualProvider,
    deleteIndividualProvider,
    addIndividualProvider,
    copyIndividualProvider,

    midLevels,
    addMidLevel,
    updateMidLevel,
    deleteMidLevel,
    copyMidLevel,

    slots,
    addSlot,
    updateSlot,
    deleteSlot,
    copySlot,

    quoteProperties,
    updateQuoteProperty,
    updateQuoteProperties,

    rosterValidationErrors,
    resolveValidationErrors,
    cleanupEmpty,
  };
}
