import { ChangeEvent, useEffect, useState } from "react";
import { Input, Select, Table, Tbody, Th, Thead, Tr, Td } from "@chakra-ui/react";
import { DatePicker, VerticalSection } from "components";
import { Carrier } from "models";
import * as queries from "graphql/queries";
import { useGraphqlApi } from "hooks";
import { Agency, SunlightBroker, getAgenciesAndBrokers } from "services/agencyBrokerService";

interface QuotePropertyTableProps {
  quoteProperties: any;
  onPropertyChange: (key: string, value: any) => void;
  onPropertiesChange: (properties: any) => void;
}

export function QuotePropertyTable({
  onPropertiesChange,
  quoteProperties,
  onPropertyChange,
}: QuotePropertyTableProps) {
  const [selectedAgency, setSelectedAgency] = useState<string>(quoteProperties.agencyId || "");
  const [agencies, setAgencies] = useState<Agency[]>();
  const [allBrokers, setAllBrokers] = useState<SunlightBroker[]>();
  const [agencyBrokers, setAgencyBrokers] = useState<SunlightBroker[]>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { data: carriers } = useGraphqlApi<Carrier[]>(queries.listCarriers);

  const [standardBroker, setStandardBroker] = useState<boolean>(true);
  async function setAgenciesAndBrokers(result: any) {
    const res = JSON.parse(result.body);
    const brokers: SunlightBroker[] = res.brokers;
    setAgencies(
      res.agencies.sort((a: Agency, b: Agency) =>
        a.name.normalize().localeCompare(b.name.normalize()),
      ),
    );
    if (selectedAgency && !agencyBrokers) {
      const filteredBrokers = brokers?.filter((b) => b.agencyCode === selectedAgency);
      setAgencyBrokers(
        filteredBrokers?.sort((a, b) => a.name.normalize().localeCompare(b.name.normalize())),
      );
    }
    setAllBrokers(res.brokers);
  }

  // ensures default commission rate set on page load
  useEffect(() => {
    async function fetchAgenciesAndBrokers() {
      setIsLoading(true);
      const agenciesAndBrokers = await getAgenciesAndBrokers();
      await setAgenciesAndBrokers(agenciesAndBrokers);
      setIsLoading(false);
    }
    fetchAgenciesAndBrokers();

    onPropertyChange("commissionRate", "CS-12-10-5");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // This code was done in a very hardcodced fashion.  The next change should refactor to a more dynamic approach
    // The brokerIds correspond to HillB group in dev and prod
    if (
      ["BR13418", "00015154"].includes(quoteProperties.brokerId) &&
      ["ProAssurance", "NORCAL"].includes(quoteProperties.currentCarrier)
    ) {
      setStandardBroker(false);
      onPropertyChange("commissionRate", "Commission Custom");
    } else if (["BR33654"].includes(quoteProperties.brokerId)) {
      setStandardBroker(false);
      onPropertyChange("commissionRate", "Commission Custom");
    } else {
      setStandardBroker(true);
      onPropertyChange("commissionRate", "CS-12-10-5");
    }
  }, [quoteProperties.brokerId, quoteProperties.currentCarrier, onPropertyChange]);

  const handleAgencyChange = (event: ChangeEvent<HTMLSelectElement>) => {
    if (Array.isArray(agencies)) {
      const agency = agencies.find((a) => a.agencyCode === event.target.value);
      if (agency) {
        onPropertiesChange({
          agencyId: event.target.value,
          agencyName: agency?.name,
          brokerName: "",
          brokerId: "",
        });
        setSelectedAgency(agency?.agencyCode || "");
        const brokers = allBrokers?.filter((b) => b.agencyCode === agency.agencyCode);
        setAgencyBrokers(
          brokers?.sort((a, b) => a.name.normalize().localeCompare(b.name.normalize())),
        );
      }
    }
    if (event.target.value === "") {
      setSelectedAgency("");
      onPropertiesChange({
        brokerName: "",
        brokerId: "",
        agencyName: "",
        agencyId: "",
      });
    }
  };

  const handleBrokerChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const selectedAgent = allBrokers?.find((b) => b.brokerCode === event.target.value);
    onPropertiesChange({
      brokerId: event.target.value,
      brokerName: selectedAgent?.name || "",
    });
  };

  const handleSubmissionEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
    onPropertyChange("submissionEmail", event.target.value);
  };

  const handleCommissionRateChange = (event: ChangeEvent<HTMLSelectElement>) => {
    onPropertyChange("commissionRate", event.target.value);
  };

  const handleCurrentCarrierChange = (event: ChangeEvent<HTMLSelectElement>) => {
    onPropertyChange("currentCarrier", event.target.value);
  };

  const handleChangeEffectiveDate = (date: string) => {
    onPropertyChange("effectiveDate", date);
  };

  const addDays = (date: Date, days: number): Date => {
    const utcseconds = date.setDate(date.getDate() + days);
    const d = new Date(0);
    d.setUTCMilliseconds(utcseconds);
    return d;
  };
  return (
    <VerticalSection>
      <VerticalSection>
        <Table
          variant="striped"
          colorScheme="teal"
          size="md"
          fontWeight="500"
          id="quote-properties-table"
        >
          <Thead>
            <Tr>
              <Th minW="50px" m="0px" p="0px">
                Agency
              </Th>
              <Th minW="50px" m="0px" p="0px">
                Broker
              </Th>
              <Th minW="50px" m="0px" p="0px">
                Contact Email
              </Th>
              <Th minW="50px" m="0px" p="0px">
                Commission Rate
              </Th>
              <Th minW="50px" m="0px" p="0px">
                Current Carrier
              </Th>
              <Th minW="50px" m="0px" p="0px">
                Effective Date
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            <Tr fontSize="md" m="0px" p="0px">
              <Td m="0px" p="0 0px">
                <Select
                  placeholder={isLoading ? "Loading..." : "Select Agency"}
                  onChange={handleAgencyChange}
                  id="broker-select"
                  disabled={isLoading}
                  m="0px"
                  p="0px"
                  value={quoteProperties.agencyId}
                >
                  {agencies?.map((a: Agency) => (
                    <option key={a.agencyCode} value={a.agencyCode || ""}>
                      {a.name}
                    </option>
                  ))}
                </Select>
              </Td>
              <Td m="0px" p="0 0px">
                <Select
                  placeholder={"Select Broker"}
                  onChange={handleBrokerChange}
                  disabled={
                    !!quoteProperties.agencyId
                      ? false
                      : !selectedAgency || !agencyBrokers || agencyBrokers.length === 0
                  }
                  id="producer-select"
                  m="0px"
                  p="0px"
                  value={quoteProperties.brokerId}
                >
                  {Array.isArray(agencyBrokers) &&
                    agencyBrokers?.map((b: SunlightBroker) => (
                      <option key={b.brokerCode} value={b.brokerCode || ""}>
                        {b.name}
                      </option>
                    ))}
                </Select>
              </Td>
              <Td m="0px" p="0 0px">
                <Input
                  m="0px"
                  type="email"
                  placeholder="Submission Email"
                  value={quoteProperties.submissionEmail || ""}
                  onChange={handleSubmissionEmailChange}
                />
              </Td>
              <Td m="0px" p="0 0px">
                <Select
                  // placeholder="Select Commission Rate"
                  onChange={handleCommissionRateChange}
                  id="commission-rate-select"
                  m="0px"
                  p="0px"
                  disabled={standardBroker}
                  value={quoteProperties.commissionRate}
                >
                  <option key="CS-12-10-5" value="CS-12-10-5">
                    Standard Commission
                  </option>
                  <option key="Commission Custom" value="Commission Custom">
                    Incentivized Commision
                  </option>
                </Select>
              </Td>
              <Td>
                <Select
                  onChange={handleCurrentCarrierChange}
                  id="current-carrier-select"
                  m="0px"
                  p="0px"
                  value={quoteProperties.currentCarrier}
                >
                  <option key="" value=""></option>
                  {Array.isArray(carriers) &&
                    carriers
                      .sort((a, b) => (a.name > b.name ? 1 : -1))
                      .map((c: Carrier) => (
                        <option key={c.name} value={c.name}>
                          {c.name}
                        </option>
                      ))}
                  <option key="Other" value="Other">
                    Other
                  </option>
                  <option key="New to Practice" value="New to Practice">
                    New to Practice
                  </option>
                </Select>
              </Td>
              <Td>
                <DatePicker
                  selected={quoteProperties?.effectiveDate}
                  onChange={handleChangeEffectiveDate}
                  includeDateIntervals={[
                    { start: addDays(new Date(), -60), end: addDays(new Date(), 120) },
                  ]}
                />
              </Td>
            </Tr>
          </Tbody>
        </Table>
      </VerticalSection>
    </VerticalSection>
  );
}
