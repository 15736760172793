import { Box, Button, Table, Tbody, Td, Text, Th, Thead, Tr } from "@chakra-ui/react";
import { useState, useEffect } from "react";
import { resolveNpi, userMultipleNpiSelection } from "services/npiResolutionService.ts";
import { NpiRegistryFetcher, NpiResolutionStorage } from "services/npiRegistryService.js";
import { getSpecialtyDetails } from "services/indigoSpecialtyService.js";
import { Spinner } from "components";
import { useAnalytics } from "providers";
import { AMOUNT_OF_NPI_MATCHES } from "analytics";

const VerticalSection = ({ children }) => {
  return (
    <Box mb={4} mx="auto">
      <Box overflowX="auto">{children}</Box>
    </Box>
  );
};

const PractitionersRow = (individualProvider, index, onResolve, onRowChange) => {
  const [resolveResponse, setResolveResponse] = useState(null);
  const analytics = useAnalytics();
  useEffect(() => {
    if (resolveResponse) {
      analytics.track(AMOUNT_OF_NPI_MATCHES, {
        amount: resolveResponse.length,
      });
    }
  }, [analytics, resolveResponse]);

  useEffect(() => {
    async function fetchData() {
      if (individualProvider.npi) {
        const knownNpiResponse = await resolveNpi(
          { number: individualProvider.npi },
          new NpiRegistryFetcher(),
        );
        setResolveResponse(knownNpiResponse);
        if (!individualProvider.nppes_result) {
          onRowChange(index, "nppes_result", knownNpiResponse[0]);
        }
        return;
      }

      const resolveInput = {
        first_name: individualProvider.firstName,
        last_name: individualProvider.lastName,
        state: individualProvider.addressObj?.state,
        licenseNumber: individualProvider.licenseNumber,
        licenseState: individualProvider.licenseState,
        taxonomySearches: getSpecialtyDetails(individualProvider.indigoSpecialty).search,
      };
      const npiResolveResponse = await resolveNpi(resolveInput, new NpiRegistryFetcher(), false);
      setResolveResponse(npiResolveResponse);

      if (npiResolveResponse.length === 1 && !npiResolveResponse[0].description) {
        userMultipleNpiSelection(resolveInput, npiResolveResponse, 0, new NpiResolutionStorage());
        onRowChange(index, "npi", npiResolveResponse[0].number);
        onRowChange(index, "nppes_result", npiResolveResponse[0]);
      }
    }
    fetchData();
  }, [
    individualProvider.npi,
    individualProvider.firstName,
    individualProvider.lastName,
    individualProvider.address,
    individualProvider.specialty,
    index,
    onRowChange,
    individualProvider.licenseNumber,
    individualProvider.licenseState,
    individualProvider.nppes_result,
    individualProvider.addressObj?.state,
    individualProvider.indigoSpecialty,
  ]);

  const name = individualProvider.firstName + " " + individualProvider.lastName;

  let nppes_name = "";
  let nppes_specialty = "";
  let nppes_address = "";
  let nppes_error = "";

  if (resolveResponse !== null && resolveResponse.length === 1) {
    const x = resolveResponse[0];
    if (x.description) {
      nppes_error = `${x.description}`;
    } else {
      nppes_name = [x.basic.first_name, x.basic.middle_name, x.basic.last_name]
        .join(" ")
        .replace("  ", " ");
      nppes_specialty = x.taxonomies.map((x) => x.desc).join(", ");
      nppes_address = x.addresses
        .map((x) => [x.address_1, x.city, x.state, x.postal_code].join(" "))
        .join(", ");
    }
  }

  return (
    <Tr key={index} fontSize="md" fontWeight="normal">
      <Td>
        <Text minW="120px">{name || ""}</Text>
      </Td>
      <Td>
        <Text minW="120px">{individualProvider.indigoSpecialty || ""}</Text>
      </Td>
      <Td>
        <Text minW="120px">{individualProvider.address || ""}</Text>
      </Td>
      <Td>
        <Text minW="120px">{nppes_name || ""}</Text>
      </Td>
      <Td>
        <Text minW="120px">{nppes_specialty || ""}</Text>
      </Td>
      <Td>
        <Text minW="120px">{nppes_address || ""}</Text>
      </Td>
      <Td>
        {resolveResponse === null ? (
          <Spinner />
        ) : nppes_error !== "" ? (
          <Text color="tomato">{nppes_error}</Text>
        ) : (
          <Text>{resolveResponse.length}</Text>
        )}
      </Td>
      <Td>
        {resolveResponse === null ? (
          <Spinner />
        ) : nppes_error !== "" ? (
          <Button
            onClick={() => {
              onResolve(individualProvider, resolveResponse);
            }}
          >
            Manual
          </Button>
        ) : resolveResponse.length > 1 ? (
          <Button
            onClick={() => {
              onResolve(individualProvider, resolveResponse);
            }}
          >
            Resolve
          </Button>
        ) : resolveResponse.length === 0 ? (
          <Button
            onClick={() => {
              onResolve(individualProvider, resolveResponse);
            }}
          >
            Manual
          </Button>
        ) : (
          <Text>{resolveResponse[0]["number"] || ""}</Text>
        )}
      </Td>
    </Tr>
  );
};

export default function PractitionersTable({ individualProviders, onResolve, onRowChange }) {
  return (
    <VerticalSection>
      <VerticalSection>
        <Text mb={2} fontWeight="bold" textAlign="left">
          Practitioners
        </Text>
      </VerticalSection>
      <VerticalSection>
        <Table variant="striped" colorScheme="teal" size="md">
          <Thead>
            <Tr>
              <Th>Name</Th>
              <Th>Indigo Specialty</Th>
              <Th>Address</Th>
              <Th>NPPES Name</Th>
              <Th>NPPES Specialty</Th>
              <Th>NPPES Address</Th>
              <Th>NPI Matches</Th>
              <Th>NPI</Th>
            </Tr>
          </Thead>
          <Tbody>
            {individualProviders.map((individualProvider, index) =>
              PractitionersRow(
                individualProvider,
                index,
                (input, results) => onResolve(input, results, index),
                onRowChange,
              ),
            )}
          </Tbody>
        </Table>
        {individualProviders.length === 0 ? <Text>no data available</Text> : <></>}
      </VerticalSection>
    </VerticalSection>
  );
}
