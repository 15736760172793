export enum Limit {
  "100000/300000" = "0.1M/0.3M",
  "200000/600000" = "0.2M/0.6M",
  "250000/750000" = "0.25M/0.75M",
  "500000/1500000" = "0.5M/1.5M",
  "1000000/3000000" = "1M/3M",
}

export enum LimitType {
  Separate = "3101",
  Shared = "3102",
}

export enum Discount {
  NEW_TO_PRACTICE = "New to Practice",
  PART_TIME = "Part Time",
  TEACHING = "Teaching",
  RESIDENCE_AND_FELLOWSHIP = "Residence & Fellowship",
  RETIRED_OR_VOLUNTEER = "Retired / Volunteer Physician",
  AMA = "AMA Discount Member",
  VICARIOUS_LIABILITY = "Vicarious Liability",
  CME = "CME Discount",
  BURNOUT = "Burnout Discount",
}
