import "./App.css";
import { useState, ReactNode } from "react";
import { Navigate } from "react-router-dom";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { WarningIcon } from "@chakra-ui/icons";
import {
  Text,
  Button,
  Box,
  Flex,
  VStack,
  StackDivider,
  Heading,
  List,
  ListItem,
  ListIcon,
  useDisclosure,
} from "@chakra-ui/react";
import RosterPage from "pages/roster/rosterPage";
import ResolvePage from "pages/resolve/resolvePage";
import ModelInsightPage from "pages/modelInsight/modelInsightPage";
import { ErrorOverlay } from "components";
import { useAnalytics } from "providers";
import { useAppModel } from "./AppModel";
import { ERROR_OVERLAY_ACTIVATED } from "analytics";
import { checkIfEmailAddressIsAllowed } from "utils";
const internalDomains = process.env.REACT_APP_INTERNAL_DOMAINS;
const internalEmailDomainAllowList =
  typeof internalDomains === "string" ? internalDomains.split(" ") : [];

interface AppHeaderProps {
  username?: string;
  signOut: () => void;
}

export function AppHeader({ username, signOut }: AppHeaderProps) {
  return (
    <Box p={4}>
      <Flex justify="space-around">
        <img
          className="logo"
          src="https://uploads-ssl.webflow.com/645029572ba4d37613789f79/645029572ba4d36e42789f80_230426_Indigo_Logo_Primary-p-1080.png"
          alt="Indigo Logo"
        />
        <Heading>Indigo Customer Insight Tool</Heading>
      </Flex>
      <Flex align="center">
        <span className="sign-out">
          <span>
            Logged in as:
            <br />
            {username}
          </span>
          <p>
            <Button onClick={signOut}>Sign out</Button>
          </p>
        </span>
      </Flex>
      <Flex justify="space-around">
        <Text>
          <b>Processing:</b> {new URLSearchParams(window.location.search).get("inquiry-id")}
        </Text>
      </Flex>
    </Box>
  );
}

export const App = () => {
  const [currentPage, setCurrentPage] = useState("roster");
  const [errors, setErrors] = useState<ReactNode | undefined>();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const analytics = useAnalytics();
  const { user, signOut } = useAuthenticator();
  const emailAddress = user?.attributes?.email || "";
  const notInternal = !checkIfEmailAddressIsAllowed(emailAddress, internalEmailDomainAllowList);

  const {
    healthcareEntities,
    addHealthcareEntity,
    updateHealthcareEntity,
    deleteHealthcareEntity,
    copyHealthcareEntity,

    individualProviders,
    addIndividualProvider,
    updateIndividualProvider,
    deleteIndividualProvider,
    copyIndividualProvider,

    midLevels,
    addMidLevel,
    updateMidLevel,
    deleteMidLevel,
    copyMidLevel,

    slots,

    quoteProperties,
    updateQuoteProperty,
    updateQuoteProperties,

    rosterValidationErrors,
    resolveValidationErrors,
    cleanupEmpty,
  } = useAppModel();

  const tryToSetCurrentPage = (page: string) => {
    cleanupEmpty();

    let errors: { invalidFields: string[]; itemsName: string }[] = [];
    if (currentPage === "roster") errors = rosterValidationErrors();
    else if (currentPage === "resolve" && page !== "roster") errors = resolveValidationErrors();

    const VALIDATION_ON = true;
    if (VALIDATION_ON && errors.length > 0) {
      analytics.track(ERROR_OVERLAY_ACTIVATED, {
        ...errors,
      });
      setErrors(
        <VStack
          spacing="24px"
          alignItems="flex-start"
          divider={<StackDivider borderColor="gray.200" />}
        >
          {errors.map((error) => {
            return (
              <VStack spacing="10px" alignItems="flex-start">
                <Heading as="h2" size="md">
                  {error.itemsName} is missing:
                </Heading>
                <List spacing={3}>
                  {error.invalidFields.map((field) => {
                    return (
                      <ListItem>
                        <ListIcon as={WarningIcon} color="red.500" />
                        {field}
                      </ListItem>
                    );
                  })}
                </List>
              </VStack>
            );
          })}
        </VStack>,
      );
      onOpen();
    } else {
      setCurrentPage(page);
    }
  };

  return notInternal ? (
    <Navigate to="/" />
  ) : (
    <div className="App">
      {/* <Test/> */}
      <main>
        <AppHeader
          username={user?.attributes?.email ? user?.attributes?.email : user?.username}
          signOut={() => {
            signOut?.();
            localStorage.removeItem("userIdentified");
          }}
        />
        <ErrorOverlay onClose={onClose} isOpen={isOpen} error={errors} />
        {currentPage === "roster" ? (
          <RosterPage
            onNavigate={tryToSetCurrentPage}
            healthcareEntities={healthcareEntities}
            onHealthcareEntityRowAdd={addHealthcareEntity}
            onHealthcareEntityRowChange={updateHealthcareEntity}
            onHealthcareEntityRowDelete={deleteHealthcareEntity}
            onHealthcareEntityRowCopy={copyHealthcareEntity}
            individualProviders={individualProviders}
            onIndividualProviderRowAdd={addIndividualProvider}
            onIndividualProviderRowChange={updateIndividualProvider}
            onIndividualProviderRowDelete={deleteIndividualProvider}
            onIndividualProviderRowCopy={copyIndividualProvider}
            midLevels={midLevels}
            onMidLevelsRowAdd={addMidLevel}
            onMidLevelsRowChange={updateMidLevel}
            onMidLevelsRowDelete={deleteMidLevel}
            onMidLevelsRowCopy={copyMidLevel}
            quoteProperties={quoteProperties}
            onQuotePropertyChange={updateQuoteProperty}
            onQuotePropertiesChange={updateQuoteProperties}
          />
        ) : currentPage === "resolve" ? (
          <ResolvePage
            onNavigate={tryToSetCurrentPage}
            individualProviders={individualProviders}
            onIndividualProviderRowChange={updateIndividualProvider}
          />
        ) : (
          <ModelInsightPage
            individualProviders={individualProviders}
            onNavigate={tryToSetCurrentPage}
            healthcareEntities={healthcareEntities}
            slots={slots}
            midLevels={midLevels}
            quoteProperties={quoteProperties}
            onIndividualProviderChange={updateIndividualProvider}
          />
        )}
      </main>
    </div>
  );
};
