import { Navigate, Outlet } from "react-router";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { checkIfEmailAddressIsAllowed } from "utils";
const internalDomains = process.env.REACT_APP_INTERNAL_DOMAINS;
const externalDomains = process.env.REACT_APP_EXTERNAL_DOMAINS;
const internalEmailDomainAllowList =
  typeof internalDomains === "string" ? internalDomains.split(" ") : [];
const externalEmailDomainAllowList =
  typeof externalDomains === "string" ? externalDomains.split(" ") : [];

interface ProtectedRouteProps {
  adminOnly?: boolean;
}

export function ProtectedRoute({ adminOnly }: ProtectedRouteProps) {
  const { user } = useAuthenticator();
  const emailAddress = user?.attributes?.email;

  const isAdmin = checkIfEmailAddressIsAllowed(emailAddress, internalEmailDomainAllowList);
  const isAllowedExternal = checkIfEmailAddressIsAllowed(
    emailAddress,
    externalEmailDomainAllowList,
  );
  const showRoute = adminOnly ? isAdmin : isAllowedExternal;

  return showRoute ? <Outlet /> : <Navigate to="/" replace />;
}
