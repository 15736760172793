import { Box, Button, Heading } from "@chakra-ui/react";
import { useDisclosure } from "@chakra-ui/react";
import { BreadcrumbNav, VerticalSection } from "components";
import { useState } from "react";
import { useAnalytics } from "providers";
import {
  PAGES,
  BACK_BUTTON_CLICKED,
  NEXT_BUTTON_CLICKED,
  OPEN_RESOLUTION_MODAL,
  CLOSE_RESOLUTION_MODAL,
} from "analytics";

import PractitionersTable from "./practitionersTable";
import { NpiResolutionModal } from "./npiResolutionModal.js";

export default function ResolvePage({
  individualProviders,
  onIndividualProviderRowChange,
  onNavigate,
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [practitionerResolveResponse, setPractitionerResolveResponse] = useState([]);
  const [practitionerIndex, setPractitionerIndex] = useState(0);
  const [userInput, setUserInput] = useState({});
  const analytics = useAnalytics();

  function onPractitionersResolve(input, results, index) {
    analytics.track(OPEN_RESOLUTION_MODAL);
    setPractitionerResolveResponse([...results]);
    setPractitionerIndex(index);
    setUserInput(input);
    onOpen();
  }

  return (
    <Box p={4}>
      <VerticalSection>
        <Heading as="h2" mb={4}>
          NPI Resolution
        </Heading>
      </VerticalSection>
      <VerticalSection>
        <BreadcrumbNav currentPage="resolve" onNavigate={onNavigate} />
      </VerticalSection>

      <PractitionersTable
        individualProviders={individualProviders}
        onResolve={onPractitionersResolve}
        onRowChange={onIndividualProviderRowChange}
      />

      <VerticalSection>
        <Button
          id="back-button"
          variant="outline"
          onClick={() => {
            analytics.track(BACK_BUTTON_CLICKED, {
              page: PAGES.RESOLUTION,
            });
            onNavigate("roster");
          }}
        >
          Back
        </Button>
        <Button
          id="next-button"
          variant="outline"
          onClick={() => {
            analytics.track(NEXT_BUTTON_CLICKED, {
              page: PAGES.RESOLUTION,
            });
            onNavigate("model_insight");
          }}
        >
          Next
        </Button>
      </VerticalSection>

      <NpiResolutionModal
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={() => {
          analytics.track(CLOSE_RESOLUTION_MODAL);
          onClose();
        }}
        userInput={userInput}
        onIndividualProviderRowChange={onIndividualProviderRowChange}
        practitionerResolveResponse={practitionerResolveResponse}
        practitionerIndex={practitionerIndex}
      />
    </Box>
  );
}
