import { useState } from "react";
import {
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Text,
  Input,
  Radio,
  Button,
  Modal,
  ModalBody,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalCloseButton,
} from "@chakra-ui/react";
import { userManualSelection, userMultipleNpiSelection } from "services/npiResolutionService.ts";
import { NpiResolutionStorage } from "services/npiRegistryService.js";
import { Pagination } from "components/pagination";
import { useAnalytics } from "providers";
import { SELECT_NPI_FROM_RESOLUTION_MODAL } from "analytics";

const PAGE_SIZE = 10;

function NpiResolutionModal({
  isOpen,
  onClose,
  userInput,
  practitionerResolveResponse,
  practitionerIndex,
  onIndividualProviderRowChange,
}) {
  const analytics = useAnalytics();
  const [selectedRow, setSelectedRow] = useState(-1);
  const [manualNpi, setManualNpi] = useState();
  const [currentPage, setCurrentPage] = useState(0);
  const amountOfPages = Math.ceil(practitionerResolveResponse.length / PAGE_SIZE);
  const onManualInputChange = (event) => {
    setManualNpi(event.target.value);
    setSelectedRow(0); // so Ok button is enabled
  };

  const onMultipleInputChange = (newNpi, index) => {
    analytics.track(SELECT_NPI_FROM_RESOLUTION_MODAL);
    setManualNpi(newNpi);
    setSelectedRow(index);
  };

  const onOkClick = () => {
    onClose();
    onIndividualProviderRowChange(practitionerIndex, "npi", manualNpi);
    if (practitionerResolveResponse.length === 0) {
      userManualSelection(
        {
          first_name: userInput.first_name,
          last_name: userInput.last_name,
          specialty: userInput.specialty,
          address: userInput.address,
        },
        manualNpi,
        new NpiResolutionStorage(),
      );
    } else {
      userMultipleNpiSelection(
        userInput,
        practitionerResolveResponse,
        practitionerIndex,
        new NpiResolutionStorage(),
      );
      onIndividualProviderRowChange(
        practitionerIndex,
        "nppes_result",
        practitionerResolveResponse[selectedRow],
      );
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent minW="800px">
        <ModalHeader>
          {practitionerResolveResponse.length === 0
            ? "NPI Resolution - NPI Not Found"
            : "NPI Resolution"}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {practitionerResolveResponse.length === 0 ? (
            <>
              <Text>
                We cannot find a record with this information. Please manually enter the NPI.
              </Text>
              <Input placeholder="Enter npi" onChange={onManualInputChange} />
            </>
          ) : practitionerResolveResponse[0].description ? (
            <>
              <Text>{`${practitionerResolveResponse[0].description}.  Please manually enter the NPI.`}</Text>
              <Input placeholder="Enter npi" onChange={onManualInputChange} />
            </>
          ) : (
            <>
              <Text>
                We found the following matching records. Please select the correct practitioner to
                continue.
              </Text>
              <Table variant="striped" colorScheme="teal" size="sm">
                <Thead>
                  <Tr>
                    <Th></Th>
                    <Th>NPI Specialty</Th>
                    <Th>NPI Address</Th>
                    <Th>NPI</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {practitionerResolveResponse
                    .slice(currentPage * PAGE_SIZE, currentPage * PAGE_SIZE + PAGE_SIZE)
                    .map((modalResult, index) => {
                      const address = modalResult.addresses
                        .map((x) => [x.address_1, x.city, x.state, x.postal_code].join(" "))
                        .join(", ");
                      const specialty = modalResult.taxonomies.map((x) => x.desc).join(", ");
                      return (
                        <Tr key={index}>
                          <Td>
                            <Radio
                              isChecked={selectedRow === index}
                              onChange={() => onMultipleInputChange(modalResult.number, index)}
                            />
                          </Td>
                          <Td>{specialty}</Td>
                          <Td>{address}</Td>
                          <Td>{modalResult.number}</Td>
                        </Tr>
                      );
                    })}
                </Tbody>
              </Table>
            </>
          )}
          {amountOfPages > 1 && (
            <Pagination
              currentPage={currentPage}
              amountOfPages={amountOfPages}
              moveForward={() => setCurrentPage(currentPage + 1)}
              moveBack={() => setCurrentPage(currentPage - 1)}
              onChangePage={setCurrentPage}
            />
          )}
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={onClose}>
            Cancel
          </Button>
          <Button variant="ghost" onClick={onOkClick} isDisabled={selectedRow === -1}>
            Ok
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export { NpiResolutionModal };
