import { Container, Heading } from "@chakra-ui/react";
interface ErrorProps {
  errorText: string;
}

export function Error({ errorText }: ErrorProps) {
  return (
    <Container mt="100px">
      <Heading color="red">{errorText}</Heading>
    </Container>
  );
}
