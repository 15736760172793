import { v4 as uuidv4 } from "uuid";

export type NpiResolveInput = {
  first_name: string;
  last_name: string;
  state: string;
  taxonomySearches?: string[];
  number?: string;
  licenseNumber?: string;
  licenseState?: string;
};

// first_name, last_name, state, postal_code, specialty

// TODO: Per Postel's Law, we shouldn't enforce a schema on objects not under our control
export type NPIRegistryResponseError = {
  description?: string;
  field?: string;
  number?: string;
};
export type NPIRegistryResponseResult = {
  number: string;
  addresses: [
    { address_1: string; address_2?: string; state: string; city: string; postal_code: string },
  ];
  taxonomies: [{ desc: string }];
  basic: {
    first_name: string;
    last_name: string;
    credential?: string;
    prefix?: string;
  };
};
export type NPIRegistryResponse = NPIRegistryResponseResult[] | NPIRegistryResponseError[];

export type NpiRegistryFetcherInput = {
  number?: string;
  first_name?: string;
  last_name?: string;
  state?: string;
  taxonomy_description?: string;
};

export type NpiResolution = {
  session_id: string;
  timestamp: string;
  npi?: string;
  match_status?: string;
  input?: object;
  nppes_data?: object;
};

export interface INpiRegistryFetcher {
  fetch(input: NpiRegistryFetcherInput): Promise<NPIRegistryResponse>;
}

export interface INpiResolutionStorage {
  store(resolution: NpiResolution): Promise<void>;
}

export async function resolveNpi(
  input: NpiResolveInput,
  fetcher: INpiRegistryFetcher,
  useSpecialtyInLookup: boolean = true,
): Promise<NPIRegistryResponse> {
  return new Promise(async (resolve, reject) => {
    if (input["number"]) {
      return resolve(await fetcher.fetch({ number: input["number"] }));
    }

    const tryToNarrowByLicense = (results: any) => {
      console.log("tryToNarrowByLicense: ");
      console.log(input);
      console.log(results);
      const filteredResults = results.filter((result: any) => {
        if (input.licenseNumber && input.licenseState)
          return (
            result.taxonomies.filter((taxonomy: any) => {
              return (
                taxonomy.state === input.licenseState && taxonomy.license === input.licenseNumber
              );
            }).length > 0
          );
        return true;
      });
      if (filteredResults.length === 0) {
        return results;
      }
      return filteredResults;
    };

    const tryToSearchWithSpecialty = async (input: NpiResolveInput, retries: number = 4) => {
      console.log("tryToSearchWithSpecialty input: ", input);
      let results = null;
      if (input.taxonomySearches) {
        for (const search of input.taxonomySearches) {
          for (let i = 0; i < retries; i++) {
            let res = await fetcher.fetch({
              first_name: input.first_name,
              last_name: input.last_name,
              state: input.state,
              taxonomy_description: search,
            });

            if (res.length > 0) {
              if (
                "description" in res[0] &&
                res[0].description === "No taxonomy codes found with entered description"
              ) {
                console.log("no taxonomy codes, skipping: " + search);
                break;
              }
              console.log("returning on taxonomy search: " + search, res);
              results = res;
              return res;
            }
          }
        }
      }
      return results;
    };

    if (useSpecialtyInLookup) {
      const resultsNSS = await tryToSearchWithSpecialty(input);
      if (resultsNSS && resultsNSS.length > 0) {
        // propogate case of =1 as well as >1, otherwise move on to NS
        return resolve([...resultsNSS]);
      }
    }

    const resultsNS = tryToNarrowByLicense(
      await fetcher.fetch({
        first_name: input.first_name,
        last_name: input.last_name,
        state: input.state,
      }),
    );
    console.log("resultsNS: ");
    console.log(resultsNS);
    if (resultsNS.length > 0) {
      // propogate case of =1 as well as >1, otherwise move on to N
      return resolve([...resultsNS]);
    }
    const resultsN = tryToNarrowByLicense(
      await fetcher.fetch({
        first_name: input.first_name,
        last_name: input.last_name,
      }),
    );
    console.log("resultsN: ");
    console.log(resultsN);
    return resolve([...resultsN]); // propogate all cases (=1, >1, =0)
  });
}

function isMatch(str1: string, str2: string) {
  if (!str1 || !str2) {
    return str1 === str2;
  } else {
    return str1.toLowerCase() === str2.toLowerCase();
  }
}

const session_id = uuidv4();

export async function userMultipleNpiSelection(
  input: NpiResolveInput,
  response: NPIRegistryResponse,
  selectedIndex: number,
  resolutionStorage: INpiResolutionStorage,
): Promise<void> {
  // address mismatch between NPI API and user entered?
  const selectedNpi = response[selectedIndex] as NPIRegistryResponseResult;
  let npiResolution: NpiResolution = {
    session_id: session_id,
    timestamp: new Date().toISOString(),
    npi: selectedNpi.number,
    input: input,
    nppes_data: selectedNpi,
  };
  if (!isMatch(selectedNpi?.addresses[0].state, input.state)) {
    npiResolution.match_status = "Address Mismatch";
    npiResolution.timestamp = new Date().toISOString();
    console.log("address mismatch logging: " + npiResolution.timestamp);
    await resolutionStorage.store(npiResolution);
  }
  if (
    !isMatch(selectedNpi?.basic?.first_name, input.first_name) ||
    !isMatch(selectedNpi?.basic?.last_name, input.last_name)
  ) {
    npiResolution.match_status = "Name Mismatch";
    npiResolution.timestamp = new Date().toISOString();
    console.log("name mismatch logging: " + npiResolution.timestamp);
    await resolutionStorage.store(npiResolution);

    // if (!isMatch(selectedNpi?.taxonomies[0].desc, input.taxonomy_description)) {
    //   npiResolution.match_status = "Specialty Mismatch";
    //   npiResolution.timestamp = new Date().toISOString();
    //   console.log("specialty mismatch logging: " + npiResolution.timestamp);
    //   await resolutionStorage.store(npiResolution);
  }
}

export async function userManualSelection(
  input: NpiResolveInput,
  userEnteredNpi: string,
  resolutionStorage: INpiResolutionStorage,
) {
  const npiResolution: NpiResolution = {
    session_id: uuidv4(),
    timestamp: new Date().toISOString(),
    npi: userEnteredNpi,
    match_status: "Manual User Entry",
    input: input,
  };
  await resolutionStorage.store(npiResolution);
}
