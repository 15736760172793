export const components = {
  Badge: {
    baseStyle: {
      borderRadius: "2px",
      padding: "8px 18px",
      fontSize: "1rem",
      textTransform: "capitalize",
    },
  },
  Button: {
    baseStyle: {
      paddingBottom: "5px",
      borderRadius: "2px",
      margin: "5px",
    },
    variants: {
      ghost: {
        bg: "transparent",
      },
      outline: {
        bg: "white",
        borderColor: "indigo.500",
        color: "indigo.500",
      },
    },
  },
  Input: {
    defaultProps: {
      focusBorderColor: "indigo.500",
    },
  },
  Select: {
    defaultProps: {
      focusBorderColor: "indigo.500",
    },
  },
};
