import { BrokerInquiryProviderInput } from "API";

export function formatProvidersLimits(providers?: BrokerInquiryProviderInput[] | null) {
  if (!providers) {
    return;
  }
  return providers.map((provider) => {
    if (!provider.limit) {
      return provider;
    }

    const [limitOccurrence, limitAggregate] = provider.limit.split("/");
    return {
      ...provider,
      limitOccurrence,
      limitAggregate,
    };
  });
}
