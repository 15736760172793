import { Navigate, useNavigate } from "react-router-dom";
import { Auth } from "aws-amplify";
import { Button, Container, Heading } from "@chakra-ui/react";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { checkIfEmailAddressIsAllowed } from "utils";
const internalDomains = process.env.REACT_APP_INTERNAL_DOMAINS;
const externalDomains = process.env.REACT_APP_EXTERNAL_DOMAINS;
const internalEmailDomainAllowList =
  typeof internalDomains === "string" ? internalDomains.split(" ") : [];
const externalEmailDomainAllowList =
  typeof externalDomains === "string" ? externalDomains.split(" ") : [];

export function Unauthorized() {
  const { user } = useAuthenticator();
  const emailAddress = user?.attributes?.email;
  const navigate = useNavigate();
  const isInternal = checkIfEmailAddressIsAllowed(emailAddress, internalEmailDomainAllowList);
  const isAllowedExternal = checkIfEmailAddressIsAllowed(
    emailAddress,
    externalEmailDomainAllowList,
  );

  async function handleSignOut() {
    await Auth.signOut();
    navigate("/");
  }

  return isInternal || isAllowedExternal ? (
    <Navigate to="/" />
  ) : (
    <Container>
      <Heading>Unauthorized</Heading>
      <Button onClick={handleSignOut}>Return Home</Button>
    </Container>
  );
}
