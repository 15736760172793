import { useNavigate } from "react-router-dom";
import {
  Button,
  ButtonGroup,
  Image,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalHeader,
  Container,
  Center,
  Text,
  Flex,
  Box,
} from "@chakra-ui/react";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { useAnalytics } from "providers";
import { Slime } from "components";
import { NEW_PROVIDER } from "mocks";
import { generateId } from "utils";
import { useGraphqlApi } from "hooks";
import { createBrokerInquiry } from "graphql/mutations";
import { CreateBrokerInquiryInput } from "API";
import { RAT_EVENTS } from "analytics";

export function NewInquiry() {
  const navigate = useNavigate();
  const { postData } = useGraphqlApi();
  const { user, signOut } = useAuthenticator((context) => [context.user]);
  const { track } = useAnalytics();

  async function postInquiry(hasMultipleProviders: boolean) {
    try {
      const providerId = generateId();
      const inq = (await postData(createBrokerInquiry, {
        input: {
          author: { email: user.attributes?.email },
          hasMultipleProviders,
          providers: [{ ...NEW_PROVIDER, id: providerId }],
        },
      })) as CreateBrokerInquiryInput;
      if (!inq?.id) {
        throw new Error("Problem creating inquiry");
      }

      navigate(`/broker/inquiry/${inq.id}/provider-info`);
    } catch (e) {
      console.warn("Something went wrong creating an inquiry", e);
    }
  }
  return (
    <Modal
      scrollBehavior="inside"
      motionPreset="slideInBottom"
      onClose={() => null}
      size="full"
      isOpen={true}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Flex justifyContent="end">
            <Button variant="ghost" color="indigo" onClick={() => signOut()} w="132px">
              Sign Out
            </Button>
          </Flex>
        </ModalHeader>
        <ModalBody overflow="visible">
          <Container mt="150px" alignItems="center">
            <Flex direction="column">
              <Box>
                <Center>
                  <Image
                    mb="45px"
                    h="45px"
                    src="https://uploads-ssl.webflow.com/645029572ba4d37613789f79/645029572ba4d36e42789f80_230426_Indigo_Logo_Primary-p-1080.png"
                    alt="Indigo Logo"
                  />
                </Center>
              </Box>
              <Text align="center" fontSize="xl">
                Receive a quote assessment in a few easy steps.
                <br />A quick and simple way to gauge risk and qualification!
              </Text>
              <Text align="center" mt="60px">
                To get started select an Individual or Multiple Providers
              </Text>
              <Center>
                <ButtonGroup colorScheme="indigo">
                  <Button
                    onClick={() => {
                      track(RAT_EVENTS.SELECT_SINGLE_PROVIDER_FLOW);
                      postInquiry(false);
                    }}
                    w="132px"
                  >
                    Individual
                  </Button>
                  <Button
                    onClick={() => {
                      track(RAT_EVENTS.SELECT_MULTIPLE_PROVIDERS_FLOW);
                      postInquiry(true);
                    }}
                    w="132px"
                  >
                    Multiple
                  </Button>
                </ButtonGroup>
              </Center>
            </Flex>
          </Container>
        </ModalBody>
        <Slime />
      </ModalContent>
    </Modal>
  );
}
