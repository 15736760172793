/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const getBrokerInquiry = /* GraphQL */ `query GetBrokerInquiry($id: ID!) {
  getBrokerInquiry(id: $id) {
    id
    providers {
      id
      address {
        street
        secondary
        city
        state
        postalCode
        countyName
        countyFips
        isSecondary
        __typename
      }
      firstName
      specialty
      npi
      retroDate
      lastName
      limit
      limitAggregate
      limitOccurrence
      limitType
      cyberOptOut
      __typename
    }
    hasMultipleProviders
    riskColor
    modelId
    author {
      email
      __typename
    }
    notes
    policyType
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetBrokerInquiryQueryVariables,
  APITypes.GetBrokerInquiryQuery
>;
export const listBrokerInquiries = /* GraphQL */ `query ListBrokerInquiries(
  $filter: ModelBrokerInquiryFilterInput
  $limit: Int
  $nextToken: String
) {
  listBrokerInquiries(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      providers {
        id
        firstName
        specialty
        npi
        retroDate
        lastName
        limit
        limitAggregate
        limitOccurrence
        limitType
        cyberOptOut
        __typename
      }
      hasMultipleProviders
      riskColor
      modelId
      author {
        email
        __typename
      }
      notes
      policyType
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListBrokerInquiriesQueryVariables,
  APITypes.ListBrokerInquiriesQuery
>;
export const syncBrokerInquiries = /* GraphQL */ `query SyncBrokerInquiries(
  $filter: ModelBrokerInquiryFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncBrokerInquiries(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      providers {
        id
        firstName
        specialty
        npi
        retroDate
        lastName
        limit
        limitAggregate
        limitOccurrence
        limitType
        cyberOptOut
        __typename
      }
      hasMultipleProviders
      riskColor
      modelId
      author {
        email
        __typename
      }
      notes
      policyType
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncBrokerInquiriesQueryVariables,
  APITypes.SyncBrokerInquiriesQuery
>;
export const getCarrier = /* GraphQL */ `query GetCarrier($id: ID!) {
  getCarrier(id: $id) {
    id
    name
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCarrierQueryVariables,
  APITypes.GetCarrierQuery
>;
export const listCarriers = /* GraphQL */ `query ListCarriers(
  $filter: ModelCarrierFilterInput
  $limit: Int
  $nextToken: String
) {
  listCarriers(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCarriersQueryVariables,
  APITypes.ListCarriersQuery
>;
export const syncCarriers = /* GraphQL */ `query SyncCarriers(
  $filter: ModelCarrierFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncCarriers(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      name
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncCarriersQueryVariables,
  APITypes.SyncCarriersQuery
>;
export const getBrokerInquiryModelRating = /* GraphQL */ `query GetBrokerInquiryModelRating(
  $npi: String
  $countyFIPS: String
  $specialty: String
) {
  getBrokerInquiryModelRating(
    npi: $npi
    countyFIPS: $countyFIPS
    specialty: $specialty
  )
}
` as GeneratedQuery<
  APITypes.GetBrokerInquiryModelRatingQueryVariables,
  APITypes.GetBrokerInquiryModelRatingQuery
>;
export const getBrokerInquiryRiskColor = /* GraphQL */ `query GetBrokerInquiryRiskColor($paramsJson: String) {
  getBrokerInquiryRiskColor(paramsJson: $paramsJson)
}
` as GeneratedQuery<
  APITypes.GetBrokerInquiryRiskColorQueryVariables,
  APITypes.GetBrokerInquiryRiskColorQuery
>;
export const submitInquiry = /* GraphQL */ `query SubmitInquiry($payload: String) {
  submitInquiry(payload: $payload)
}
` as GeneratedQuery<
  APITypes.SubmitInquiryQueryVariables,
  APITypes.SubmitInquiryQuery
>;
