import { useCallback, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardBody,
  Container,
  Flex,
  Box,
  Text,
} from "@chakra-ui/react";
import { ArrowForwardIcon } from "@chakra-ui/icons";
import { useGraphqlApi } from "hooks";
import { getBrokerInquiry } from "graphql/queries";
import { updateBrokerInquiry } from "graphql/mutations";
import { Error, Spinner } from "components";
import { BrokerInquiry, BrokerInquiryProviderInput, BrokerInquiryProvider } from "API";
import { OptionCard } from "./OptionCard";
import { fetchProvidersNppes } from "./helpers";
import { useAnalytics } from "providers";
import { RAT_EVENTS } from "analytics";

export function ResolveNpi() {
  const navigate = useNavigate();
  const { track } = useAnalytics();
  const { submissionId } = useParams();
  const [isFetchingNpiData, setIsFetchingNpiData] = useState(true);
  const [updatedProviders, setUpdatedProviders] = useState<BrokerInquiryProvider[] | undefined>();
  const inquiryVariables = {
    id: submissionId,
  };
  const [unresolvedNpis, setUnresolvedNpis] = useState<{ [key: string]: any[] }>({});
  const {
    data: inquiry,
    postData,
    loading,
    error,
  } = useGraphqlApi<BrokerInquiry>(getBrokerInquiry, inquiryVariables);

  const fetchNppes = useCallback(async (providers: BrokerInquiryProvider[]) => {
    const unresolvedList = await fetchProvidersNppes(providers);
    setUnresolvedNpis(unresolvedList);
    setIsFetchingNpiData(false);
  }, []);

  useEffect(() => {
    if (loading === false && inquiry) {
      console.log("Running effect for resolve npi");
      const providers = inquiry?.providers || [];
      fetchNppes(providers);
    }
    // This is to be fixed in https://getindigo.atlassian.net/browse/PK-461
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, fetchNppes]);

  function handleChangeSelectedOption(providerId: string, npi: string) {
    const providers = inquiry?.providers?.map((provider) => {
      if (provider.id === providerId) {
        provider.npi = npi;
        return provider;
      }
      return provider;
    });
    setUpdatedProviders(providers);
  }

  async function saveNpiSelection() {
    await postData(updateBrokerInquiry, { input: { ...inquiry, providers: updatedProviders } });
    navigate(`/broker/inquiry/${submissionId}/submit`);
  }

  return error ? (
    <Error errorText="Something went wrong, please contact support." />
  ) : isFetchingNpiData || loading ? (
    <Spinner />
  ) : (
    <Container mt="100px" maxW="833px">
      <Card maxWidth="833px" border="1px" borderColor="gray.150">
        <CardHeader p="0" bg="indigo.50" fontSize="sm">
          <Flex px="25px" py="15px" alignItems="center" justifyContent="space-between">
            We found the following providers, please pick one
          </Flex>
        </CardHeader>
        <CardBody pt="0px">
          {inquiry?.providers?.map((provider: BrokerInquiryProviderInput) => {
            return (
              <Box key={provider.id}>
                <Flex gap="13px" my="20px" alignItems="center">
                  <Badge colorScheme="yellow" px="12px" borderRadius="3px" pb="2px">
                    <Text
                      fontSize="xm"
                      pb="8px"
                      color="black"
                      fontStyle="lowercase"
                      textTransform="none"
                    >
                      {provider.firstName} {provider.lastName}
                    </Text>
                  </Badge>
                  <Text fontSize="sm" color="black" fontStyle="lowercase" textTransform="none">
                    We found {unresolvedNpis?.length} matching result
                    {unresolvedNpis[provider.id]?.length !== 1 ? "s" : ""}:
                  </Text>
                </Flex>
                <OptionCard
                  selectedNpi={provider.npi}
                  onChangeSelectedNpi={(npi) => handleChangeSelectedOption(provider.id, npi)}
                  unresolvedNpis={unresolvedNpis[provider.id]}
                />
              </Box>
            );
          })}
        </CardBody>
      </Card>
      <Flex justifyContent="flex-end" mt="30px">
        <Button
          isLoading={loading}
          onClick={() => {
            track(RAT_EVENTS.ASSESS_RISK);
            saveNpiSelection();
          }}
          rightIcon={<ArrowForwardIcon />}
          colorScheme="indigo"
        >
          Next, Assess Risk
        </Button>
      </Flex>
    </Container>
  );
}
