async function autocomplete(search) {
  const searchEnc = encodeURIComponent(search);
  const key = "170117547993626608";
  const url = `https://us-autocomplete-pro.api.smartystreets.com/lookup?key=${key}&search=${searchEnc}`;

  return new Promise((resolve, reject) => {
    fetch(url, {
      method: "GET",
      headers: {
        Referer: "localhost",
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not OK");
        }
        return response.json();
      })
      .then((data) => {
        // console.log(data);
        resolve(data);
      })
      .catch((error) => {
        // console.error('Error:', error);
        reject(error);
      });
  });
}

async function details(street, city, state) {
  const host = "us-street.api.smartystreets.com";
  const key = "170117547993626608";
  const _street = encodeURIComponent(street);
  const _city = encodeURIComponent(city);
  const url = `https://${host}/street-address?key=${key}&license=us-core-cloud&street=${_street}&city=${_city}&state=${state}&candidates=10`;

  return new Promise((resolve, reject) => {
    fetch(url, {
      method: "GET",
      headers: {
        Referer: "localhost",
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not OK");
        }
        return response.json();
      })
      .then((data) => {
        if (data) {
          const addressData = data.map((d) => {
            return {
              countyName: d.metadata.county_name,
              countyFips: d.metadata.county_fips,
              postalCode: d.components.zipcode,
            };
          })[0];
          resolve(addressData);
        } else {
          throw new Error("Failed to fetch address data");
        }
      })
      .catch((error) => {
        // console.error('Error:', error);
        reject(error);
      });
  });
}

export { autocomplete, details };
