import { ReactNode } from "react";
import { Box } from "@chakra-ui/react";

interface VerticalSectionProps {
  children: ReactNode;
}

export function VerticalSection({ children }: VerticalSectionProps) {
  return (
    <Box mb={4} mx="auto">
      <Box overflowX="auto">{children}</Box>
    </Box>
  );
}
