import { Flex, useRadioGroup, Text, Box } from "@chakra-ui/react";
import { UnresolvedNpi } from "types";
import { CustomRadio } from "./CustomRadio";
import { CircleIcon } from "components";

interface OptionCardProps {
  unresolvedNpis: UnresolvedNpi[];
  onChangeSelectedNpi: (npi: string) => void;
  selectedNpi?: string | null;
}
export function OptionCard({ selectedNpi, unresolvedNpis, onChangeSelectedNpi }: OptionCardProps) {
  const { getRootProps, getRadioProps } = useRadioGroup({
    defaultValue: selectedNpi || "",
    name: "npi",
    onChange: onChangeSelectedNpi,
  });
  const group = getRootProps();

  return (
    <Flex direction="column" gap="10px" {...group} tabIndex={0}>
      {unresolvedNpis?.map((unresolved, index) => {
        const radio = getRadioProps({ value: `${unresolved.npi}` });
        return (
          <CustomRadio key={index} radioProps={radio}>
            <Flex justifyContent="space-between" direction={["column", "row"]}>
              <Flex>
                <CircleIcon pr="12px" boxSize={9} color={radio.isChecked ? "indigo" : "white"} />
                <Flex direction="column">
                  <Box textTransform="capitalize">
                    {unresolved.firstName.toLowerCase()} {unresolved.lastName.toLowerCase()}
                  </Box>
                  <Box textTransform="capitalize">{unresolved.specialty}</Box>
                  <Flex
                    gap="5px"
                    textTransform="capitalize"
                    fontWeight={500}
                    direction={["column", "row"]}
                  >
                    {unresolved.address.address1.toLowerCase()}
                    {unresolved.address.address2?.toLowerCase()},
                    <Text textTransform="capitalize">{unresolved.address.city.toLowerCase()},</Text>
                    {unresolved.address.state} {unresolved.address.postalCode.slice(0, 5)}
                  </Flex>
                </Flex>
              </Flex>
              <Box>NPI: {unresolved.npi}</Box>
            </Flex>
          </CustomRadio>
        );
      })}
    </Flex>
  );
}
