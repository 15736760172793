import { Amplify, API } from "aws-amplify";
import awsconfig from "../aws-exports";

const myAPI = "api2b38d508";
const path = "/submitToSunlight/agents";

Amplify.configure(awsconfig);
API.configure(awsconfig);

export type Agency = {
  agencyCode: string;
  name: string;
};

export type SunlightBroker = {
  agencyCode: string;
  brokerCode: string;
  name: string;
};

export type AgencyBrokerResult = {
  agencies: Agency[];
  brokers: SunlightBroker[];
};

export async function getAgenciesAndBrokers(): Promise<String> {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await API.get(myAPI, path, {}));
    } catch (error) {
      reject(error);
    }
  });
}
