import { useState, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { IconButton, Center, Flex, Text } from "@chakra-ui/react";
import { CloseIcon, ArrowUpIcon, CheckIcon } from "@chakra-ui/icons";
import { generateId } from "utils";

interface DocumentUploaderProps {
  selectedFiles: File[];
  setSelectedFiles: React.Dispatch<React.SetStateAction<File[]>>;
  error?: string;
}
export function DocumentUploader({
  error,
  selectedFiles,
  setSelectedFiles,
}: DocumentUploaderProps) {
  const [localIds, setLocalIds] = useState<string[]>([]);
  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      const newLocalIds = acceptedFiles.map(() => generateId());

      setLocalIds([...localIds, ...newLocalIds]);
      setSelectedFiles((prev) => [...prev, ...acceptedFiles]);
    },
    [setSelectedFiles, setLocalIds, localIds],
  );

  const handleRemoveFile = (index: number) => {
    const newSelectedFiles = selectedFiles.filter((_, i) => i !== index);
    const newLocalIds = localIds.filter((_, i) => i !== index);
    setSelectedFiles(newSelectedFiles);
    setLocalIds(newLocalIds);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
  return (
    <>
      <div {...getRootProps()} data-testid="dropzone">
        {error && <Text color="red">{error}</Text>}
        <Flex
          backgroundColor="white"
          border="1px"
          borderStyle={error ? "solid" : "dashed"}
          borderColor={error ? "red" : "gray.200"}
          borderRadius="3px"
          height="80px"
          alignItems="center"
        >
          <Center
            m="0 auto"
            border="2px"
            borderColor={isDragActive ? "green.100" : "indigo.100"}
            borderRadius="100%"
            height="30px"
            width="30px"
          >
            <input {...getInputProps()} />
            {isDragActive ? (
              <CheckIcon fontSize="20px" color="green.100" />
            ) : (
              <ArrowUpIcon fontSize="20px" color="indigo.100" />
            )}
          </Center>
        </Flex>
      </div>
      {selectedFiles.map((file: File, index) => {
        return (
          <Flex key={`${file.name}-${localIds[index]}`} alignContent="center" my="5px">
            <IconButton
              size="xxs"
              p="4px"
              isRound={true}
              aria-label={`remove file ${index}`}
              colorScheme="red"
              icon={<CloseIcon fontSize="10px" />}
              variant="solid"
              onClick={() => {
                handleRemoveFile(index);
              }}
            />
            <Text mt="-1px" fontSize="md">
              {file.name}
            </Text>
          </Flex>
        );
      })}
    </>
  );
}
