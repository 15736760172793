import { Image } from "@chakra-ui/react";
import logo from "logo.png";
import "./spinner.css";

export function Spinner() {
  return (
    <Image
      m="2px auto"
      alt="logo loading spinner"
      className="logo-spinner"
      boxSize="40px"
      src={logo}
    />
  );
}
