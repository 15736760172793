/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cloud_logic_custom": [
        {
            "name": "api2b38d508",
            "endpoint": "https://bziextyeif.execute-api.us-east-1.amazonaws.com/acorley",
            "region": "us-east-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://gvsscopugfdxxoo5g7pfyaproy.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-mt64cq6lafg4rmpsk7vsd7yiju",
    "aws_cognito_identity_pool_id": "us-east-1:b78036d9-c69b-4efc-b302-43ef2959696e",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_vaVHxaLab",
    "aws_user_pools_web_client_id": "oslslmhgfn7hsp6ig6s6opvf3",
    "oauth": {
        "domain": "1dax7eozdksv-acorley.auth.us-east-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "http://localhost:3000,http://localhost:3000/",
        "redirectSignOut": "http://localhost:3000",
        "responseType": "token"
    },
    "federationTarget": "COGNITO_USER_AND_IDENTITY_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [
        "GOOGLE"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "brokerbucket111553-acorley",
    "aws_user_files_s3_bucket_region": "us-east-1",
    "aws_dynamodb_all_tables_region": "us-east-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "npiResolutions-acorley",
            "region": "us-east-1"
        },
        {
            "tableName": "predictionMeans",
            "region": "us-east-1"
        },
        {
            "tableName": "scoredCompilations",
            "region": "us-east-1"
        }
    ]
};


export default awsmobile;
