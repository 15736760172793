import { useCallback, useState, useEffect } from "react";
import { Storage } from "aws-amplify";
import { useParams, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { ArrowForwardIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Container,
  Card,
  CardHeader,
  CardBody,
  Divider,
  Flex,
  FormLabel,
  Select,
  Text,
  Textarea,
} from "@chakra-ui/react";

import { CardFooter, Error } from "components";
import { UpdateBrokerInquiryInput, BrokerInquiryProviderInput, RiskColor } from "API";
import { useGraphqlApi } from "hooks";
import { submitInquiry, getBrokerInquiry } from "graphql/queries";
import { updateBrokerInquiry } from "graphql/mutations";
import { SubmissionForm } from "./SubmissionForm";
import { RiskSummary } from "./RiskSummary";
import { DocumentUploader } from "./DocumentUploader";
import { formatProvidersLimits } from "./helpers";
import { getRiskScore } from "services/riskScoreService";
import { RiskBucket } from "./RiskSummary/RiskState";
import { API } from "aws-amplify";
import { Auth } from "aws-amplify";
import { useAnalytics } from "providers";
import { RAT_EVENTS } from "analytics";

async function submitRatInquiry(payload: string) {
  new Promise(async (resolve, reject) => {
    try {
      const response = await API.graphql(
        {
          query: submitInquiry,
          variables: { payload },
          authMode: "AMAZON_COGNITO_USER_POOLS",
        },
        {
          Authorization: (await Auth.currentSession()).getIdToken().getJwtToken(),
        },
      );
      resolve(response);
    } catch (err) {
      console.error(err);
      reject(err);
    }
  });
}

export function SubmitBrokerInquiry() {
  const navigate = useNavigate();
  const { track } = useAnalytics();
  const { submissionId } = useParams();
  const inquiryVariables = {
    id: submissionId,
  };
  const {
    data: inquiry,
    postData,
    loading,
    error,
  } = useGraphqlApi<UpdateBrokerInquiryInput>(getBrokerInquiry, inquiryVariables);
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [isHighRiskBrokerInquiry, setIsHighRiskBrokerInquiry] = useState<boolean>();
  const [overrideHighRiskFormBlocker, setOverrideHighRiskFormBlocker] = useState<boolean>(false);
  const [submissionError, setSubmissionError] = useState<string | undefined>();
  const [riskBucket, setRiskBucket] = useState<RiskBucket | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(true);
  const [hasBeenReset, setHasBeenReset] = useState(false);
  const {
    control,
    handleSubmit,
    formState: { isSubmitting, errors },
    getValues,
    setError,
    clearErrors,
    reset,
    register,
  } = useForm<UpdateBrokerInquiryInput & { documents?: File[] }>();

  const handleUpload = async () => {
    try {
      await Promise.all(
        selectedFiles.map(async (file: File) => {
          await Storage.put(`${inquiry?.id}/${file.name}`, file);
        }),
      );
    } catch (error) {
      console.warn("Error uploading file: ", error);
    } finally {
    }
  };

  useEffect(() => {
    if (selectedFiles.length !== 0) {
      clearErrors("documents");
    }
  }, [selectedFiles, clearErrors]);

  useEffect(() => {
    if (inquiry && loading === false && hasBeenReset !== true) {
      setHasBeenReset(true);
      reset(inquiry);
    }
  }, [loading, inquiry, hasBeenReset, reset]);

  const fetchData = useCallback(async () => {
    if (!inquiry) return;
    const { riskScore } = await getRiskScore({ inquiry });
    const isHighRisk = riskScore === RiskBucket.HIGH; // TODO figure out what this needs to be
    setIsHighRiskBrokerInquiry(isHighRisk);
    setRiskBucket(riskScore as RiskBucket);
    setIsLoading(false);
  }, [inquiry]);

  useEffect(() => {
    fetchData();
  }, [inquiry, fetchData]);

  async function onSubmit() {
    if (selectedFiles.length === 0) {
      setError("documents", {
        type: "manual",
        message: "Please add at least one document",
      });
      return;
    }

    try {
      const updatedProviders = formatProvidersLimits(getValues().providers);

      const newBrokerInquiry = {
        ...inquiry,
        ...getValues(),
        providers: updatedProviders,
      };

      if (riskBucket) {
        const color: RiskColor =
          riskBucket === RiskBucket.HIGH
            ? RiskColor.RED
            : riskBucket === RiskBucket.LOW
            ? RiskColor.GREEN
            : RiskColor.GRAY;
        newBrokerInquiry.riskColor = color;
      }

      await postData(updateBrokerInquiry, { input: { ...newBrokerInquiry } });
      await handleUpload();

      await submitRatInquiry(JSON.stringify(newBrokerInquiry));

      navigate(`/broker/inquiry/${submissionId}/confirm`);
    } catch (e) {
      console.warn("Error submitting", e);
      setSubmissionError("Something went wrong submitting your inquiry.");
    }
  }

  return error ? (
    <Error errorText="Something went wrong, please contact support." />
  ) : (
    <Container mt="100px" maxW="833px">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Card mb="42px" maxWidth="833px" border="1px" borderColor="gray.150">
          <CardHeader p="0" bg="indigo.50" fontSize="sm">
            <Flex px="25px" py="15px" alignItems="center" justifyContent="space-between">
              Please review risk profiles and follow directions below
            </Flex>
          </CardHeader>
          <CardBody pt="0px">
            {submissionError && <Text color="red">{submissionError}</Text>}
            <RiskSummary isLoading={isLoading} riskBucket={riskBucket} />
            {inquiry?.providers?.map((provider: BrokerInquiryProviderInput, index: number) => {
              return (
                (!isHighRiskBrokerInquiry || overrideHighRiskFormBlocker) && (
                  <div key={provider.id}>
                    <SubmissionForm index={index} control={control} />
                    <Divider my="40px" />
                  </div>
                )
              );
            })}
            {isHighRiskBrokerInquiry && !overrideHighRiskFormBlocker && (
              <Flex fontSize="xm" fontWeight="600" direction={["column", "row"]}>
                <Button
                  colorScheme="indigo"
                  onClick={() => {
                    navigate("/");
                  }}
                >
                  Cancel Application
                </Button>
                <Button
                  variant="ghost"
                  colorScheme="red"
                  onClick={() => {
                    setOverrideHighRiskFormBlocker(true);
                  }}
                >
                  Continue With Application Anyway
                </Button>
              </Flex>
            )}
          </CardBody>
          <CardFooter>
            <Box mb={6}>
              <FormLabel htmlFor="policy-type">Policy Type</FormLabel>
              <Select id="policy-type" {...register("policyType")}>
                <option></option>
                <option value="Claims Made">Claims Made</option>
                <option value="Occurrence">Occurrence</option>
              </Select>
            </Box>
            <Flex mb="50px" direction="column">
              <Text fontWeight="500">
                Please upload the declaration page for this submission. If there are any additional
                documents you would like to share with us, you can upload them below, too.
              </Text>
              <Flex mb="15px">
                <Text fontWeight="500">Or email them to:</Text>
                <Box color="indigo" ml="3px" fontWeight="700">
                  <a href="mailto:submissions@getindigo.com"> submissions@getindigo.com</a>
                </Box>
              </Flex>
              <DocumentUploader
                error={errors?.documents && errors?.documents?.message}
                setSelectedFiles={setSelectedFiles}
                selectedFiles={selectedFiles}
              />
              <Box mt={6}>
                <FormLabel htmlFor="notes">Additional Notes</FormLabel>
                <Textarea bg="white" id="notes" {...register("notes")} />
              </Box>
            </Flex>
          </CardFooter>
        </Card>
        <Flex justifyContent="flex-end" mt="30px">
          <Button
            isLoading={isSubmitting}
            type="submit"
            rightIcon={<ArrowForwardIcon />}
            colorScheme="indigo"
            onClick={() => track(RAT_EVENTS.SUBMIT_INQUIRY)}
          >
            Submit Applications
          </Button>
        </Flex>
      </form>
    </Container>
  );
}
