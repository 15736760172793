/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const createBrokerInquiry = /* GraphQL */ `mutation CreateBrokerInquiry(
  $input: CreateBrokerInquiryInput!
  $condition: ModelBrokerInquiryConditionInput
) {
  createBrokerInquiry(input: $input, condition: $condition) {
    id
    providers {
      id
      address {
        street
        secondary
        city
        state
        postalCode
        countyName
        countyFips
        isSecondary
        __typename
      }
      firstName
      specialty
      npi
      retroDate
      lastName
      limit
      limitAggregate
      limitOccurrence
      limitType
      cyberOptOut
      __typename
    }
    hasMultipleProviders
    riskColor
    modelId
    author {
      email
      __typename
    }
    notes
    policyType
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateBrokerInquiryMutationVariables,
  APITypes.CreateBrokerInquiryMutation
>;
export const updateBrokerInquiry = /* GraphQL */ `mutation UpdateBrokerInquiry(
  $input: UpdateBrokerInquiryInput!
  $condition: ModelBrokerInquiryConditionInput
) {
  updateBrokerInquiry(input: $input, condition: $condition) {
    id
    providers {
      id
      address {
        street
        secondary
        city
        state
        postalCode
        countyName
        countyFips
        isSecondary
        __typename
      }
      firstName
      specialty
      npi
      retroDate
      lastName
      limit
      limitAggregate
      limitOccurrence
      limitType
      cyberOptOut
      __typename
    }
    hasMultipleProviders
    riskColor
    modelId
    author {
      email
      __typename
    }
    notes
    policyType
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateBrokerInquiryMutationVariables,
  APITypes.UpdateBrokerInquiryMutation
>;
export const deleteBrokerInquiry = /* GraphQL */ `mutation DeleteBrokerInquiry(
  $input: DeleteBrokerInquiryInput!
  $condition: ModelBrokerInquiryConditionInput
) {
  deleteBrokerInquiry(input: $input, condition: $condition) {
    id
    providers {
      id
      address {
        street
        secondary
        city
        state
        postalCode
        countyName
        countyFips
        isSecondary
        __typename
      }
      firstName
      specialty
      npi
      retroDate
      lastName
      limit
      limitAggregate
      limitOccurrence
      limitType
      cyberOptOut
      __typename
    }
    hasMultipleProviders
    riskColor
    modelId
    author {
      email
      __typename
    }
    notes
    policyType
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteBrokerInquiryMutationVariables,
  APITypes.DeleteBrokerInquiryMutation
>;
export const createCarrier = /* GraphQL */ `mutation CreateCarrier(
  $input: CreateCarrierInput!
  $condition: ModelCarrierConditionInput
) {
  createCarrier(input: $input, condition: $condition) {
    id
    name
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCarrierMutationVariables,
  APITypes.CreateCarrierMutation
>;
export const updateCarrier = /* GraphQL */ `mutation UpdateCarrier(
  $input: UpdateCarrierInput!
  $condition: ModelCarrierConditionInput
) {
  updateCarrier(input: $input, condition: $condition) {
    id
    name
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCarrierMutationVariables,
  APITypes.UpdateCarrierMutation
>;
export const deleteCarrier = /* GraphQL */ `mutation DeleteCarrier(
  $input: DeleteCarrierInput!
  $condition: ModelCarrierConditionInput
) {
  deleteCarrier(input: $input, condition: $condition) {
    id
    name
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteCarrierMutationVariables,
  APITypes.DeleteCarrierMutation
>;
