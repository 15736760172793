import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import { useAnalytics } from "providers";
import { Authenticator } from "@aws-amplify/ui-react";
import { AuthEventData, AmplifyUser } from "@aws-amplify/ui";
import { App } from "App";
import { NotFound } from "./not-found";
import { Broker } from "./broker";
import { Unauthorized } from "./unauthorized";
import { ProtectedRoute } from "./protected-route";
import { checkIfEmailAddressIsAllowed } from "utils";
const internalDomains = process.env.REACT_APP_INTERNAL_DOMAINS;
const externalDomains = process.env.REACT_APP_EXTERNAL_DOMAINS;
const internalEmailDomainAllowList =
  typeof internalDomains === "string" ? internalDomains.split(" ") : [];
const externalEmailDomainAllowList =
  typeof externalDomains === "string" ? externalDomains.split(" ") : [];

type AppProps = {
  signOut?: (data?: AuthEventData | undefined) => void;
  user?: AmplifyUser | undefined;
};

export function Root() {
  const analytics = useAnalytics();
  const searchParams = new URLSearchParams(window.location.search).toString();
  const services = {
    async validateCustomSignUp(formData: { email?: string }) {
      const { email } = formData;
      if (
        email !== "" &&
        email !== undefined &&
        !checkIfEmailAddressIsAllowed(email, externalEmailDomainAllowList)
      ) {
        return {
          email: "Invalid email address",
        };
      }
    },
  };

  return (
    <Router>
      <Authenticator
        services={services}
        components={{
          SignUp: {
            FormFields() {
              return (
                <>
                  <Authenticator.SignUp.FormFields />
                </>
              );
            },
          },
        }}
      >
        {(props: AppProps) => {
          let prefix = "insight-tool";
          const emailAddress = props.user?.attributes?.email;
          const isInternal = checkIfEmailAddressIsAllowed(
            emailAddress,
            internalEmailDomainAllowList,
          );
          const isAllowedExternal = checkIfEmailAddressIsAllowed(
            emailAddress,
            externalEmailDomainAllowList,
          );
          if (!isInternal && isAllowedExternal) {
            prefix = "broker";
          } else if (!isInternal && !isAllowedExternal) {
            prefix = "unauthorized";
          }

          if (props.user?.username) {
            analytics.identify(props.user.username, {
              name: props.user.username,
              email: props.user.attributes?.email,
            });
          }
          const destination = `/${prefix}${searchParams ? `?${searchParams}` : ""}`;

          return (
            <Routes>
              <Route element={<ProtectedRoute />}>
                <Route path="/broker/*" element={<Broker />} />
              </Route>
              <Route element={<ProtectedRoute adminOnly={true} />}>
                <Route path="/insight-tool" element={<App />} />
              </Route>

              <Route path="/" element={<Navigate to={destination} />} />
              <Route path="/unauthorized/*" element={<Unauthorized />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          );
        }}
      </Authenticator>
    </Router>
  );
}
