import { customAlphabet, urlAlphabet } from "nanoid";
import { AddressInput } from "API";

export function generateId() {
  const gen = customAlphabet(urlAlphabet, 7);
  return gen();
}

export function stringifyAddress(address?: AddressInput | null): string {
  if (address) {
    return `${[address.street, address.secondary].join(" ").trim()}, ${address.city}, ${
      address.state
    }, ${address.postalCode}`;
  }
  return "";
}

export function checkIfEmailAddressIsAllowed(
  emailAddress: string | undefined,
  allowList: string[],
): boolean {
  const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  if (emailAddress === undefined) return false;
  if (!regex.test(emailAddress)) return false;
  const domain = emailAddress.split("@")[1];
  return allowList.includes(domain);
}
