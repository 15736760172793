import {
  Alert,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Card,
  CardHeader,
  Flex,
  Heading,
  Input,
  Stat,
  StatArrow,
  StatLabel,
  StatNumber,
  StatHelpText,
  Thead,
  Tooltip,
  useClipboard,
} from "@chakra-ui/react";
import { CopyIcon, QuestionOutlineIcon, ExternalLinkIcon } from "@chakra-ui/icons";
import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { Link, Grid, GridItem, Text, Table, Tbody, Tr, Td, Th } from "@chakra-ui/react";
import { BreadcrumbNav, VerticalSection } from "components";
import { getModelRating } from "services/modelRatingService";
import { getRiskScore } from "services/riskScoreService";
import { submitToSunlight } from "services/submitToSunlightService";
import { Spinner } from "components";
import { useAnalytics } from "providers";
import {
  QUOTE_RESPONSE,
  QUOTE_ERROR,
  SUBMIT_QUOTE,
  PAGES,
  BACK_BUTTON_CLICKED,
  UPDATE_SCHEDULE_RATING_FACTOR,
} from "analytics";

const debug = true;

function getPolicyTotal(coverages) {
  const total = coverages.reduce((sum, coverage) => sum + coverage.Premium?.Amount, 0);
  return formatCurrency(total);
}

function formatCurrency(amount) {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });
  return formatter.format(amount);
}

function formatEpoch(epoch) {
  epoch = typeof epoch === "string" ? parseInt(epoch) : epoch;
  const date = new Date(epoch);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const formattedDate = `${month}/${day}/${year}`;
  return formattedDate;
}

function calcPayerTotalPayment(resolveResults) {
  if (!resolveResults) return 0.0;
  const amount2020raw = resolveResults.NON_CMS_PAYOR_TOTAL_PAYMENT_1Y_2020 || "0";
  const amount2021raw = resolveResults.NON_CMS_PAYOR_TOTAL_PAYMENT_1Y_2021 || "0";
  const amount2022raw = resolveResults.NON_CMS_PAYOR_TOTAL_PAYMENT_1Y_2022 || "0";
  const amountAverage =
    (parseFloat(amount2020raw) + parseFloat(amount2021raw) + parseFloat(amount2022raw)) / 3.0;
  return amountAverage.toFixed(2);
}

function brokerProducerLabel(quoteProperties) {
  if (!quoteProperties) return "";
  return `${quoteProperties.brokerName} / ${quoteProperties.agencyName}`;
}

function checkMissingData(modelRating) {
  let retval = modelRating;
  if (modelRating === -1) {
    retval = "No Data Available for Specialty in County";
  }
  return retval;
}

function getPrimaryTaxonomy(taxonomies) {
  return taxonomies.filter((obj) => {
    return obj.primary;
  });
}

function Item({
  providerInfo,
  resolveResults,
  isLoading,
  index,
  onIndividualProviderChange,
  quoteProperties,
}) {
  const analytics = useAnalytics();
  const handleScheduledRatingFactorChange = (event) => {
    const originalFactor = `${
      checkMissingData(providerInfo.scheduledRatingFactor) ||
      checkMissingData(resolveResults.MODEL_RISK_SCORE)
    }`;
    analytics.track(UPDATE_SCHEDULE_RATING_FACTOR, {
      originalFactor,
      newFactor: event.target.value,
    });
    return onIndividualProviderChange(index, "scheduledRatingFactor", event.target.value);
  };
  console.log("res", resolveResults);
  return (
    <Box borderWidth="1px" borderRadius="md" p={2} mb={4}>
      {isLoading ? <Spinner /> : ""}
      {!isLoading && !resolveResults ? (
        <Alert status="error">
          <AlertIcon />
          <AlertTitle>No data for npi {providerInfo.npi}</AlertTitle>
        </Alert>
      ) : !isLoading ? (
        <Grid templateColumns="repeat(6, 4fr)" gridColumnGap={15}>
          {/* Provider Information Box */}
          <GridItem colSpan={3}>
            <Box className="model-box">
              <Heading>Individual Provider Information</Heading>
              <Box>
                <Text className="name">NPI: </Text>
                <Text className="value">{providerInfo.npi}</Text>
              </Box>
              <Box>
                <Text className="name">Name: </Text>
                <Text className="value">
                  {providerInfo.firstName} {providerInfo.lastName}
                </Text>
              </Box>
              <Box>
                <Text className="name">Specialty: </Text>
                <Text className="value">{providerInfo.indigoSpecialty}</Text>
              </Box>
              <Box>
                <Text className="name">City: </Text>
                <Text className="value">{providerInfo.addressObj.city}</Text>
              </Box>
              <Box>
                <Text className="name">State: </Text>
                <Text className="value">{providerInfo.addressObj.state}</Text>
              </Box>
              <Box>
                <Text className="name">County: </Text>
                <Text className="value">{providerInfo.addressObj?.countyName}</Text>
              </Box>
              <Box>
                <Text className="name">County FIPS: </Text>
                <Text className="value">{providerInfo.addressObj?.countyFips}</Text>
              </Box>
              <Box>
                <Text className="name">Broker / Producer: </Text>
                <Text className="value">{brokerProducerLabel(quoteProperties)}</Text>
              </Box>
              <Box>
                <Text className="name">AMA Membership: </Text>
                <Text className="value">{"Coming Soon"}</Text>
              </Box>
              <Box>
                <Text className="name">Age: </Text>
                <Text className="value">{resolveResults.AGE_LAST_REPORTED_REPORT}</Text>
              </Box>
              <Box>
                <Text className="name">Gender: </Text>
                <Text className="value">{resolveResults.GENDER_REPORT}</Text>
              </Box>
              <Box>
                <Text className="name">Expiring Premium: </Text>
                <Text className="value">{formatCurrency(providerInfo.expiringPremium)}</Text>
              </Box>
              <Box>
                <Text className="name">Target Premium: </Text>
                <Text className="value">{formatCurrency(providerInfo.targetPremium)}</Text>
              </Box>
            </Box>
            <Box className="model-box">
              <Heading>Indigo Model Data: </Heading>
              <Box>
                <Text className="name">Model Risk Score: </Text>
                <Text className="value">{checkMissingData(resolveResults.MODEL_RISK_SCORE)}</Text>
                <Tooltip
                  label={
                    <Box>
                      <Box>
                        <Text className="name">Risk based on Broker Info</Text>
                        <hr />
                      </Box>
                      <Box>
                        <Text className="name">Prediction: </Text>
                        <Text className="value">{resolveResults.PREDICTION_2023}</Text>
                      </Box>
                      <Box>
                        <Text className="name">Cohort Mean: </Text>
                        <Text className="value">{resolveResults.MEAN_PREDICTION_2023}</Text>
                      </Box>
                      <Box>
                        <Text className="name">County FIPS Code</Text>
                        <Text className="value">{providerInfo.addressObj?.countyFips}</Text>
                      </Box>
                      <Box>
                        <Text className="name">Specialty: </Text>
                        <Text className="value">{providerInfo.indigoSpecialty}</Text>
                      </Box>
                    </Box>
                  }
                >
                  <QuestionOutlineIcon ml={2} />
                </Tooltip>
              </Box>
              <Box>
                <Text className="name">Prior Legal Claims: </Text>
                <Text className="value">
                  {resolveResults.COUNT_OF_PRIOR_LEGAL_CLAIMS_REPORT || "0"}
                </Text>
              </Box>
              <Box>
                <Text className="name">Dates of Prior Legal Claims: </Text>
                <Text className="value">
                  {resolveResults.PRIOR_LEGAL_CLAIM_REPORTED_DATES_REPORT || "None"}
                </Text>
              </Box>
              <Box>
                <Text className="name">Board Actions: </Text>
                <Text className="value">{"Coming Soon"}</Text>
              </Box>
              <Box>
                <Text className="name">Payer Total Payment: </Text>
                <Text className="value">
                  {formatCurrency(`${calcPayerTotalPayment(resolveResults)}`)}
                </Text>
              </Box>
            </Box>
            <Box className="model-box">
              <Heading>NPPES Information</Heading>
              <Box>
                <Text className="name">Original Risk Score: </Text>
                <Text className="value">{resolveResults.ORIGINAL_RISK_SCORE}</Text>
                <Tooltip
                  label={
                    <Box>
                      <Box>
                        <Text className="name">Risk based on NPPES Info</Text>
                        <hr />
                      </Box>
                      <Box>
                        <Text className="name">Prediction: </Text>
                        <Text className="value">{resolveResults.PREDICTION_2023}</Text>
                      </Box>
                      <Box>
                        <Text className="name">Cohort Mean: </Text>
                        <Text className="value">
                          {
                            resolveResults.MEAN_PREDICTION2023_OF_PTP_GT100K_IN_INDIGO_SPECIALTY_IN_RATE_TERRITORY
                          }
                        </Text>
                      </Box>
                      <Box>
                        <Text className="name">County FIPS Code: </Text>
                        <Text className="value">{resolveResults.FIPS_COUNTY_CODE_2023}</Text>
                      </Box>
                      <Box>
                        <Text className="name">Specialty: </Text>
                        <Text className="value">{resolveResults.INDIGO_SPECIALTY_2023}</Text>
                      </Box>
                    </Box>
                  }
                >
                  <QuestionOutlineIcon ml={2} />
                </Tooltip>
              </Box>
              <Box>
                <Text className="name">NPPES Specialty: </Text>
                <Text className="value">
                  {getPrimaryTaxonomy(providerInfo.nppes_result?.taxonomies)[0]?.desc}
                </Text>
              </Box>
              <Box>
                <Text className="name">NPPES County: </Text>
                <Text className="value">{resolveResults.FIPS_COUNTY_NAME_2023} County</Text>
              </Box>
              <Box>
                <Text className="name">NPPES FIPS: </Text>
                <Text className="value">{resolveResults.FIPS_COUNTY_NAME_2023} County</Text>
              </Box>
              <Box>
                <Text className="name">NPPES License: </Text>
                <Text className="value">
                  {getPrimaryTaxonomy(providerInfo.nppes_result?.taxonomies)[0]?.license}
                </Text>
              </Box>
              <Box>
                <Text className="name">Date NPPES Registry Last Updated: </Text>
                <Text className="value">
                  {formatEpoch(providerInfo.nppes_result.last_updated_epoch)}
                </Text>
              </Box>
              {/* <Text><Text className="name">Affiliated Groups</Text><Text className="value">{resolveResults.CURRENT_AFFILIATED_GROUPS_REPORT}</Text></Text> */}
              {/* <Text><Text className="name">Specialty</Text><Text className="value">{providerInfo.nppes_result?.taxonomies.map(x => x.desc).join(', ')}</Text></Text> */}
              {/* <Text><Text className="name">City, State</Text><Text className="value">{new Set(providerInfo.nppes_result?.addresses.map(x=> x.city + ", " + x.state))}</Text></Text> */}
            </Box>
          </GridItem>
          {/* Indigo Underwriting Info Box */}
          <GridItem colSpan={3}>
            <Box mb={4}>
              <Table size="md">
                <Thead>
                  <Tr>
                    <Th></Th>
                    <Th fontSize="md">Good Factor Categories</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  <Tr key="negative_1">
                    <Td>1</Td>
                    <Td>
                      <Flex direction="column">
                        <Stat>
                          <StatLabel>Category:</StatLabel>
                          <StatNumber>{resolveResults.NEGATIVE_FACTOR__1_CATEGORY}</StatNumber>
                          <StatHelpText>
                            <StatArrow type="decrease" color="green.400" />
                            {resolveResults.NEGATIVE_FACTOR__1_SCORE}
                          </StatHelpText>
                        </Stat>
                        <Stat>
                          <StatLabel>Factor:</StatLabel>
                          <StatNumber>{resolveResults.NEGATIVE_FACTOR__1}</StatNumber>
                          <StatHelpText>
                            Value: {resolveResults.NEGATIVE_FACTOR__1_VALUE}
                          </StatHelpText>
                        </Stat>
                      </Flex>
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>2</Td>
                    <Td>
                      <Flex direction="column">
                        <Stat>
                          <StatLabel>Category:</StatLabel>
                          <StatNumber>{resolveResults.NEGATIVE_FACTOR__2_CATEGORY}</StatNumber>
                          <StatHelpText>
                            <StatArrow type="decrease" color="green.400" />
                            {resolveResults.NEGATIVE_FACTOR__2_SCORE}
                          </StatHelpText>
                        </Stat>
                        <Stat>
                          <StatLabel>Factor:</StatLabel>
                          <StatNumber>{resolveResults.NEGATIVE_FACTOR__2}</StatNumber>
                          <StatHelpText>
                            Value: {resolveResults.NEGATIVE_FACTOR__2_VALUE}
                          </StatHelpText>
                        </Stat>
                      </Flex>
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>3</Td>
                    <Td>
                      <Flex direction="column">
                        <Stat>
                          <StatLabel>Category:</StatLabel>
                          <StatNumber>{resolveResults.NEGATIVE_FACTOR__3_CATEGORY}</StatNumber>
                          <StatHelpText>
                            <StatArrow type="decrease" color="green.400" />
                            {resolveResults.NEGATIVE_FACTOR__3_SCORE}
                          </StatHelpText>
                        </Stat>
                        <Stat>
                          <StatLabel>Factor:</StatLabel>
                          <StatNumber>{resolveResults.NEGATIVE_FACTOR__3}</StatNumber>
                          <StatHelpText>
                            Value: {resolveResults.NEGATIVE_FACTOR__3_VALUE}
                          </StatHelpText>
                        </Stat>
                      </Flex>
                    </Td>
                  </Tr>
                </Tbody>
              </Table>
            </Box>
            <Box>
              <Table size="md">
                <Thead>
                  <Tr>
                    <Th></Th>
                    <Th fontSize="md">Bad Factor Categories</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  <Tr>
                    <Td>1</Td>
                    <Td>
                      <Flex direction="column">
                        <Stat>
                          <StatLabel>Category:</StatLabel>
                          <StatNumber>{resolveResults.POSITIVE_FACTOR_1_CATEGORY}</StatNumber>
                          <StatHelpText>
                            <StatArrow type="increase" color="red.400" />
                            {resolveResults.POSITIVE_FACTOR_1_SCORE}
                          </StatHelpText>
                        </Stat>
                        <Stat>
                          <StatLabel>Factor:</StatLabel>
                          <StatNumber>{resolveResults.POSITIVE_FACTOR_1}</StatNumber>
                          <StatHelpText>
                            Value: {resolveResults.POSITIVE_FACTOR_1_VALUE}
                          </StatHelpText>
                        </Stat>
                      </Flex>
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>2</Td>
                    <Td>
                      <Flex direction="column">
                        <Stat>
                          <StatLabel>Category:</StatLabel>
                          <StatNumber>{resolveResults.POSITIVE_FACTOR_2_CATEGORY}</StatNumber>
                          <StatHelpText>
                            <StatArrow type="increase" color="red.400" />
                            {resolveResults.POSITIVE_FACTOR_2_SCORE}
                          </StatHelpText>
                        </Stat>
                        <Stat>
                          <StatLabel>Factor:</StatLabel>
                          <StatNumber>{resolveResults.POSITIVE_FACTOR_2}</StatNumber>
                          <StatHelpText>
                            Value: {resolveResults.POSITIVE_FACTOR_2_VALUE}
                          </StatHelpText>
                        </Stat>
                      </Flex>
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>3</Td>
                    <Td>
                      <Flex direction="column">
                        <Stat>
                          <StatLabel>Category:</StatLabel>
                          <StatNumber>{resolveResults.POSITIVE_FACTOR_3_CATEGORY}</StatNumber>
                          <StatHelpText>
                            <StatArrow type="increase" color="red.400" />
                            {resolveResults.POSITIVE_FACTOR_3_SCORE}
                          </StatHelpText>
                        </Stat>
                        <Stat>
                          <StatLabel>Factor:</StatLabel>
                          <StatNumber>{resolveResults.POSITIVE_FACTOR_3}</StatNumber>
                          <StatHelpText>
                            Value: {resolveResults.POSITIVE_FACTOR_3_VALUE}
                          </StatHelpText>
                        </Stat>
                      </Flex>
                    </Td>
                  </Tr>
                </Tbody>
              </Table>
            </Box>
            <Box mt="32px">
              <Text>
                <b>Scheduled Rating Factor</b>
              </Text>
              <Input
                type="text"
                value={
                  checkMissingData(providerInfo.scheduledRatingFactor) ||
                  checkMissingData(resolveResults.MODEL_RISK_SCORE)
                }
                onChange={handleScheduledRatingFactorChange}
              ></Input>
            </Box>
          </GridItem>
        </Grid>
      ) : (
        <></>
      )}
    </Box>
  );
}

function calcAverageRateFromResolveResults(resolveResults) {
  let accumulatedRate = 0;
  let numRates = 0;
  for (let i = 0; i < resolveResults.length; i++) {
    numRates += !resolveResults[i] ? 0 : 1;
    accumulatedRate += !resolveResults[i] ? 0 : parseFloat(resolveResults[i].MODEL_RISK_SCORE);
  }
  return accumulatedRate / numRates;
}

export default function ModelInsightPage({
  individualProviders,
  onNavigate,
  slots,
  midLevels,
  healthcareEntities,
  quoteProperties,
  onIndividualProviderChange,
}) {
  const analytics = useAnalytics();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [resolvedRatings, setResolvedRatings] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [submitResults, setSubmitResults] = useState([]);
  const [submissionError, setSubmissionError] = useState();
  const [modelInsightsError, setModelInsightsError] = useState();
  const [metadata, setMetadata] = useState({});
  const [riskScore, setRiskScore] = useState(null);
  let [searchParams] = useSearchParams();
  const { onCopy, hasCopied } = useClipboard(
    JSON.stringify(
      JSON.stringify({
        individualProviders,
        slots,
        midLevels,
        healthcareEntities,
        resolvedRatings,
        quoteProperties,
      }),
    ),
  );

  const showCopyPayload = process.env.NODE_ENV === "development" && searchParams.get("devtools");

  useEffect(() => {
    if (!isLoaded) {
      async function fetchData() {
        setIsLoading(true);
        try {
          const results = await Promise.all(
            individualProviders.map(async (ip) => {
              const r = await getModelRating({
                npi: ip.npi,
                countyFIPS: ip.addressObj.countyFips,
                specialty: ip.indigoSpecialty,
              });
              return r;
            }),
          );
          const resolveResult = results.map((result) => result["results"][0]);
          setResolvedRatings(resolveResult);
          setMetadata(results[0]["metadata"]);
          quoteProperties.modelInfo = results[0].metadata;

          // getRiskScore expects individual providers that are in the RAT format rather than CIT format
          const { riskScore } = await getRiskScore({
            inquiry: {
              providers: individualProviders.map((p) => {
                const pCopy = { ...p };
                pCopy.address = p.addressObj;
                pCopy.specialty = p.indigoSpecialtyId;
                return pCopy;
              }),
            },
          });
          setRiskScore(riskScore);

          // if we don't have a model rating for an npi, resolveResults will include an undefined entry for that npi
          if (
            resolveResult &&
            !resolveResult.includes(null) &&
            !resolveResult.includes(undefined)
          ) {
            resolveResult.map((result, index) =>
              onIndividualProviderChange(index, "scheduledRatingFactor", result.MODEL_RISK_SCORE),
            );
            setAverageRate(calcAverageRateFromResolveResults(resolveResult));
          } else {
            setAverageRate("Missing NPIs");
          }
          setIsLoading(false);
          setIsLoaded(true);
        } catch (e) {
          setModelInsightsError(`Something went wrong fetching model insight data: ${e.message}`);
          setIsLoading(false);
          setIsLoaded(true);
        }
      }
      fetchData();
    }
  }, [individualProviders, isLoaded, onIndividualProviderChange, quoteProperties]);

  const onSubmit = async () => {
    if (process.env.REACT_APP_SUPPRESS_QUOTE === "true") {
      alert(
        "Quote submission is suppressed, remove REACT_APP_SUPPRESS_QUOTE flag to enable quoting.",
      );
      return;
    }

    if (!isSubmitted) {
      setIsSubmitting(true);
      setSubmissionError("");
      try {
        const payload = {
          individualProviders,
          slots,
          midLevels,
          healthcareEntities,
          resolvedRatings,
          quoteProperties,
        };
        analytics.track(SUBMIT_QUOTE);
        const results = await submitToSunlight(payload);
        if ("PolicyJSON" in results.quoteResults[0]) {
          const quoteNumber = results.quoteResults[0].PolicyJSON.FullNumber;
          analytics.track(QUOTE_RESPONSE, { quoteNumber });
        }
        setSubmitResults(results);
        setIsSubmitting(false);
        setIsSubmitted(true);
      } catch (error) {
        console.error(error.message);
        analytics.track(QUOTE_ERROR, { error: error.message });
        setSubmissionError(`Something went wrong submitting to Sunlight: ${error.message}`);
        setIsLoading(false);
        setIsSubmitting(false);
        setIsSubmitted(false);
      }
    }
  };
  const [averageRate, setAverageRate] = useState(-1);

  return (
    <VerticalSection>
      <Box p={4}>
        <Heading as="h2" mb={4}>
          Model Insights
        </Heading>
        <BreadcrumbNav currentPage="model_insight" onNavigate={onNavigate} />

        {modelInsightsError && <Text color="red">{modelInsightsError}</Text>}

        <Flex w="100%">
          <Box className="model-box" w="50%">
            <Heading>Average Rate</Heading>
            {averageRate !== -1 && modelInsightsError === undefined && <Text>{averageRate}</Text>}
          </Box>
          <Box className="model-box" w="50%">
            <Heading>Risk Score</Heading>
            <Text>{riskScore}</Text>
          </Box>
        </Flex>

        {!isLoading &&
          healthcareEntities.map((entity, index) => (
            <Box mb={4} key={index}>
              <Heading mb={2} fontWeight="bold" textAlign="left">
                Medical Practice
              </Heading>
              <Box>
                <Text className="name">Current Premium: </Text>
                <Text className="value">{formatCurrency(entity.currentPremium)}</Text>
              </Box>
              <Box>
                <Text className="name">Target Premium: </Text>
                <Text className="value">{formatCurrency(entity.targetPremium)}</Text>
              </Box>
            </Box>
          ))}

        <Heading fontWeight="bold" textAlign="left">
          Individual Providers
        </Heading>

        <Box p={4}>
          {individualProviders.map((provider, index) => (
            <Item
              key={index}
              providerInfo={provider}
              resolveResults={resolvedRatings?.[index]}
              isLoading={isLoading}
              onIndividualProviderChange={onIndividualProviderChange}
              index={index}
              quoteProperties={quoteProperties}
            />
          ))}
        </Box>
        {!isLoading && individualProviders.length === 0 ? <Text>no data available</Text> : <></>}
        <Box>
          <Text color="red">{submissionError}</Text>
        </Box>
        <Button
          variant="outline"
          id="back-button"
          onClick={() => {
            analytics.track(BACK_BUTTON_CLICKED, {
              page: PAGES.MODEL_INSIGHT,
            });
            onNavigate("resolve");
          }}
        >
          Back
        </Button>
        <Button
          id="submit-button"
          onClick={onSubmit}
          isLoading={isSubmitting}
          loadingText="Submitting"
          disabled={isSubmitted}
          variant="solid"
          colorScheme="indigo"
        >
          {isSubmitted ? "Submitted" : "Submit"}
        </Button>
        {showCopyPayload && (
          <Card variant="filled" alignItems="flex-start" m="10px" colorScheme="red">
            <CardHeader>
              <Heading size="sm" color="red">
                Devtools
              </Heading>
            </CardHeader>
            <Button onClick={onCopy} rightIcon={<CopyIcon />} variant="ghost">
              {hasCopied ? "Copied!" : "Copy submission"}
            </Button>
          </Card>
        )}

        <Box className="modal-box" mt="4">
          {debug && submitResults.entityResults
            ? submitResults.entityResults.map((result, index) => (
                <Box className="modal-box" mt="4" key={index}>
                  <Heading as="h3" size="lg">
                    Simplify Results: Medical Practice #{index + 1}
                  </Heading>
                  <Box>
                    <Text className="name">Entity Name: </Text>
                    <Text className="value">{result.Customer.CustomerName}</Text>
                  </Box>
                  <Box>
                    <Text className="name">Simplify Customer ID: </Text>
                    <Link
                      color="blue"
                      href={`${process.env.REACT_APP_ENV_SUNLIGHT_HOST}/customers/${result.Customer.CustomerNumber}`}
                      isExternal
                    >
                      {result.Customer.CustomerNumber} <ExternalLinkIcon mx="2px" />
                    </Link>
                  </Box>
                </Box>
              ))
            : ""}

          {debug && submitResults.customerResults
            ? submitResults.customerResults.map((result, index) => (
                <Box className="modal-box" mt="4" key={index}>
                  <Heading as="h3" size="lg">
                    Simplify Results: {result.CustomerType} #{index + 1}
                  </Heading>
                  <Box>
                    <Text className="name">{result.CustomerType} Name: </Text>
                    <Text className="value">{result.Result.Customer.CustomerName}</Text>
                  </Box>
                  <Box>
                    <Text className="name">Simplify Customer ID: </Text>
                    <Link
                      color="blue"
                      href={`${process.env.REACT_APP_ENV_SUNLIGHT_HOST}/customers/${result.Result.Customer.CustomerNumber}`}
                      isExternal
                    >
                      {result.Result.Customer.CustomerNumber} <ExternalLinkIcon mx="2px" />
                    </Link>
                  </Box>
                </Box>
              ))
            : ""}

          {debug && submitResults.quoteResults && submitResults.quoteResults[0].PolicyJSON
            ? submitResults.quoteResults.map((policy, index) => (
                <Box className="modal-box" mt="4" key={index}>
                  <Heading as="h3" size="lg">
                    Simplify Quote
                  </Heading>
                  <Box>
                    <Text className="name">Quote Number: </Text>
                    <Link
                      color="blue"
                      href={`${process.env.REACT_APP_ENV_SUNLIGHT_HOST}/policies/${policy.PolicyJSON.FullNumber}`}
                      isExternal
                    >
                      {policy.PolicyJSON.FullNumber} <ExternalLinkIcon mx="2px" />
                    </Link>
                  </Box>
                  <Box>
                    <Text className="name">Policy Holder Number: </Text>
                    <Link
                      color="blue"
                      href={`${process.env.REACT_APP_ENV_SUNLIGHT_HOST}/customers/${policy.PolicyJSON.PolicyHolderNumber}`}
                      isExternal
                    >
                      {policy.PolicyJSON.PolicyHolderNumber} <ExternalLinkIcon mx="2px" />
                    </Link>
                  </Box>
                  <Heading as="h3" size="lg" mt="4">
                    Current Premiums
                  </Heading>
                  {policy.PolicyJSON.ListofCoverages.map((coverage, index) => (
                    <Box key={index}>
                      <Text className="name">{coverage.MyOfferedCoverage?.CoverageName}: </Text>
                      <Text className="value">{formatCurrency(coverage.Premium?.Amount)}</Text>
                    </Box>
                  ))}
                  <hr width="500px"></hr>
                  <Box>
                    <Text className="name">Total Current Premium: </Text>
                    <Text className="value">
                      {getPolicyTotal(policy.PolicyJSON.ListofCoverages)}
                    </Text>
                  </Box>
                </Box>
              ))
            : ""}

          {debug && submitResults.quoteResults && submitResults.quoteResults[0].ListOfErrors
            ? submitResults.quoteResults[0].ListOfErrors.map((error, index) => (
                <Box className="modal-box" mt="4" key={index}>
                  <Heading as="h3" size="lg">
                    Simplify Error
                  </Heading>
                  <Box>
                    <Text className="value">{error}</Text>
                  </Box>
                </Box>
              ))
            : ""}
        </Box>
      </Box>
      <hr />
      <Box>
        Scored Compilations Info: Generated: <i>{metadata?.generationDate}</i>, ModelId:{" "}
        <i>{metadata?.modelId}</i>
        <Tooltip
          label={
            <Box>
              <Box>
                <Text className="name">Current Scored Compiltation Dataset Info</Text>
                <hr />
              </Box>
              <Box>
                <Text className="name">ModelId: </Text>
                <Text className="value">{metadata?.modelId} </Text>
              </Box>
              <Box>
                <Text className="name">Generated: </Text>
                <Text className="value">{metadata?.generationDate}</Text>
              </Box>
              <Box>
                <Text className="name">Source File: </Text>
                <Text className="value">{metadata?.sourceFile}</Text>
              </Box>
              <Box>
                <Text className="name">Description: </Text>
                <Text className="value">{metadata?.description}</Text>
              </Box>
            </Box>
          }
        >
          <QuestionOutlineIcon ml={2} />
        </Tooltip>
      </Box>
    </VerticalSection>
  );
}
