import { Autocomplete } from "autocomplete";
import { AutocompleteProps } from "autocomplete/types";
import { details } from "services/smartyService";
import { Address } from "models";

type Suggestion = {
  labelText: string;
  value: {
    street_line: string;
    secondary: string;
    city: string;
    state: string;
    details?: any;
  };
};

interface AutocompleteInputProps {
  value: string;
  onChange: (value: Address, label: string) => void;
  error?: boolean;
}

const AutocompleteTyped: React.FC<AutocompleteProps> = Autocomplete as any;

export const AutocompleteInput = ({ value, onChange, error }: AutocompleteInputProps) => {
  const handleSelect = async (suggestion: Suggestion) => {
    const results = await details(
      suggestion.value.street_line,
      suggestion.value.city,
      suggestion.value.state,
    );
    suggestion.value = { ...suggestion.value, ...results };
    const address: Address = {
      street: suggestion.value.street_line,
      secondary: suggestion.value.secondary,
      city: suggestion.value.city,
      state: suggestion.value.state,
      postalCode: results.postalCode,
      countyName: results.countyName,
      countyFips: results.countyFips,
    };
    onChange(address, suggestion.labelText);
  };

  return (
    <AutocompleteTyped
      error={error}
      apiKey="live_pub_be2c81218c1f30239713d2f526d20db"
      onSelection={handleSelect}
      delaySearch={true}
      delayValue={2000}
      menuPosition={"fixed"}
      defaultInputValue={value}
      inputValue={value}
    />
  );
};
