import {
  resolveNpi,
  NPIRegistryResponse,
  NPIRegistryResponseResult,
} from "services/npiResolutionService";
import { BrokerInquiryProvider } from "API";

import { NpiRegistryFetcher } from "services/npiRegistryService";
import { getSpecialtyDetailsById } from "services/indigoSpecialtyService";

export async function fetchProvidersNppes(
  providers: BrokerInquiryProvider[],
): Promise<{ [key: string]: any[] }> {
  const resultList: NPIRegistryResponseResult[] = [];
  const unresolvedList: { [key: string]: any[] } = {};
  await Promise.all(
    providers?.map(async (p) => {
      const payload = {
        first_name: p.firstName,
        last_name: p.lastName,
        state: p.address?.state || "",
        taxonomySearches: getSpecialtyDetailsById(p.specialty).search,
      };
      const knownNpiResponse: NPIRegistryResponse = await resolveNpi(
        payload,
        new NpiRegistryFetcher(),
        true,
      );
      knownNpiResponse?.forEach((response) => {
        const r = response as NPIRegistryResponseResult;
        resultList.push(r);
        const { address_1, address_2, city, state, postal_code } = r.addresses[0];
        const { basic } = r;
        const unresolved: any = {
          firstName: basic.first_name,
          lastName: basic.last_name,
          credential: basic.credential,
          prefix: basic.prefix,
          npi: response.number,
          specialty: r?.taxonomies?.[0]?.desc,
          address: {
            address1: address_1,
            address2: address_2,
            city,
            state,
            postalCode: postal_code,
          },
        };
        if (unresolvedList[p.id]) {
          unresolvedList[p.id] = [...unresolvedList[p.id], unresolved];
        } else {
          unresolvedList[p.id] = [unresolved];
        }
      });
    }),
  );
  return unresolvedList;
}
