export class LocalStorageService {
  constructor(storageKey) {
    this.storageKey = storageKey;
  }

  saveData(data) {
    const serializedData = JSON.stringify(data);
    localStorage.setItem(this.storageKey, serializedData);
  }

  loadData() {
    const serializedData = localStorage.getItem(this.storageKey);
    if (serializedData) {
      return JSON.parse(serializedData);
    }
    return null;
  }

  clearData() {
    localStorage.removeItem(this.storageKey);
  }
}
