import { API, Auth } from "aws-amplify";
import { getBrokerInquiryRiskColor } from "../graphql/queries";
import { UpdateBrokerInquiryInput } from "API";

type RiskScoreFetcherInput = {
  inquiry: UpdateBrokerInquiryInput;
};

export type RiskScoreResult = {
  riskScore: String;
};

type RiskScoreResolveInput = {
  inquiry: UpdateBrokerInquiryInput;
};

class RiskScoreFetcher {
  async fetch(input: RiskScoreFetcherInput) {
    const response = await API.graphql(
      {
        query: getBrokerInquiryRiskColor,
        variables: {
          paramsJson: JSON.stringify(input.inquiry),
        },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      },
      {
        Authorization: (await Auth.currentSession()).getIdToken().getJwtToken(),
      },
    );

    // TODO - there must be a better way to pass that modelRatingResult down from the lambda
    const lambdaPayload = JSON.parse(JSON.stringify(response)).data.getBrokerInquiryRiskColor;
    if (lambdaPayload) {
      const body = JSON.parse(lambdaPayload);
      return { riskScore: body.riskThreshold };
    } else {
      throw new Error("malformed results, expected payload.");
    }
  }
}

export async function getRiskScore(input: RiskScoreResolveInput): Promise<RiskScoreResult> {
  return new Promise(async (resolve, reject) => {
    const fetcher = new RiskScoreFetcher();
    try {
      resolve(await fetcher.fetch({ inquiry: input.inquiry }));
    } catch (e: any) {
      reject(e);
    }
  });
}
