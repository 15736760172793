import {
  Box,
  Button,
  Checkbox,
  IconButton,
  Input,
  Select,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  Stack,
} from "@chakra-ui/react";
import { DeleteIcon, CopyIcon } from "@chakra-ui/icons";
import { AutocompleteInput, CurrencyInput, DatePicker } from "components";
import { INDIGO_SPECIALTIES } from "services/indigoSpecialtyService";
import { providerPDs } from "services/professionalDesignationService";

const STATE_ABBREVIATIONS = [
  "AL",
  "AK",
  "AZ",
  "AR",
  "CA",
  "CO",
  "CT",
  "DE",
  "FL",
  "GA",
  "HI",
  "ID",
  "IL",
  "IN",
  "IA",
  "KS",
  "KY",
  "LA",
  "ME",
  "MD",
  "MA",
  "MI",
  "MN",
  "MS",
  "MO",
  "MT",
  "NE",
  "NV",
  "NH",
  "NJ",
  "NM",
  "NY",
  "NC",
  "ND",
  "OH",
  "OK",
  "OR",
  "PA",
  "RI",
  "SC",
  "SD",
  "TN",
  "TX",
  "UT",
  "VT",
  "VA",
  "WA",
  "WV",
  "WI",
  "WY",
];

const VerticalSection = ({ children }) => {
  return (
    <Box mb={4} mx="auto">
      <Box overflowX="auto">{children}</Box>
    </Box>
  );
};

const IndividualProviderRow = (provider, index, onChange, onDelete, onCopy) => {
  const handleSuffixChange = (event) => onChange(index, "suffix", event.target.value);
  const handleFirstNameChange = (event) => onChange(index, "firstName", event.target.value);
  const handleLastNameChange = (event) => onChange(index, "lastName", event.target.value);
  const handleSpecialtyChange = (event) => onChange(index, "specialty", event.target.value);
  const handleIndigoSpecialtyChange = (event) => {
    onChange(index, "indigoSpecialtyId", event.target.value);
    onChange(index, "indigoSpecialty", event.target[event.target.selectedIndex].text); // retrieves text value of selected option
  };
  const handleIndigoAddressChange = (address, addressLabel) => {
    onChange(index, "address", addressLabel);
    onChange(index, "addressObj", address);
  };
  const handleRetroDateChange = (date) => {
    onChange(index, "retroDate", date);
  };
  const handleDeductibleChange = (event) => onChange(index, "deductible", event.target.value);
  const handleLimitChange = (event) => {
    const limits = event.target.value.split("/");
    onChange(index, "limit", event.target.value);
    onChange(index, "limitOccurrence", limits[0]);
    onChange(index, "limitAggregate", limits[1]);
  };
  const handleLimitTypeChange = (event) => onChange(index, "limitType", event.target.value);
  const handleExpiringPremiumChange = (value) => onChange(index, "expiringPremium", value);
  const handleTargetPremiumChange = (value) => onChange(index, "targetPremium", value);
  const handleNpiChange = (event) => onChange(index, "npi", event.target.value);
  const handleLicenseNumberChange = (event) => onChange(index, "licenseNumber", event.target.value);
  const handleLicenseStateChange = (event) => onChange(index, "licenseState", event.target.value);
  const handleRetiredVolunteeredChange = (event) => {
    onChange(index, "retiredVolunteered", event.target.checked);
    const dummyEvt = { target: { value: "100000/300000" } };
    handleLimitChange(dummyEvt);
  };
  //const handleVicariousLiabilityChange = (event) => onChange(index, 'vicariousLiability', event.target.checked)
  const handlePartTimePracticeChange = (event) =>
    onChange(index, "partTimePractice", event.target.value);
  const handleTeachingPhysiciansChange = (event) =>
    onChange(index, "teachingPhysicians", event.target.value);
  const handleResidencyAndFellowshipProgramsChange = (event) =>
    onChange(index, "residencyAndFellowshipPrograms", event.target.value);
  const handleNewToPracticeChange = (event) => onChange(index, "newToPractice", event.target.value);
  const handleAmaChange = (event) => onChange(index, "ama", event.target.checked);
  const handleBurnoutChange = (event) => onChange(index, "burnout", event.target.checked);

  const handleDeleteClick = (event) => onDelete(index);
  const handleCopyClick = (event) => onCopy(index);

  return (
    <Tr key={index} fontSize="md" m="0px" p="10px">
      <Td m="0px" p="0">
        <IconButton
          aria-label="Delete Individual Provider"
          icon={<DeleteIcon />}
          onClick={handleDeleteClick}
          colorScheme="red"
        />
      </Td>
      <Td m="0px" p="0">
        <IconButton
          aria-label="Delete Individual Provider"
          icon={<CopyIcon />}
          onClick={handleCopyClick}
          colorScheme="cyan"
        />
      </Td>
      <Td m="0px" p="0 10px">
        <Input size="sm" value={provider.firstName || ""} onChange={handleFirstNameChange} />
      </Td>
      <Td m="0px" p="0 10px">
        <Input
          size="sm"
          isRequired={true}
          type="text"
          value={provider.lastName || ""}
          onChange={handleLastNameChange}
        />
      </Td>
      <Td m="0px" p="0px">
        <Select size="xs" value={provider.suffix || ""} onChange={handleSuffixChange}>
          <option></option>
          {providerPDs.map((providerPD) => (
            <option key={providerPD[0]} value={providerPD[0]}>
              {providerPD[1]}
            </option>
          ))}
        </Select>
      </Td>
      <Td m="0px" p="0 10px">
        <Input
          size="sm"
          isRequired={true}
          type="text"
          value={provider.specialty || ""}
          onChange={handleSpecialtyChange}
        />
      </Td>
      <Td m="0px" p="0 10px">
        <Select
          size="sm"
          value={provider.indigoSpecialtyId || ""}
          onChange={handleIndigoSpecialtyChange}
          required
          m="0px"
          p="0px"
        >
          <option></option>
          {INDIGO_SPECIALTIES.map((spec) => (
            <option key={spec.value} value={spec.value}>
              {spec.name}
            </option>
          ))}
        </Select>
      </Td>
      <Td m="0px" p="0 10px">
        <AutocompleteInput value={provider.address || ""} onChange={handleIndigoAddressChange} />
      </Td>
      <Td m="0px" p="0 10px">
        <Select
          size="sm"
          value={provider.limit || "1000000/3000000"}
          onChange={handleLimitChange}
          required
          m="0px"
        >
          <option></option>
          <option value="100000/300000">0.1M/0.3M</option>
          <option value="200000/600000">0.2M/0.6M</option>
          <option value="250000/750000">0.25M/0.75M</option>
          <option value="500000/1500000">0.5M/1.5M</option>
          <option value="1000000/3000000">1M/3M</option>
        </Select>
      </Td>
      <Td p="0 10px" mx="5px">
        <Select
          size="sm"
          value={provider.limitType || "3101"}
          onChange={handleLimitTypeChange}
          required
          m="0px"
        >
          <option value="3102">Shared</option>
          <option value="3101">Separate</option>
        </Select>
      </Td>
      <Td m="0px" p="0 10px">
        <DatePicker selected={provider.retroDate} onChange={handleRetroDateChange} />
      </Td>
      <Td m="0px" p="0 10px">
        <Input type="text" value={provider.npi || ""} onChange={handleNpiChange} m="0px" />
      </Td>
      <Td m="0px" p="0 10px">
        <Select
          size="sm"
          value={provider.deductible || "None"}
          onChange={handleDeductibleChange}
          required
          m="0px"
        >
          <option value="">None</option>
          <option value="3101">$10k</option>
          <option value="3102">$25k</option>
          <option value="3103">$50k</option>
        </Select>
      </Td>
      <Td m="0px" p="0px">
        <CurrencyInput value={provider.expiringPremium} onChange={handleExpiringPremiumChange} />
      </Td>
      <Td m="0px" p="0px">
        <CurrencyInput value={provider.targetPremium} onChange={handleTargetPremiumChange} />
      </Td>
      <Td m="0px" p="0px">
        <Input
          type="text"
          value={provider.licenseNumber || ""}
          onChange={handleLicenseNumberChange}
          m="0px"
        />
      </Td>
      <Td m="0px" p="0px">
        <Select
          size="sm"
          value={provider.licenseState || "CA"}
          onChange={handleLicenseStateChange}
          required
          m="0px"
        >
          {STATE_ABBREVIATIONS.map((state) => (
            <option key={state} value={state}>
              {state}
            </option>
          ))}
        </Select>
      </Td>
      <Td m="0px" p="0px">
        <Checkbox
          isChecked={provider.retiredVolunteered}
          onChange={handleRetiredVolunteeredChange}
          borderColor="black"
          colorScheme="red"
        ></Checkbox>
      </Td>
      {/* <Td m="0px" p="0px">
          <Checkbox isChecked={provider.vicariousLiability} onChange={handleVicariousLiabilityChange} borderColor="black" colorScheme='red'/>
        </Td> */}
      <Td m="0px" p="0px">
        <Select
          size="sm"
          value={provider.partTimePractice || "3103"}
          onChange={handlePartTimePracticeChange}
          required
          m="0px"
        >
          <option value="3101">Less than 50 hours</option>
          <option value="3102">51 hours to 80 hours</option>
          <option value="3103">More than 80 hours</option>
        </Select>
      </Td>

      <Td m="0px" p="0px">
        <Select
          size="sm"
          value={provider.teachingPhysicians || 0}
          onChange={handleTeachingPhysiciansChange}
        >
          <option value="0">0%</option>
          <option value="10">10%</option>
          <option value="20">20%</option>
          <option value="30">30%</option>
          <option value="40">40%</option>
          <option value="50">50%</option>
        </Select>
      </Td>
      <Td m="0px" p="0px">
        <Select
          size="sm"
          value={provider.residencyAndFellowshipPrograms || 0}
          onChange={handleResidencyAndFellowshipProgramsChange}
        >
          <option value="0">0%</option>
          <option value="10">10%</option>
          <option value="20">20%</option>
          <option value="30">30%</option>
          <option value="40">40%</option>
          <option value="50">50%</option>
          <option value="60">60%</option>
          <option value="70">70%</option>
          <option value="80">80%</option>
          <option value="90">90%</option>
        </Select>
      </Td>

      <Td m="0px" p="0px">
        <Select
          size="sm"
          value={provider.newToPractice || "3"}
          onChange={handleNewToPracticeChange}
          required
          m="0px"
        >
          <option value="0">Less than 12 months</option>
          <option value="1">&gt;12 months to 24 months</option>
          <option value="2">&gt;24 months to 36 months</option>
          <option value="3">&gt;36 months</option>
        </Select>
      </Td>
      <Td m="0px" p="0px">
        <Checkbox
          isChecked={provider.ama}
          onChange={handleAmaChange}
          borderColor="black"
          colorScheme="red"
        ></Checkbox>
      </Td>
      <Td m="0px" p="0px">
        <Checkbox
          isChecked={provider.burnout}
          onChange={handleBurnoutChange}
          borderColor="black"
          colorScheme="red"
        ></Checkbox>
      </Td>
    </Tr>
  );
};

export default function IndividualProviderTable({
  individualProviders,
  onRowChange,
  onRowDelete,
  onRowAdd,
  onRowCopy,
}) {
  return (
    <VerticalSection>
      <VerticalSection>
        <Text mb={2} fontWeight="bold" textAlign="left" fontSize="lg">
          Individual Providers
        </Text>
      </VerticalSection>
      <VerticalSection>
        <Table
          variant="striped"
          colorScheme="teal"
          size="md"
          fontWeight="500"
          id="individual-providers-table"
        >
          <Thead>
            <Tr>
              <Th minW="50px" m="0px" p="0px"></Th>
              <Th minW="50px" m="0px" p="0px"></Th>
              <Th minW="100px" m="0px" p="0px">
                *First Name
              </Th>
              <Th minW="100px" m="0px" p="0px">
                *Last Name
              </Th>
              <Th minW="100px" m="0px" p="0px">
                Suffix
              </Th>
              <Th minW="100px" m="0px" p="0px">
                *Specialty
              </Th>
              <Th minW="150px" m="0px" p="0px">
                *Indigo Specialty
              </Th>
              <Th minW="250px" m="0px" p="0px">
                *Address
              </Th>
              <Th minW="130px" m="0px" p="0px">
                Limit
              </Th>
              <Th minW="100px" m="0px" p="0px">
                Limit Type
              </Th>
              <Th minW="160px" m="0px" p="0px">
                Retro Date
              </Th>
              <Th minW="100px" m="0px" p="0px">
                NPI
              </Th>
              <Th minW="100px" m="0px" p="0px">
                Deductible
              </Th>
              <Th minW="100px" m="0px" p="0px">
                Expiring Premium
              </Th>
              <Th minW="100px" m="0px" p="0px">
                Target Premium
              </Th>
              <Th minW="100px" m="0px" p="0px">
                License Number
              </Th>
              <Th minW="100px" m="0px" p="0px">
                License State
              </Th>
              <Th minW="100px" m="0px" p="0px">
                Retired/Volunteer Physician
              </Th>
              {/* <Th minW='100px' m="0px" p="0px">Vicarious Liability</Th> */}
              <Th minW="160px" m="0px" p="0px">
                Part Time Practice
              </Th>
              <Th minW="140px" m="0px" p="0px">
                Teaching Physicians
              </Th>
              <Th minW="240px" m="0px" p="0px">
                Residency and Fellowship Programs
              </Th>
              <Th minW="160px" m="0px" p="0px">
                New to Practice
              </Th>
              <Th minW="100px" m="0px" p="0px">
                AMA
              </Th>
              <Th minW="100px" m="0px" p="0px">
                Burnout
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {individualProviders.map((provider, index) =>
              IndividualProviderRow(provider, index, onRowChange, onRowDelete, onRowCopy),
            )}
          </Tbody>
        </Table>
      </VerticalSection>
      <Stack spacing={4} direction="row">
        <Button
          padding="0x"
          margin="0x"
          mb={4}
          colorScheme="indigo"
          size="md"
          onClick={onRowAdd}
          id="add-new-individual-provider-button"
        >
          Add New
        </Button>
        {individualProviders.length === 0 ? (
          <Text fontSize="sm">No Individual Providers</Text>
        ) : (
          <></>
        )}
      </Stack>
    </VerticalSection>
  );
}
