import React, { useEffect, useState } from "react";
import { API } from "aws-amplify";
import { Box, Flex, UnorderedList, ListItem, Text, Icon } from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";
import { Spinner } from "components";

const queryParams = new URLSearchParams(window.location.search);
const inquiryId = queryParams.get("inquiry-id");

export default function Submissions() {
  const [submissions, setSubmissions] = useState([]);

  const myAPI = "api2b38d508";
  const path = "/submissions";

  useEffect(() => {
    getSubmissions();
  }, []);

  function getSubmissions() {
    const submissionURL = path + "/" + inquiryId;
    API.get(myAPI, submissionURL)
      .then((response) => {
        setSubmissions(response);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  if (!inquiryId) {
    return;
  } else {
    return (
      <>
        <Text fontWeight="bold" textAlign="left">
          Submission Documents
        </Text>
        <Box maxW="100%" bg="gray.200" display="flex">
          <Box width="100%" bg="white" borderRadius="md" m="10px">
            <UnorderedList listStyleType="none" p={0}>
              {submissions.length > 0 ? (
                submissions.map((submission) => (
                  <ListItem
                    onClick={() => {
                      window.open(submission.url, "_blank");
                    }}
                    key={submission.url}
                    display="flex"
                  >
                    <Flex alignItems="center">
                      <Icon as={SearchIcon} boxSize={6} mr={2} />
                      <Text>{submission.name}</Text>
                    </Flex>
                    {/* Add your onClick handler for the link */}
                  </ListItem>
                ))
              ) : (
                <Spinner />
              )}
            </UnorderedList>
          </Box>
        </Box>
      </>
    );
  }
}
