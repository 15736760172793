import { useLayoutEffect } from "react";
import { Navigate, Routes, Route, useLocation } from "react-router-dom";
import { Inquiry } from "./inquiry";
import { NotFound } from "routes/not-found";
import { Navbar } from "components";

export function Broker() {
  const searchParams = new URLSearchParams(window.location.search).toString();
  const destination = `inquiry/new${searchParams ? `?${searchParams}` : ""}`;
  const location = useLocation();

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <div style={{ height: "100%", paddingBottom: "30px" }}>
      <Navbar />
      <Routes>
        <Route path="/" element={<Navigate to={destination} />} />
        <Route path="inquiry/*" element={<Inquiry />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
}
