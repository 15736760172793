import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Container,
  Flex,
  IconButton,
  Tooltip,
} from "@chakra-ui/react";
import { AddIcon, CloseIcon } from "@chakra-ui/icons";
import { useForm } from "react-hook-form";
import { generateId } from "utils";
import { getBrokerInquiry } from "graphql/queries";
import { updateBrokerInquiry } from "graphql/mutations";
import { NEW_PROVIDER } from "mocks";
import { UpdateBrokerInquiryInput, BrokerInquiryProviderInput, BrokerInquiry } from "API";
import { useGraphqlApi } from "hooks";
import { Error } from "components";
import { InfoForm } from "./InfoForm";
import { useAnalytics } from "providers";
import { RAT_EVENTS } from "analytics";

export function ProviderInfo() {
  const navigate = useNavigate();
  const { track } = useAnalytics();
  const { submissionId } = useParams();
  const inquiryVariables = {
    id: submissionId,
  };
  const {
    data: inquiry,
    postData,
    loading,
    error,
  } = useGraphqlApi<BrokerInquiry>(getBrokerInquiry, inquiryVariables);
  const [hasBeenReset, setHasBeenReset] = useState(false);
  const {
    control,
    handleSubmit,
    getValues,
    setValue,
    reset,
    formState: { isSubmitting },
  } = useForm<UpdateBrokerInquiryInput>();

  useEffect(() => {
    if (inquiry && loading === false && hasBeenReset !== true) {
      setHasBeenReset(true);
      reset(inquiry);
    }
  }, [loading, inquiry, hasBeenReset, reset]);

  async function onSubmit(values: UpdateBrokerInquiryInput) {
    await postData(updateBrokerInquiry, {
      input: { ...inquiry, providers: [] },
    });
    await postData(updateBrokerInquiry, {
      input: values,
    });
    navigate(`/broker/inquiry/${submissionId}/resolve-npi`);
  }
  return error ? (
    <Error errorText="Something went wrong, please contact support." />
  ) : (
    <Container mt="100px" maxW="833px">
      <Card
        maxWidth="833px"
        boxShadow="0px 0px 5px 0px rgba(0, 0, 0, 0.10)"
        border="1px"
        borderColor="#DEDEDE"
      >
        <CardHeader p="0" bg="indigo.50" fontSize="sm">
          <Flex px="25px" py="15px" alignItems="center" justifyContent="space-between">
            Let’s find the provider. Please let us know...
            <IconButton
              onClick={() => navigate("/broker")}
              size="xs"
              variant="ghost"
              aria-label="Exit inquiry"
              icon={<CloseIcon />}
            />
          </Flex>
        </CardHeader>
        <CardBody>
          <form onSubmit={handleSubmit(onSubmit)}>
            {getValues("providers")?.map((provider: BrokerInquiryProviderInput, index: number) => (
              <InfoForm
                removeProvider={async () => {
                  if (inquiry?.providers) {
                    const newProviders = [...inquiry.providers].filter((p) => p.id !== provider.id);
                    await postData(updateBrokerInquiry, {
                      input: { ...inquiry, providers: [] },
                    });
                    postData(updateBrokerInquiry, {
                      input: { ...inquiry, providers: newProviders },
                    });
                    setValue(`providers`, newProviders);
                  }
                }}
                key={provider.id}
                control={control}
                index={index}
              />
            ))}
            <Flex mt={5} direction={["column", "row"]}>
              <Tooltip
                label={
                  inquiry?.hasMultipleProviders && (inquiry?.providers?.length || 0) <= 1
                    ? "Must at least provider 2 providers, or go back and select Indivdual Provider"
                    : ""
                }
              >
                <Button
                  isDisabled={
                    inquiry?.hasMultipleProviders && inquiry?.providers?.length
                      ? inquiry.providers.length <= 1
                      : false
                  }
                  isLoading={isSubmitting}
                  onClick={() => {
                    track(RAT_EVENTS.MATCH_PROFILES);
                  }}
                  colorScheme="indigo"
                  type="submit"
                >
                  Let's Match Profiles
                </Button>
              </Tooltip>
              {inquiry?.hasMultipleProviders && (
                <Button
                  leftIcon={<AddIcon boxSize="0.75rem" mt="5px" />}
                  onClick={async () => {
                    const newBrokerInquiry = { ...inquiry, providers: getValues("providers") };
                    newBrokerInquiry.providers = newBrokerInquiry?.providers?.concat({
                      ...NEW_PROVIDER,
                      id: generateId(),
                    });
                    await postData(updateBrokerInquiry, { input: newBrokerInquiry });
                    setValue("providers", newBrokerInquiry.providers);
                  }}
                  variant="outline"
                >
                  Another Provider
                </Button>
              )}
            </Flex>
          </form>
        </CardBody>
      </Card>
    </Container>
  );
}
