import { Navigate, Routes, Route } from "react-router-dom";
import { New } from "./new";
import { ProviderInfo } from "./provider-info";
import { ResolveNpi } from "./resolve-npi";
import { Submit } from "./submit";
import { Confirm } from "./confirm";
import { NotFound } from "routes/not-found";

export function Inquiry() {
  const searchParams = new URLSearchParams(window.location.search).toString();
  const destination = `new${searchParams ? `?${searchParams}` : ""}`;

  return (
    <div>
      <Routes>
        <Route path="/" element={<Navigate to={destination} />} />
        <Route path="new" element={<New />} />
        <Route path=":submissionId">
          <Route path="provider-info" element={<ProviderInfo />} />
          <Route path="resolve-npi" element={<ResolveNpi />} />
          <Route path="submit" element={<Submit />} />
          <Route path="confirm" element={<Confirm />} />
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
}
