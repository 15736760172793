import {
  Box,
  Button,
  IconButton,
  Input,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  Stack,
  Select,
} from "@chakra-ui/react";
import { DeleteIcon, CopyIcon } from "@chakra-ui/icons";
import { INDIGO_NON_PHYSICIAN_SPECIALTIES } from "services/indigoSpecialtyService";
import { DatePicker, AutocompleteInput } from "components";
import { midLevelPDs } from "services/professionalDesignationService";

const VerticalSection = ({ children }) => {
  return (
    <Box mb={4} maxW="1290px">
      <Box overflowX="auto">{children}</Box>
    </Box>
  );
};

const MidLevelRow = (level, index, onChange, onDelete, onCopy) => {
  const handleSuffixChange = (event) => onChange(index, "suffix", event.target.value);
  const handleFirstNameChange = (event) => onChange(index, "firstName", event.target.value);
  const handleLastNameChange = (event) => onChange(index, "lastName", event.target.value);
  const handleSpecialtyChange = (event) => onChange(index, "specialty", event.target.value);
  const handleIndigoSpecialtyChange = (event) => {
    onChange(index, "indigoSpecialtyId", event.target.value);
    onChange(index, "indigoSpecialty", event.target[event.target.selectedIndex].text); // retrieves text value of selected option
  };
  const handleIndigoAddressChange = (address, addressLabel) => {
    onChange(index, "address", addressLabel);
    onChange(index, "addressObj", address);
  };
  const handleLimitChange = (event) => {
    const limits = event.target.value.split("/");
    onChange(index, "limit", event.target.value);
    onChange(index, "limitOccurrence", limits[0]);
    onChange(index, "limitAggregate", limits[1]);
  };
  const handleRetroDateChange = (date) => onChange(index, "retroDate", date);
  const handleLimitTypeChange = (event) => onChange(index, "limitType", event.target.value);

  const handleDeleteClick = () => onDelete(index);
  const handleCopyClick = () => onCopy(index);

  return (
    <Tr key={index} fontSize="md" m="0px" p="0px">
      <Td m="0px" p="0px">
        <IconButton
          aria-label="Delete Individual Provider"
          icon={<DeleteIcon />}
          onClick={handleDeleteClick}
          colorScheme="red"
        />
      </Td>
      <Td m="0px" p="0px">
        <IconButton
          aria-label="Delete Individual Provider"
          icon={<CopyIcon />}
          onClick={handleCopyClick}
          colorScheme="cyan"
        />
      </Td>
      <Td m="0px" p="0 10px">
        <Input
          m="0px"
          p="0px"
          type="text"
          value={level.firstName || ""}
          onChange={handleFirstNameChange}
        />
      </Td>
      <Td m="0px" p="0px">
        <Input
          m="0px"
          p="0px"
          type="text"
          value={level.lastName || ""}
          onChange={handleLastNameChange}
        />
      </Td>
      <Td m="0px" p="0px">
        <Select size="xs" value={level.suffix || ""} onChange={handleSuffixChange}>
          <option></option>
          {midLevelPDs.map((midLevelPD) => (
            <option key={midLevelPD[0]} value={midLevelPD[0]}>
              {midLevelPD[1]}
            </option>
          ))}
        </Select>
      </Td>
      <Td m="0px" p="0px">
        <Input
          m="0px"
          p="0px"
          type="text"
          value={level.specialty || ""}
          onChange={handleSpecialtyChange}
        />
      </Td>
      <Td m="0px" p="0px">
        <Select
          size="sm"
          fontWeight="500"
          value={level.indigoSpecialtyId || ""}
          onChange={handleIndigoSpecialtyChange}
          required
          m="0px"
          p="0px"
        >
          <option></option>
          {INDIGO_NON_PHYSICIAN_SPECIALTIES.map((spec) => (
            <option key={spec.name} value={spec.value}>
              {spec.name}
            </option>
          ))}
        </Select>
      </Td>
      <Td m="0px" p="0px">
        <AutocompleteInput value={level.address || ""} onChange={handleIndigoAddressChange} />
      </Td>
      <Td m="0px" p="0px">
        <Select
          size="md"
          fontWeight="500"
          value={level.limit || "1000000/3000000"}
          onChange={handleLimitChange}
          required
          m="0px"
          p="0px"
        >
          <option value="100000/300000">0.1M/0.3M</option>
          <option value="200000/600000">0.2M/0.6M</option>
          <option value="250000/750000">0.25M/0.75M</option>
          <option value="500000/1500000">0.5M/1.5M</option>
          <option value="1000000/3000000">1M/3M</option>
        </Select>
      </Td>
      <Td m="0px" p="0px">
        <Select
          size="md"
          fontWeight="500"
          value={level.limitType || "3102"}
          onChange={handleLimitTypeChange}
          required
          m="0px"
          p="0px"
        >
          <option value="3102">Shared</option>
          <option value="3101">Separate</option>
        </Select>
      </Td>
      <Td m="0px" p="0px">
        <DatePicker selected={level.retroDate} onChange={handleRetroDateChange} />
      </Td>
    </Tr>
  );
};

export default function MidLevelTable({
  midLevels,
  onRowChange,
  onRowDelete,
  onRowAdd,
  onRowCopy,
}) {
  return (
    <VerticalSection>
      <VerticalSection>
        <Text mb={2} fontWeight="bold" textAlign="left" fontSize="lg">
          Non-Physician Providers
        </Text>
      </VerticalSection>
      <VerticalSection>
        <Table variant="striped" colorScheme="teal" size="md" fontWeight="500">
          <Thead>
            <Tr>
              <Th minW="50px" m="0px" p="0px"></Th>
              <Th minW="50px" m="0px" p="0px"></Th>
              <Th minW="100px" m="0px" p="0px">
                *First Name
              </Th>
              <Th minW="100px" m="0px" p="0px">
                *Last Name
              </Th>
              <Th minW="100px" m="0px" p="0px">
                Suffix
              </Th>
              <Th minW="100px" m="0px" p="0px">
                *Specialty
              </Th>
              <Th minW="150px" m="0px" p="0px">
                *Indigo Specialty
              </Th>
              <Th minW="180px" m="0px" p="0px">
                *Address
              </Th>
              <Th minW="130px" m="0px" p="0px">
                Limit
              </Th>
              <Th minW="100px" m="0px" p="0px">
                Limit Type
              </Th>
              <Th minW="160px" m="0px" p="0px">
                Retro Date
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {midLevels.map((entity, index) =>
              MidLevelRow(entity, index, onRowChange, onRowDelete, onRowCopy),
            )}
          </Tbody>
        </Table>
      </VerticalSection>
      <Stack spacing={4} direction="row">
        <Button padding="0x" margin="0x" mb={4} colorScheme="indigo" size="md" onClick={onRowAdd}>
          Add New
        </Button>
        {midLevels.length === 0 ? (
          <Text fontSize="md" fontWeight="500">
            Non-Physician Providers
          </Text>
        ) : (
          <></>
        )}
      </Stack>
    </VerticalSection>
  );
}
