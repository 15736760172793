import ReactDatePicker, { ReactDatePickerProps } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "theme/datepicker.css";

function formatDate(date: Date) {
  if (!date) return "";
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${month}/${day}/${year}`;
}

function parseDate(dateString?: string) {
  if (!dateString) return null;
  const parts = dateString.split("/");
  // Note: Months are zero-based in JavaScript's Date object, so we subtract 1 from the month value
  const month = parseInt(parts[0], 10) - 1;
  const day = parseInt(parts[1], 10);
  const year = parseInt(parts[2], 10);
  return new Date(year, month, day);
}

export function DatePicker({
  selected,
  onChange,
  includeDateIntervals,
}: {
  selected: string;
  onChange: (dateString: string) => void;
  includeDateIntervals?: ReactDatePickerProps["includeDateIntervals"];
}) {
  const _handleChange = (date: Date) => onChange(formatDate(date));

  return (
    <>
      <ReactDatePicker
        selected={parseDate(selected)}
        onChange={_handleChange}
        includeDateIntervals={includeDateIntervals}
      />
    </>
  );
}
