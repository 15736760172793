import { ReactNode, ChangeEvent } from "react";
import {
  Box,
  Button,
  IconButton,
  Input,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  Select,
  Stack,
} from "@chakra-ui/react";
import { DeleteIcon, CopyIcon } from "@chakra-ui/icons";
import { CurrencyInput, DatePicker } from "components";

const VerticalSection = ({ children }: { children: ReactNode }) => {
  return (
    <Box mb={4} maxW="1335px">
      <Box overflowX="auto">{children}</Box>
    </Box>
  );
};

const HealthcareEntityRow = (
  entity: any,
  index: number,
  onChange: (index: number, property: string, value: string | Date) => void,
  onDelete: (index: number) => void,
  onCopy: (index: number) => void,
) => {
  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) =>
    onChange(index, "name", event.target.value);
  const handleLimitChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const limits = event.target.value.split("/");
    onChange(index, "limit", event.target.value);
    onChange(index, "limitOccurrence", limits[0]);
    onChange(index, "limitAggregate", limits[1]);
  };
  const handleEntityChargesChange = (event: ChangeEvent<HTMLSelectElement>) =>
    onChange(index, "entityCharges", event.target.value);
  const handleRetroDateChange = (date: string) => onChange(index, "retroDate", date);
  const handleCurrentPremiumChange = (value: string) => onChange(index, "currentPremium", value);
  const handleTargetPremiumChange = (value: string) => onChange(index, "targetPremium", value);

  const handleDeleteClick = () => onDelete(index);
  const handleCopyClick = () => onCopy(index);

  return (
    <Tr key={index} fontSize="sm" m="0px" p="0px">
      <Td m="0px" p="0px">
        <IconButton
          aria-label="Delete Individual Provider"
          icon={<DeleteIcon />}
          onClick={handleDeleteClick}
          colorScheme="red"
        />
      </Td>
      <Td m="0px" p="0px">
        <IconButton
          aria-label="Delete Individual Provider"
          icon={<CopyIcon />}
          onClick={handleCopyClick}
          colorScheme="cyan"
        />
      </Td>
      <Td m="0px" p="0px">
        <Input m="0px" p="0px" type="text" value={entity.name || ""} onChange={handleNameChange} />
      </Td>
      <Td m="0px" p="0px">
        <Select
          size="sm"
          value={entity.limit || "1000000/3000000"}
          onChange={handleLimitChange}
          required
          m="0px"
          p="0px"
        >
          <option value="100000/300000">0.1M/0.3M</option>
          <option value="200000/600000">0.2M/0.6M</option>
          <option value="250000/750000">0.25M/0.75M</option>
          <option value="500000/1500000">0.5M/1.5M</option>
          <option value="1000000/3000000">1M/3M</option>
        </Select>
      </Td>
      <Td m="0px" p="0px">
        <Select
          size="sm"
          value={entity.entityCharges || "3101"}
          onChange={handleEntityChargesChange}
          required
          m="0px"
          p="0px"
        >
          <option value="3101">Practice entities, separate limits</option>
          <option value="3102">Practice entities, shared limits</option>
        </Select>
      </Td>
      <Td m="0px" p="0px">
        <DatePicker selected={entity.retroDate} onChange={handleRetroDateChange} />
      </Td>
      <Td m="0px" p="0px">
        <CurrencyInput value={entity.currentPremium} onChange={handleCurrentPremiumChange} />
      </Td>
      <Td m="0px" p="0px">
        <CurrencyInput value={entity.targetPremium} onChange={handleTargetPremiumChange} />
      </Td>
    </Tr>
  );
};

interface HealthcareEntityTableProps {
  healthcareEntities: any;
  onRowChange: () => void;
  onRowDelete: () => void;
  onRowAdd: () => void;
  onRowCopy: () => void;
}

export default function HealthcareEntityTable({
  healthcareEntities,
  onRowChange,
  onRowDelete,
  onRowAdd,
  onRowCopy,
}: HealthcareEntityTableProps) {
  return (
    <VerticalSection>
      <VerticalSection>
        <Text mb={2} fontWeight="bold" textAlign="left" fontSize="lg">
          Medical Practices
        </Text>
      </VerticalSection>
      <VerticalSection>
        <Table fontWeight="500" variant="striped" colorScheme="teal" size="sm">
          <Thead>
            <Tr>
              <Th minW="50px" m="0px" p="0px"></Th>
              <Th minW="50px" m="0px" p="0px"></Th>
              <Th minW="200px" m="0px" p="0px">
                *Name
              </Th>
              <Th minW="110px" m="0px" p="0px">
                Limit
              </Th>
              <Th minW="225px" m="0px" p="0px">
                Entity Charges
              </Th>
              <Th minW="130px" m="0px" p="0px">
                Retro Date
              </Th>
              <Th minW="140px" m="0px" p="0px">
                Current Premium
              </Th>
              <Th minW="140px" m="0px" p="0px">
                Target Premium
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {healthcareEntities.map((entity: any, index: number) =>
              HealthcareEntityRow(entity, index, onRowChange, onRowDelete, onRowCopy),
            )}
          </Tbody>
        </Table>
      </VerticalSection>
      <Stack spacing={4} direction="row">
        <Button padding="0x" margin="0x" mb={4} colorScheme="indigo" size="md" onClick={onRowAdd}>
          Add New
        </Button>
        {healthcareEntities.length === 0 ? <Text fontSize="sm">No Medical Practices</Text> : <></>}
      </Stack>
    </VerticalSection>
  );
}
