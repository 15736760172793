import { ReactNode } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalCloseButton,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalFooter,
} from "@chakra-ui/react";

interface ErrorOverlayProps {
  error: ReactNode;
  isOpen: boolean;
  onClose: () => void;
}

export function ErrorOverlay({ error, isOpen, onClose }: ErrorOverlayProps) {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Something went wrong</ModalHeader>
        <ModalCloseButton />
        <ModalBody>{error}</ModalBody>

        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
