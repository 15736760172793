import { Flex, Badge, Text } from "@chakra-ui/react";

export enum RiskBucket {
  LOW = "Low",
  MODERATE = "Moderate",
  HIGH = "High",
  UNKNOWN = "Unknown",
}

interface RiskProps {
  riskBucket: RiskBucket;
}
export function RiskState({ riskBucket }: RiskProps) {
  let color;
  let copy;
  let subtext;
  switch (riskBucket) {
    case RiskBucket.LOW:
      color = "green";
      copy = "Great news! This submission is currently within our risk appetite.";
      subtext =
        "Please provide us with more information and we will respond with a quote as soon as possible. ";
      break;

    case RiskBucket.MODERATE:
      color = "orange";
      copy = "This provider appears to have a moderate risk profile.";
      subtext = "We will need a few more data points to properly assess the risk. ";
      break;
    case RiskBucket.HIGH:
      color = "red";
      copy = "This submission is currently outside our risk appetite.";
      subtext =
        "We suggest not continuing with this application currently, but our risk profile is always changing, so please check back in the future! ";
      break;
    default:
      color = "gray";
      copy = "This provider appears to have an unknown risk profile.";
      subtext = "We will need a few more data points to properly assess the risk. ";
  }
  return (
    <Flex alignItems="center" justifyContent="center" gap="13px" direction={["column", "row"]}>
      <Badge variant="solid" colorScheme={color} data-testid="riskProfileBadge">
        Risk Profile{" "}
        <span aria-hidden="false" data-testid={riskBucket} style={{ display: "none" }}>
          {riskBucket}
        </span>
      </Badge>
      <Flex direction={["column", "column"]}>
        <Flex fontSize="xm" fontWeight="700" direction={["column", "row"]}>
          <Text pr="3px">{copy}</Text>{" "}
        </Flex>
        <Text fontSize="xm">{subtext}</Text>
      </Flex>
    </Flex>
  );
}
