import { Controller, Control } from "react-hook-form";
import {
  Box,
  Checkbox,
  Select,
  Flex,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
} from "@chakra-ui/react";

import { INDIGO_SPECIALTIES } from "services/indigoSpecialtyService";
import { DatePicker, AutocompleteInput, Spinner } from "components";
import { AddressInput, UpdateBrokerInquiryInput } from "API";
import { Limit, LimitType } from "enums";
import { stringifyAddress } from "utils";

interface SubmissionFormProps {
  control: Control<UpdateBrokerInquiryInput>;
  index: number;
}
export function SubmissionForm({ control, index }: SubmissionFormProps) {
  return (
    <>
      <Flex gap="12px">
        <Controller
          control={control}
          name={`providers.${index}.firstName`}
          render={({ fieldState, field }) => {
            return (
              <FormControl isInvalid={Boolean(fieldState.error)}>
                <FormLabel fontSize="sm" htmlFor={`providers.${index}.firstName`}>
                  First name
                </FormLabel>
                <Input
                  id={`providers.${index}.firstName`}
                  placeholder="First name"
                  onChange={field.onChange}
                  defaultValue={field.value}
                />
                <FormErrorMessage>{fieldState.error && fieldState.error.message}</FormErrorMessage>
              </FormControl>
            );
          }}
        />
        <Controller
          control={control}
          name={`providers.${index}.lastName`}
          render={({ fieldState, field }) => {
            return (
              <FormControl isInvalid={Boolean(fieldState.error)}>
                <FormLabel htmlFor={`providers.${index}.lastName`}>Last name</FormLabel>
                <Input
                  id={`providers.${index}.lastName`}
                  placeholder="Last name"
                  onChange={field.onChange}
                  defaultValue={field.value}
                />
                <FormErrorMessage>{fieldState.error && fieldState.error.message}</FormErrorMessage>
              </FormControl>
            );
          }}
        />
      </Flex>
      <Controller
        name={`providers.${index}.specialty`}
        control={control}
        rules={{ required: "Specialty is required" }}
        render={({ field, fieldState }) => {
          return (
            <FormControl mt="16px" isInvalid={Boolean(fieldState.error)}>
              <FormLabel fontSize="sm" htmlFor={`providers.${index}.specialty`}>
                Specialty
              </FormLabel>
              <Select
                value={field.value}
                id={`providers.${index}.specialty`}
                size="md"
                m="0px"
                p="0px"
                onChange={field.onChange}
              >
                <option></option>
                {INDIGO_SPECIALTIES.map((spec) => (
                  <option key={spec.value} value={spec.value}>
                    {spec.name}
                  </option>
                ))}
              </Select>
              <FormErrorMessage>{fieldState.error && fieldState.error.message}</FormErrorMessage>
            </FormControl>
          );
        }}
      />
      <Flex gap="12px" direction={["column", "row"]}>
        <Box flex="3">
          <Controller
            control={control}
            name={`providers.${index}.address`}
            render={({ fieldState, field }) => {
              return field.value === undefined ? (
                <Spinner />
              ) : (
                <FormControl mt="16px" isInvalid={Boolean(fieldState.error)}>
                  <FormLabel fontSize="sm" htmlFor={`providers.${index}.address`}>
                    Address
                  </FormLabel>
                  <AutocompleteInput
                    error={fieldState.error && !!fieldState.error.message}
                    value={stringifyAddress(field.value)}
                    onChange={(addressObj: AddressInput) => {
                      field.onChange(addressObj);
                    }}
                    data-testid={`providers.${index}.address`}
                  />
                  <FormErrorMessage>
                    {fieldState.error && fieldState.error.message}
                  </FormErrorMessage>
                </FormControl>
              );
            }}
            rules={{ required: "Address is required" }}
          />
        </Box>
        <Box flex="1" mt="14px">
          <Controller
            control={control}
            name={`providers.${index}.npi`}
            render={({ fieldState, field }) => {
              return (
                <FormControl isInvalid={Boolean(fieldState.error)}>
                  <FormLabel htmlFor={`providers.${index}.npi`}>NPI</FormLabel>
                  <Input
                    id={`providers.${index}.npi`}
                    placeholder="NPI"
                    onChange={field.onChange}
                    value={`${field.value}`}
                  />
                  <FormErrorMessage>
                    {fieldState.error && fieldState.error.message}
                  </FormErrorMessage>
                </FormControl>
              );
            }}
          />
        </Box>
      </Flex>
      <Flex gap="12px">
        <Controller
          name={`providers.${index}.limit`}
          control={control}
          rules={{ required: "Limit is required" }}
          render={({ field, fieldState }) => {
            return (
              <FormControl mt="16px" isInvalid={Boolean(fieldState.error)}>
                <FormLabel fontSize="sm" htmlFor={`providers.${index}.limit`}>
                  Limit
                </FormLabel>
                <Select
                  value={`${field.value}`}
                  onChange={field.onChange}
                  id={`providers.${index}.limit`}
                  size="md"
                  m="0px"
                  p="0px"
                >
                  <option></option>
                  {Object.keys(Limit).map((limitKey) => (
                    <option value={limitKey} key={limitKey}>
                      {Limit[limitKey as keyof typeof Limit]}
                    </option>
                  ))}
                </Select>
                <FormErrorMessage>{fieldState.error && fieldState.error.message}</FormErrorMessage>
              </FormControl>
            );
          }}
        />
        <Controller
          name={`providers.${index}.limitType`}
          control={control}
          rules={{ required: "Limit is required" }}
          render={({ field, fieldState }) => {
            return (
              <FormControl mt="16px" isInvalid={Boolean(fieldState.error)}>
                <FormLabel fontSize="sm" htmlFor={`providers.${index}.limitType`}>
                  Limit Type
                </FormLabel>
                <Select
                  value={`${field.value}`}
                  onChange={field.onChange}
                  id={`providers.${index}.limitType`}
                  size="md"
                  m="0px"
                  p="0px"
                >
                  <option></option>
                  {Object.entries(LimitType).map(([key]) => (
                    <option value={key} key={key}>
                      {key}
                    </option>
                  ))}
                </Select>
                <FormErrorMessage>{fieldState.error && fieldState.error.message}</FormErrorMessage>
              </FormControl>
            );
          }}
        />
        <Controller
          control={control}
          name={`providers.${index}.retroDate`}
          rules={{ required: "Retro date is required" }}
          render={({ fieldState, field: { onChange, value } }) => {
            return (
              <FormControl mt="16px" isInvalid={Boolean(fieldState.error)}>
                <FormLabel fontSize="sm" htmlFor="retroDate">
                  Retro Date
                </FormLabel>
                <DatePicker selected={value || ""} onChange={onChange} />
                <FormErrorMessage>{fieldState.error && fieldState.error.message}</FormErrorMessage>
              </FormControl>
            );
          }}
        />
      </Flex>
    </>
  );
}
