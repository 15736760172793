import { memo } from "react";
import { Center, Flex, Text } from "@chakra-ui/react";

import { Spinner } from "components";
import { RiskState, RiskBucket } from "./RiskState";

interface RiskSummaryProps {
  riskBucket?: RiskBucket;
  isLoading?: boolean;
}

export const RiskSummary = memo(function RiskSummary({ riskBucket, isLoading }: RiskSummaryProps) {
  return (
    <Flex py="37px" alignItems="center" gap="14px">
      {!isLoading ? (
        <RiskState riskBucket={riskBucket || RiskBucket.UNKNOWN} />
      ) : (
        <Center w="100%">
          <Flex direction="column" justifyContent="center">
            <Spinner />
            <Text>Assessing risk...</Text>
          </Flex>
        </Center>
      )}
    </Flex>
  );
});
