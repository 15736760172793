import React from "react";
import {
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
} from "@chakra-ui/react";

interface CurrencyInputProps {
  value?: string;
  max?: number;
  onChange: (parsedString: string) => void;
}

export const CurrencyInput = ({ value, max, onChange }: CurrencyInputProps) => {
  const format = (val?: string) => `$` + (val !== undefined ? val : "");
  const parse = (val: string) => val.replace(/^\$/, "");

  return (
    <NumberInput
      onChange={(valueString) => {
        onChange(parse(valueString));
      }}
      value={format(value)}
      clampValueOnBlur={false}
      min={0}
      max={max}
      size="xs"
    >
      <NumberInputField />
      <NumberInputStepper>
        <NumberIncrementStepper />
        <NumberDecrementStepper />
      </NumberInputStepper>
    </NumberInput>
  );
};
